/**
 * @generated SignedSource<<15f543f7c1c0b2e79ef62a01b218a808>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPipelinePageDependencyFragment$data = {
  readonly snapshotDependencies: {
    readonly build: ReadonlyArray<{
      readonly buildType: {
        readonly name: string | null | undefined;
      } | null | undefined;
      readonly canceledInfo: {
        readonly timestamp: string | null | undefined;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"EditPipelineJobLastRunFragment" | "JobDirectoryPopupLastRunFragment" | "useJobStepRunnerSummarizeErrorPreviewRunFragment">;
    }> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "EditPipelinePageDependencyFragment";
};
export type EditPipelinePageDependencyFragment$key = {
  readonly " $data"?: EditPipelinePageDependencyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditPipelinePageDependencyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditPipelinePageDependencyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Builds",
      "kind": "LinkedField",
      "name": "snapshotDependencies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Build",
          "kind": "LinkedField",
          "name": "build",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditPipelineJobLastRunFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useJobStepRunnerSummarizeErrorPreviewRunFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "JobDirectoryPopupLastRunFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BuildTypeRest",
              "kind": "LinkedField",
              "name": "buildType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Comment",
              "kind": "LinkedField",
              "name": "canceledInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timestamp",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Build",
  "abstractKey": null
};

(node as any).hash = "6fce18554c63123935b668aae5d83555";

export default node;
