import {graphql, useLazyLoadQuery, usePreloadedQuery} from 'react-relay'
import type {PreloadedQuery} from 'react-relay/relay-hooks/EntryPointTypes'

import {getPropertyFromList} from '../../../../utils/getProperty'

import type {BuildArtifactStorageInfoFeatureIdQuery} from './__generated__/BuildArtifactStorageInfoFeatureIdQuery.graphql'
import type {BuildArtifactStorageInfoFeatureQuery} from './__generated__/BuildArtifactStorageInfoFeatureQuery.graphql'
import {featureIdQuery} from './BuildArtifactStorageInfo.queries'

const STORAGE_FEATURE_ID = 'teamcity.storage.feature.id'

const query = graphql`
  query BuildArtifactStorageInfoFeatureQuery(
    $projectLocator: String!
    $featureLocator: String!
    $skip: Boolean!
  ) {
    feature(projectLocator: $projectLocator, featureLocator: $featureLocator) @skip(if: $skip) {
      properties {
        property {
          name
          value
        }
      }
    }
  }
`

export function useArtifactStorage(
  featureIdQueryRef: PreloadedQuery<BuildArtifactStorageInfoFeatureIdQuery>,
) {
  const {build} = usePreloadedQuery(featureIdQuery, featureIdQueryRef)
  const featureId = getPropertyFromList(STORAGE_FEATURE_ID, build?.resultingProperties)

  const {feature} = useLazyLoadQuery<BuildArtifactStorageInfoFeatureQuery>(query, {
    projectLocator: `projectFeature:id:${featureId}`,
    featureLocator: `id:${featureId}`,
    skip: featureId == null,
  })
  return {
    name: getPropertyFromList('storage.name', feature?.properties) ?? '',
    type: getPropertyFromList('storage.type', feature?.properties) ?? '',
  }
}
