import {adapterEventNames, darkThemeAdapterServicePrefix} from '../defaults'
import type {FilterConfig} from '../types'
import {forEach, push, toArray} from '../utils/array'
import {clearColorCache, parseColorWithCache} from '../utils/color'
import {
  addDOMReadyListener,
  cleanReadyStateCompleteListeners,
  isDOMReady,
  iterateShadowHosts,
  removeDOMReadyListener,
  removeNode,
  watchForNodePosition,
} from '../utils/dom'
import {getStyleSheetJSSelector} from '../utils/selectors'
import {throttle} from '../utils/throttle'
import {parsedURLCache} from '../utils/url'

import type {AdoptedStyleSheetManager} from './adoptedStyleManager'
import {createAdoptedStyleSheetOverride} from './adoptedStyleManager'
import {
  getInlineOverrideStyle,
  INLINE_STYLE_SELECTOR,
  overrideInlineStyle,
  stopWatchingForInlineStyles,
  watchForInlineStyles,
} from './inlineStyle'
import {modifyBackgroundColor, modifyForegroundColor} from './modifyColors'
import {
  cleanModificationCache,
  getModifiedFallbackStyle,
  getModifiedUserAgentStyle,
  getSelectionColor,
} from './modifyCss'
import {manageStyle} from './styleManager'
import type {StyleElement, Index} from './styleManager/styleManager.types'
import {cleanLoadingLinks, getManageableStyles} from './styleManager/styleManager.utils'
import {injectStylesheetProxy} from './stylesheetProxy'
import {variablesStore} from './variables'
import {stopWatchingForStyleChanges, watchForStyleChanges} from './watch'

const styleManagers = new Map<StyleElement, Index>()
const adoptedStyleManagers = [] as AdoptedStyleSheetManager[]

let filter: FilterConfig | null = null
let loadingStylesCounter = 0
const loadingStyles = new Set<number>()
const nodePositionWatchers = new Map<string, ReturnType<typeof watchForNodePosition>>()
const shadowRootsWithOverrides = new Set<ShadowRoot>()
const throttledRenderAllStyles = throttle((callback?: () => void) => {
  styleManagers.forEach(manager => manager.render(filter!))
  adoptedStyleManagers.forEach(manager => manager.render(filter!))
  callback?.()
})
const cancelRendering = () => throttledRenderAllStyles.cancel()
let metaObserver: MutationObserver

function createOrUpdateStyle(name: string, root: ParentNode = document.head || document) {
  const selector = getStyleSheetJSSelector(name)
  let element: HTMLStyleElement | null = root.querySelector(`.${selector}`)
  if (!element) {
    element = document.createElement('style')
    element.classList.add(darkThemeAdapterServicePrefix)
    element.classList.add(selector)
    element.media = 'screen'
    element.textContent = ''
  }
  return element
}

function setupNodePositionWatcher(node: Node, alias: string) {
  if (nodePositionWatchers.has(alias)) {
    nodePositionWatchers.get(alias)!.stop()
  }
  nodePositionWatchers.set(alias, watchForNodePosition(node, 'head'))
}

function stopStylePositionWatchers() {
  forEach(nodePositionWatchers.values(), watcher => watcher.stop())
  nodePositionWatchers.clear()
}

function createStaticStyleOverrides() {
  const fallbackStyle = createOrUpdateStyle('fallback', document)
  fallbackStyle.textContent = getModifiedFallbackStyle(filter!)
  document.head.insertBefore(fallbackStyle, document.head.firstChild)
  setupNodePositionWatcher(fallbackStyle, 'fallback')

  const userAgentStyle = createOrUpdateStyle('user-agent')
  userAgentStyle.textContent = getModifiedUserAgentStyle(filter!)
  document.head.insertBefore(userAgentStyle, fallbackStyle.nextSibling)
  setupNodePositionWatcher(userAgentStyle, 'user-agent')

  const inlineStyle = createOrUpdateStyle('inline')
  inlineStyle.textContent = getInlineOverrideStyle()
  document.head.insertBefore(inlineStyle, userAgentStyle.nextSibling)
  setupNodePositionWatcher(inlineStyle, 'inline')

  const variableStyle = createOrUpdateStyle('variables')
  const selectionColors = getSelectionColor(filter!)
  const {
    darkSchemeBackgroundColor,
    darkSchemeTextColor,
    lightSchemeBackgroundColor,
    lightSchemeTextColor,
    mode,
  } = filter!
  let schemeBackgroundColor = mode === 0 ? lightSchemeBackgroundColor : darkSchemeBackgroundColor
  let schemeTextColor = mode === 0 ? lightSchemeTextColor : darkSchemeTextColor
  schemeBackgroundColor = modifyBackgroundColor(
    parseColorWithCache(schemeBackgroundColor)!,
    filter!,
  )
  schemeTextColor = modifyForegroundColor(parseColorWithCache(schemeTextColor)!, filter!)
  variableStyle.textContent = [
    `:root {`,
    `   --${darkThemeAdapterServicePrefix}-neutral-background: ${schemeBackgroundColor};`,
    `   --${darkThemeAdapterServicePrefix}-neutral-text: ${schemeTextColor};`,
    `   --${darkThemeAdapterServicePrefix}-selection-background: ${selectionColors.backgroundColorSelection};`,
    `   --${darkThemeAdapterServicePrefix}-selection-text: ${selectionColors.foregroundColorSelection};`,
    `}`,
  ].join('\n')
  document.head.insertBefore(variableStyle, inlineStyle.nextSibling)
  setupNodePositionWatcher(variableStyle, 'variables')

  const rootVarsStyle = createOrUpdateStyle('root-vars')
  document.head.insertBefore(rootVarsStyle, variableStyle.nextSibling)

  injectStylesheetProxy(darkThemeAdapterServicePrefix)
}

function createShadowStaticStyleOverrides(root: ShadowRoot) {
  const inlineStyle = createOrUpdateStyle('inline', root)
  inlineStyle.textContent = getInlineOverrideStyle()
  root.insertBefore(inlineStyle, root.firstChild)
  shadowRootsWithOverrides.add(root)
}

function cleanFallbackStyle() {
  const fallback = document.querySelector(`.${getStyleSheetJSSelector('fallback')}`)
  if (fallback) {
    fallback.textContent = ''
  }
}

function createDynamicStyleOverrides() {
  cancelRendering()

  const allStyles = getManageableStyles(document)

  const newManagers = allStyles
    .filter(style => !styleManagers.has(style))
    .map(style => createManager(style))
  newManagers
    .map(manager => manager.details({secondRound: false}))
    .filter(detail => detail && detail.rules.length > 0)
    .forEach(detail => {
      variablesStore.addRulesForMatching(detail!.rules)
    })

  variablesStore.matchVariablesAndDependants()
  variablesStore.setOnRootVariableChange(() => {
    const rootVarsStyle = createOrUpdateStyle('root-vars')
    variablesStore.putRootVars(rootVarsStyle, filter!)
  })
  const rootVarsStyle = createOrUpdateStyle('root-vars')
  variablesStore.putRootVars(rootVarsStyle, filter!)

  styleManagers.forEach(manager => manager.render(filter!))
  if (loadingStyles.size === 0) {
    cleanFallbackStyle()
  }
  newManagers.forEach(manager => manager.watch())

  const inlineStyleElements = toArray(document.querySelectorAll(INLINE_STYLE_SELECTOR))
  iterateShadowHosts(document.documentElement, host => {
    createShadowStaticStyleOverrides(host.shadowRoot!)
    const elements = host.shadowRoot!.querySelectorAll(INLINE_STYLE_SELECTOR)
    if (elements.length > 0) {
      push(inlineStyleElements, elements)
    }
  })
  inlineStyleElements.forEach(el => overrideInlineStyle(el as HTMLElement, filter!))
  handleAdoptedStyleSheets(document)
}

function createManager(element: StyleElement) {
  const loadingStyleId = ++loadingStylesCounter

  const manager = manageStyle(element, {update, loadingStart, loadingEnd})
  styleManagers.set(element, manager)

  function loadingStart() {
    if (!isDOMReady() || document.hidden) {
      loadingStyles.add(loadingStyleId)

      const fallbackStyle = document.querySelector(`.${getStyleSheetJSSelector('fallback')}`)!
      if (!fallbackStyle.textContent) {
        fallbackStyle.textContent = getModifiedFallbackStyle(filter!)
      }
    }
  }

  function loadingEnd() {
    loadingStyles.delete(loadingStyleId)
    if (loadingStyles.size === 0 && isDOMReady()) {
      cleanFallbackStyle()
    }
  }

  function update() {
    const details = manager.details({secondRound: true})
    if (!details) {
      return
    }
    variablesStore.addRulesForMatching(details.rules)
    variablesStore.matchVariablesAndDependants()
    manager.render(filter!)
  }

  return manager
}

function removeManager(element: StyleElement) {
  const manager = styleManagers.get(element)
  if (manager) {
    manager.destroy()
    styleManagers.delete(element)
  }
}

function onDOMReady() {
  if (loadingStyles.size === 0) {
    cleanFallbackStyle()
    return
  }
}

function runDynamicStyle() {
  createDynamicStyleOverrides()
  watchForUpdates()
}

function handleAdoptedStyleSheets(node: ShadowRoot | Document) {
  try {
    if (Array.isArray(node.adoptedStyleSheets)) {
      if (node.adoptedStyleSheets.length > 0) {
        const newManger = createAdoptedStyleSheetOverride(node)

        adoptedStyleManagers.push(newManger)
        newManger.render(filter!)
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err)
  }
}

function watchForUpdates() {
  const managedStyles = Array.from(styleManagers.keys())
  watchForStyleChanges(
    managedStyles,
    ({created, updated, removed, moved}) => {
      const stylesToRemove = removed
      const stylesToManage = created
        .concat(updated)
        .concat(moved)
        .filter(style => !styleManagers.has(style))
      const stylesToRestore = moved.filter(style => styleManagers.has(style))
      stylesToRemove.forEach(style => removeManager(style))
      const newManagers = stylesToManage.map(style => createManager(style))
      newManagers
        .map(manager => manager.details({secondRound: false}))
        .filter(detail => detail && detail.rules.length > 0)
        .forEach(detail => {
          variablesStore.addRulesForMatching(detail!.rules)
        })
      variablesStore.matchVariablesAndDependants()
      newManagers.forEach(manager => manager.render(filter!))
      newManagers.forEach(manager => manager.watch())
      stylesToRestore.forEach(style => styleManagers.get(style)!.restore())
    },
    shadowRoot => {
      createShadowStaticStyleOverrides(shadowRoot)
      handleAdoptedStyleSheets(shadowRoot)
    },
  )

  watchForInlineStyles(
    element => {
      overrideInlineStyle(element, filter!)
      if (element === document.documentElement) {
        const styleAttr = element.getAttribute('style') || ''
        if (styleAttr.includes('--')) {
          variablesStore.matchVariablesAndDependants()
          const rootVarsStyle = createOrUpdateStyle('root-vars')
          variablesStore.putRootVars(rootVarsStyle, filter!)
        }
      }
    },
    root => {
      createShadowStaticStyleOverrides(root)
      const inlineStyleElements = root.querySelectorAll(INLINE_STYLE_SELECTOR)
      if (inlineStyleElements.length > 0) {
        forEach(inlineStyleElements, el => overrideInlineStyle(el as HTMLElement, filter!))
      }
    },
  )

  addDOMReadyListener(onDOMReady)
}

function stopWatchingForUpdates() {
  styleManagers.forEach(manager => manager.pause())
  stopStylePositionWatchers()
  stopWatchingForStyleChanges()
  stopWatchingForInlineStyles()
  removeDOMReadyListener(onDOMReady)
  cleanReadyStateCompleteListeners()
}

function cleanDynamicThemeCache() {
  variablesStore.clear()
  parsedURLCache.clear()
  cancelRendering()
  stopWatchingForUpdates()
  cleanModificationCache()
  clearColorCache()
}

export function removeDarkTheme() {
  cleanDynamicThemeCache()
  removeNode(document.querySelector(`.${getStyleSheetJSSelector('fallback')}`))
  if (document.head) {
    removeNode(document.head.querySelector('meta[name="darkreader-lock"]'))
    removeNode(document.head.querySelector(`.${getStyleSheetJSSelector('user-agent')}`))
    removeNode(document.head.querySelector(`.${getStyleSheetJSSelector('text')}`))
    removeNode(document.head.querySelector(`.${getStyleSheetJSSelector('invert')}`))
    removeNode(document.head.querySelector(`.${getStyleSheetJSSelector('inline')}`))
    removeNode(document.head.querySelector(`.${getStyleSheetJSSelector('override')}`))
    removeNode(document.head.querySelector(`.${getStyleSheetJSSelector('variables')}`))
    removeNode(document.head.querySelector(`.${getStyleSheetJSSelector('root-vars')}`))
    removeNode(document.head.querySelector(`meta[name="${darkThemeAdapterServicePrefix}"]`))
    document.dispatchEvent(new CustomEvent(adapterEventNames.cleanup))
  }
  shadowRootsWithOverrides.forEach(root => {
    removeNode(root.querySelector(`.${getStyleSheetJSSelector('inline')}`))
    removeNode(root.querySelector(`.${getStyleSheetJSSelector('override')}`))
  })
  shadowRootsWithOverrides.clear()
  forEach(styleManagers.keys(), el => removeManager(el))
  loadingStyles.clear()
  cleanLoadingLinks()
  forEach(document.querySelectorAll(`.${darkThemeAdapterServicePrefix}`), removeNode)

  adoptedStyleManagers.forEach(manager => {
    manager.destroy()
  })
  adoptedStyleManagers.splice(0)

  metaObserver?.disconnect()
}

export function createOrUpdateDarkTheme(filterConfig: FilterConfig) {
  const lock = document.querySelector('meta[name="darkreader-lock"]')
  const darkReaderMeta = document.querySelector('meta[name="darkreader"]')

  if (lock || darkReaderMeta) {
    return
  }

  filter = filterConfig

  const metaElement: HTMLMetaElement = document.createElement('meta')
  metaElement.name = 'darkreader-lock'
  document.head.appendChild(metaElement)

  createStaticStyleOverrides()
  runDynamicStyle()
}
