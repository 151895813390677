import {getProjectsSidebarStateToPersist} from '../components/App/SidebarPanel/SidebarPanelContent/ProjectsSidebar/ProjectsSidebar.selectors'
import {getBuildlogStateToPersist} from '../components/packages/BuildLog/BuildLog.selectors'
import {getHintsStateToPersist} from '../components/packages/Hints/Hints.selectors'
import {getPipelinesStateToPersist} from '../components/pages/PipelinesPages/selectors'
import type {State} from '../reducers/types'

export const getStateToPersist = (state: State): Partial<State> => {
  const {blocks, clientId, parameterGroups, compareBuilds}: State = state
  return {
    blocks,
    projectsSidebar: getProjectsSidebarStateToPersist(state),
    compareBuilds,
    buildLog: getBuildlogStateToPersist(state),
    clientId,
    pipelines: getPipelinesStateToPersist(state),
    hints: getHintsStateToPersist(state),
    parameterGroups,
  } as Partial<State>
}
