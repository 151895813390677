/**
 * @generated SignedSource<<1ffeec2f2ad0d506a200323005fc6efb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalReviewers_approvalInfo$data = {
  readonly groupApprovals: {
    readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_approvalGroups">;
  } | null | undefined;
  readonly userApprovals: {
    readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_groupedByStatusApprovals">;
  } | null | undefined;
  readonly " $fragmentType": "BuildApprovalReviewers_approvalInfo";
};
export type BuildApprovalReviewers_approvalInfo$key = {
  readonly " $data"?: BuildApprovalReviewers_approvalInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_approvalInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalReviewers_approvalInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserApprovals",
      "kind": "LinkedField",
      "name": "userApprovals",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuildApprovalReviewers_groupedByStatusApprovals"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GroupApprovals",
      "kind": "LinkedField",
      "name": "groupApprovals",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BuildApprovalReviewers_approvalGroups"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ApprovalInfo",
  "abstractKey": null
};

(node as any).hash = "43e0f3505d716a2a46b33cf595beb6f2";

export default node;
