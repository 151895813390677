import {useAppSelector} from '../../../../../hooks/react-redux'
import {getServerInfo} from '../../../../../selectors'

export function useFormattedVersionAndDetails() {
  const licenseModeDetails = useAppSelector(state => getServerInfo(state)?.licenseModeDetails)
  const version = useAppSelector(state => getServerInfo(state)?.version)
  const licenseIsCloud = useAppSelector(state => getServerInfo(state)?.licenseIsCloud)
  const formattedVersion =
    !licenseIsCloud && version != null && version.length > 0
      ? version.replace(/\s(?![^)]*(\(|$))/g, '\u00a0')
      : ''
  const formattedDetails =
    licenseModeDetails != null && licenseModeDetails.length > 0
      ? licenseModeDetails.replace(/\s/g, '\u00a0').replace(/,\s/, ', ')
      : ''
  return `${formattedVersion}${formattedDetails}`
}
