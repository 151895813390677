import {useMutation} from 'react-relay'
import type {UseMutationConfig} from 'react-relay/relay-hooks/useMutation'
import type {
  Disposable,
  GraphQLTaggedNode,
  IEnvironment,
  MutationConfig,
  MutationParameters,
} from 'relay-runtime'

export function useMutationAsPromise<TMutation extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  commitMutationFn?: (environment: IEnvironment, config: MutationConfig<TMutation>) => Disposable,
): [(config: UseMutationConfig<TMutation>) => Promise<TMutation['response']>, boolean] {
  const [commitMutation, inFlight] = useMutation<TMutation>(mutation, commitMutationFn)

  return [
    (config: UseMutationConfig<TMutation>) =>
      new Promise((resolve, reject) => {
        commitMutation({
          ...config,
          onCompleted: (response, errors) => {
            config.onCompleted?.(response, errors)
            if (errors?.length) {
              reject(errors)
            } else {
              resolve(response)
            }
          },
          onError: error => {
            config.onError?.(error)
            reject(error)
          },
        })
      }),
    inFlight,
  ]
}
