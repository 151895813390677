import {mapProps} from 'recompose'

import {skipToken} from '@reduxjs/toolkit/query'
import compose from 'lodash/flowRight'

import withHook from '../../../hocs/withHook'
import {useAppSelector} from '../../../hooks/react-redux'
import {getBuildTypeArg} from '../../../rest/buildTypes'
import {getBuildType} from '../../../selectors'
import {restApi} from '../../../services/rest'
import type {BuildTypeId} from '../../../types'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'

type OwnProps = {
  buildTypeId: BuildTypeId
}
type StateProps = {
  children: string | null | undefined
}
export default compose(
  withHook(({buildTypeId}: OwnProps) => {
    const buildTypeName = useAppSelector(state => getBuildType(state, buildTypeId)?.name)
    const {name} = restApi.endpoints.getBuildTypeNormalized.useQuery(
      !buildTypeName && buildTypeId != null ? getBuildTypeArg(buildTypeId) : skipToken,
      {
        selectFromResult: ({data}) => {
          const name = data?.entities?.buildTypes?.[data?.result!]?.name
          return {name}
        },
      },
    )
    return {children: buildTypeName ?? name}
  }),
  mapProps(({buildTypeId, ...restProps}: OwnProps & StateProps) => restProps),
)(MiddleEllipsis)
