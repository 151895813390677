import {produce} from 'immer'

import {restBaseApi} from '../../../../services/restBase'
import {
  GITHUB_REPOSITORIES_ENDPOINT,
  SPACE_REPOSITORIES_ENDPOINT,
} from '../CreatePipelinePage/CreatePipelinePage.consts'
import {
  getPreformattingIntegrations,
  getPreformattingSettings,
  parsePipeline,
  stringifyPipeline,
} from '../EditPipelinePage/EditPipelinePage.utils'
import {ACCOUNT_ROOT_PROJECT_ID} from '../PipelinesPages.consts'
import {isStoreYamlInVcsEnabled} from '../utils/featureToggles'

import type {
  NewPipelineDto,
  RepositoryGroup,
  PipelineDto,
  Pipeline,
  UpdatePipelineArg,
  NewPipeline,
  GetRepositoryBranchesArg,
  RepositoryTestConnectionArg,
  PipelineDurations,
  RunDurations,
  PipelineRunnersJsonFromXMLs,
  GetParametersResolveArg,
  CheckVersionedSettingsArg,
  PipelineParametersResolve,
  CreateNewPipelineArg,
  PipelineProviderJdk,
  GetBuildStepDescriptionArg,
  GetBuildStepDescription,
  AgentCompatibilitiesArg,
  AgentCompatibilities,
  JobMapAgentCompatibilities,
  GetCompatibilityAgentsQueuedJobArg,
  VcsProvider,
  GetRepositoriesArg,
  GetRepositoriesResponse,
  GetPipelineRunVcsRootsArg,
  VcsRootDto,
} from './pipelinesApi.types'
import {prossessSpaceRepositories} from './spaceRepositories.utils'

const url = '/app/pipeline'

const processNewPipeline = ({settings, integrations, ...rest}: NewPipeline): NewPipelineDto => ({
  ...rest,
  integrations: produce(integrations, getPreformattingIntegrations),
  yaml: stringifyPipeline(settings, {
    replacer: getPreformattingSettings,
    noRefs: true,
  }),
})

function tryToParsePipeline(yaml: string) {
  try {
    return {yaml, settings: yaml === '' ? {name: 'Pipeline'} : parsePipeline(yaml)}
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return {yaml, settings: {name: 'Pipeline'}}
  }
}

const processPipeline = ({yaml, versionedSettings, ...rest}: PipelineDto): Pipeline => {
  if (isStoreYamlInVcsEnabled) {
    return {...rest, ...tryToParsePipeline(yaml), versionedSettings}
  }
  return {
    ...rest,
    settings: parsePipeline(yaml),
  }
}

export const pipelinesApi = restBaseApi.injectEndpoints({
  endpoints: build => ({
    getGitHubRepositories: build.query<RepositoryGroup[], void>({
      query: () => ({url: GITHUB_REPOSITORIES_ENDPOINT}),
    }),
    getSpaceRepositories: build.query<RepositoryGroup[] | null, void>({
      query: () => ({url: SPACE_REPOSITORIES_ENDPOINT}),
      transformResponse: prossessSpaceRepositories,
    }),
    getRepositoryBranches: build.query<string[], GetRepositoryBranchesArg>({
      query: queryArg => ({
        url: `${url}/repository/branches`,
        method: 'POST',
        body: queryArg,
      }),
      transformResponse: (data: {values: string[]}) => data.values,
    }),
    repositoryTestConnection: build.query<
      {status: 'OK' | 'ERROR'; error?: string},
      RepositoryTestConnectionArg
    >({
      query: queryArg => ({
        url: `${url}/repository/testConnection`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    checkVersionedSettings: build.query<boolean, CheckVersionedSettingsArg>({
      query: queryArg => ({
        url: `${url}/repository/checkVersionedSettings`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getAllPipelines: build.query<Pipeline[], void>({
      query: () => ({
        url: `${url}?parentProjectExtId=${ACCOUNT_ROOT_PROJECT_ID}`,
      }),
      transformResponse: (data: PipelineDto[]) => data.map(processPipeline),
      providesTags: data => [
        ...(data?.map(({id}) => ({type: 'Pipeline' as const, id})) ?? []),
        {type: 'Pipeline', id: 'LIST'},
      ],
    }),
    getPipelineById: build.query<Pipeline, string>({
      query: id => ({url: `${url}/${id}`}),
      transformResponse: processPipeline,
      providesTags: data => [{type: 'Pipeline', id: `${data?.id}-SINGLE`}],
    }),
    createNewPipeline: build.mutation<Pipeline, CreateNewPipelineArg>({
      query: ({parentProjectId = ACCOUNT_ROOT_PROJECT_ID, body}) => ({
        url: `${url}?parentProjectExtId=${parentProjectId}`,
        method: 'POST',
        body: processNewPipeline(body),
      }),
      transformResponse: processPipeline,
      invalidatesTags: [{type: 'Pipeline', id: 'LIST'}],
    }),
    updatePipeline: build.mutation<Pipeline, UpdatePipelineArg>({
      query: ({id, body}) => ({
        url: `${url}/${id}`,
        method: 'POST',
        body: processNewPipeline(body),
      }),
      transformResponse: processPipeline,
      invalidatesTags: (_, __, {id}) => [{type: 'Pipeline', id}],
      async onQueryStarted({id}, {dispatch, queryFulfilled}) {
        const {data} = await queryFulfilled
        dispatch(pipelinesApi.util.updateQueryData('getPipelineById', id, () => data))
      },
    }),
    deletePipeline: build.mutation<void, string>({
      query: id => ({url: `${url}/${id}`, method: 'DELETE'}),
      invalidatesTags: (_, __, id) => [
        {type: 'Pipeline', id},
        {type: 'Pipeline', id: `${id}-SINGLE`},
      ],
    }),
    getRunOptimizations: build.query<RunDurations, {pipelineId: string; runId: number}>({
      query: ({pipelineId, runId}) => ({
        url: `${url}/${pipelineId}/${runId}/optimizations`,
      }),
    }),
    getPipelineOptimizations: build.query<PipelineDurations, {pipelineId: string; branch: string}>({
      query: ({pipelineId, branch}) => ({
        url: `${url}/${pipelineId}/optimizations`,
        params: {branch},
      }),
    }),
    getPipelineRunnersJsonFromXMLs: build.query<PipelineRunnersJsonFromXMLs, void>({
      query: () => ({
        url: `${url}/runners/jsonFromXMLs`,
      }),
    }),
    getPipelineProviderJdk: build.query<PipelineProviderJdk[], void>({
      query: () => ({
        url: `${url}/provider/jdk`,
      }),
      transformResponse: (data: {values: PipelineProviderJdk[]}) => data.values,
    }),
    getParametersResolve: build.query<PipelineParametersResolve[], GetParametersResolveArg>({
      query: ({id, body}) => ({
        url: `${url}/${id}/parameters/resolve`,
        method: 'POST',
        body: {
          ...body,
          pipeline: processNewPipeline(body.pipeline),
        },
      }),
      transformResponse: (data: {matches: PipelineParametersResolve[]}) => data.matches,
    }),
    getBuildStepDescription: build.query<GetBuildStepDescription, GetBuildStepDescriptionArg>({
      query: ({id, body}) => ({
        url: `${url}/${id}/buildStepDescription`,
        method: 'POST',
        body: processNewPipeline(body),
      }),
    }),
    agentCompatibilities: build.query<AgentCompatibilities, AgentCompatibilitiesArg>({
      query: queryArg => ({
        url: `${url}/${queryArg.pipelineId}/compatibility/agents/${queryArg.jobId}`,
        method: 'POST',
        body: processNewPipeline(queryArg.body),
      }),
    }),
    jobMapAgentCompatibilities: build.query<JobMapAgentCompatibilities, AgentCompatibilitiesArg>({
      query: queryArg => ({
        url: `${url}/${queryArg.pipelineId}/compatibility/agents`,
        method: 'POST',
        body: processNewPipeline(queryArg.body),
      }),
    }),
    getCompatibilityAgentsQueuedJob: build.query<
      AgentCompatibilities,
      GetCompatibilityAgentsQueuedJobArg
    >({
      query: queryArg => ({
        url: `${url}/${queryArg.pipelineId}/compatibility/agents/queuedJob/${queryArg.buildId}`,
      }),
    }),
    getVcsProviders: build.query<VcsProvider[], void>({
      query: () => ({
        url: `${url}/provider/vcs`,
      }),
      transformResponse: (data: {values: VcsProvider[]}) => data.values,
    }),
    getRepositories: build.query<GetRepositoriesResponse, GetRepositoriesArg>({
      query: ({connectionId, ...queryParams}) => ({
        url: `${url}/provider/vcs/${connectionId}/repositories`,
        params: {
          ...queryParams,
        },
      }),
    }),
    getPipelineRunVcsRoots: build.query<VcsRootDto[], GetPipelineRunVcsRootsArg>({
      query: ({pipelineId, runId}) => ({
        url: `${url}/${pipelineId}/${runId}/vcsRoots`,
      }),
      transformResponse: (data: {values: VcsRootDto[]}) => data.values,
    }),
  }),
})
