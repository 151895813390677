import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'
import {graphql, useFragment} from 'react-relay'

import type {BuildId} from '../../../../../types'
import {useBuildApprove, useTimeoutInfo} from '../../BuildApproval.hooks'
import BuildApprovalDialog from '../../BuildApprovalDialog/BuildApprovalDialog'

import type {BuildApprovalControls_approvalInfo$key} from './__generated__/BuildApprovalControls_approvalInfo.graphql'
import BuildApprovalStatus from './BuildApprovalStatus/BuildApprovalStatus'

import styles from './BuildApprovalControls.css'

const fragment = graphql`
  fragment BuildApprovalControls_approvalInfo on ApprovalInfo {
    canBeApprovedByCurrentUser
    status
    timeoutTimestamp
  }
`

type OwnProps = {
  approvalInfoKey: BuildApprovalControls_approvalInfo$key | null
  buildId: BuildId
}

export default function BuildApprovalControls({approvalInfoKey, buildId}: OwnProps) {
  const approvalInfo = useFragment(fragment, approvalInfoKey)
  const secondsLeft = useTimeoutInfo(approvalInfo?.timeoutTimestamp || null)
  const [showDialog, setShowDialog] = React.useState(false)
  const {approve} = useBuildApprove(buildId, false)
  const approveAll = useBuildApprove(buildId, true)

  const shouldRenderApproveButton = approvalInfo?.canBeApprovedByCurrentUser && !!secondsLeft

  return (
    <>
      {shouldRenderApproveButton ? (
        <>
          <Button className={styles.approveButton} onClick={() => setShowDialog(true)}>
            {'Approve'}
          </Button>
          <BuildApprovalDialog
            buildId={buildId}
            show={showDialog}
            onClose={() => setShowDialog(false)}
            onApprove={approve}
            onApproveAll={approveAll.approve}
          />
        </>
      ) : null}
      {approvalInfo?.timeoutTimestamp ? (
        <BuildApprovalStatus timeout={approvalInfo.timeoutTimestamp} status={approvalInfo.status} />
      ) : null}
    </>
  )
}
