/**
 * @generated SignedSource<<dc898a193845c1857ebec89762d30fc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BuildState = "deleted" | "finished" | "queued" | "running" | "unknown";
export type QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateType = "composite" | "deployment" | "regular";
export type QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsPlannedAgentProtocol = "bidirectional" | "unidirectional";
export type QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsTestOccurrencesTestOccurrenceItemsStatus = "ERROR" | "FAILURE" | "NORMAL" | "SUCCESS" | "UNKNOWN" | "WARNING";
export type QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsTestOccurrencesTestOccurrenceItemsTestMutesMuteItemsTargetProblemsProblemItemsInvestigationsInvestigationItemsResolutionType = "atTime" | "manually" | "whenFixed";
export type QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsTestOccurrencesTestOccurrenceItemsTestMutesMuteItemsTargetProblemsProblemItemsInvestigationsInvestigationItemsState = "FIXED" | "GIVEN_UP" | "NONE" | "TAKEN";
export type QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsVcsLabelsItemsStatus = "DISABLED_FOR_THE_ROOT" | "FAILED" | "IS_BEING_SET" | "LABELING_NOT_SUPPORTED" | "SUCCESSFUL_SET" | "UNKNOWN";
export type BuildInput = {
  agent?: AgentInput | null | undefined;
  approvalInfo?: ApprovalInfoInput | null | undefined;
  artifactDependencies?: BuildsInput | null | undefined;
  artifactDependencyChanges?: BuildChangesInput | null | undefined;
  artifacts?: FilesInput | null | undefined;
  artifactsDirectory?: string | null | undefined;
  attributes?: EntriesInput | null | undefined;
  branchName?: string | null | undefined;
  buildType?: BuildTypeInput | null | undefined;
  buildTypeId?: string | null | undefined;
  buildTypeInternalId?: string | null | undefined;
  canceledInfo?: CommentInput | null | undefined;
  chainModificationId?: string | null | undefined;
  changes?: ChangesInput | null | undefined;
  changesCollectingInProgress?: boolean | null | undefined;
  comment?: CommentInput | null | undefined;
  compatibleAgents?: AgentsInput | null | undefined;
  composite?: boolean | null | undefined;
  currentSettingsHash?: string | null | undefined;
  customArtifactDependencies?: ArtifactDependenciesInput | null | undefined;
  customization?: CustomizationsInput | null | undefined;
  customized?: boolean | null | undefined;
  defaultBranch?: boolean | null | undefined;
  delayedByBuild?: BuildInput | null | undefined;
  detachedFromAgent?: boolean | null | undefined;
  downloadedArtifacts?: DownloadedArtifactsInput | null | undefined;
  failedToStart?: boolean | null | undefined;
  finishDate?: string | null | undefined;
  finishEstimate?: string | null | undefined;
  finishOnAgentDate?: string | null | undefined;
  firstBuildWithSameChanges?: BuildInput | null | undefined;
  history?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: number | null | undefined;
  lastChanges?: ChangesInput | null | undefined;
  limitedChangesCount?: number | null | undefined;
  links?: LinksInput | null | undefined;
  locator?: string | null | undefined;
  metadata?: DatasInput | null | undefined;
  modificationId?: string | null | undefined;
  number?: string | null | undefined;
  originalProperties?: PropertiesInput | null | undefined;
  percentageComplete?: number | null | undefined;
  personal?: boolean | null | undefined;
  pinInfo?: CommentInput | null | undefined;
  pinned?: boolean | null | undefined;
  plannedAgent?: AgentInput | null | undefined;
  problemOccurrences?: ProblemOccurrencesInput | null | undefined;
  properties?: PropertiesInput | null | undefined;
  queuePosition?: number | null | undefined;
  queuedDate?: string | null | undefined;
  queuedWaitReasons?: PropertiesInput | null | undefined;
  related?: RelatedInput | null | undefined;
  relatedIssues?: IssuesUsagesInput | null | undefined;
  replacementIds?: ItemsInput | null | undefined;
  resultingProperties?: PropertiesInput | null | undefined;
  revisions?: RevisionsInput | null | undefined;
  running?: boolean | null | undefined;
  runningInfo?: ProgressInfoInput | null | undefined;
  settingsHash?: string | null | undefined;
  snapshotDependencies?: BuildsInput | null | undefined;
  startDate?: string | null | undefined;
  startEstimate?: string | null | undefined;
  state?: BuildState | null | undefined;
  statistics?: PropertiesInput | null | undefined;
  status?: string | null | undefined;
  statusChangeComment?: CommentInput | null | undefined;
  statusText?: string | null | undefined;
  tags?: TagsInput | null | undefined;
  taskId?: number | null | undefined;
  testOccurrences?: TestOccurrencesInput | null | undefined;
  triggered?: TriggeredByInput | null | undefined;
  triggeringOptions?: BuildTriggeringOptionsInput | null | undefined;
  unspecifiedBranch?: boolean | null | undefined;
  usedByOtherBuilds?: boolean | null | undefined;
  user?: UserInput | null | undefined;
  vcsLabels?: ReadonlyArray<VcsLabelInput> | null | undefined;
  versionedSettingsRevision?: RevisionInput | null | undefined;
  waitReason?: string | null | undefined;
  webUrl?: string | null | undefined;
};
export type LinksInput = {
  count?: number | null | undefined;
  link?: ReadonlyArray<LinkInput> | null | undefined;
};
export type LinkInput = {
  relativeUrl?: string | null | undefined;
  type?: string | null | undefined;
  url?: string | null | undefined;
};
export type BuildTypeInput = {
  agentRequirements?: AgentRequirementsInput | null | undefined;
  artifactDependencies?: ArtifactDependenciesInput | null | undefined;
  branches?: BranchesInput | null | undefined;
  builds?: BuildsInput | null | undefined;
  compatibleAgents?: AgentsInput | null | undefined;
  compatibleCloudImages?: CloudImagesInput | null | undefined;
  description?: string | null | undefined;
  externalStatusAllowed?: boolean | null | undefined;
  features?: FeaturesInput | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  internalId?: string | null | undefined;
  investigations?: InvestigationsInput | null | undefined;
  links?: LinksInput | null | undefined;
  locator?: string | null | undefined;
  name?: string | null | undefined;
  parameters?: PropertiesInput | null | undefined;
  pauseComment?: CommentInput | null | undefined;
  paused?: boolean | null | undefined;
  project?: ProjectInput | null | undefined;
  projectId?: string | null | undefined;
  projectInternalId?: string | null | undefined;
  projectName?: string | null | undefined;
  settings?: PropertiesInput | null | undefined;
  snapshotDependencies?: SnapshotDependenciesInput | null | undefined;
  steps?: StepsInput | null | undefined;
  template?: BuildTypeInput | null | undefined;
  templateFlag?: boolean | null | undefined;
  templates?: BuildTypesInput | null | undefined;
  triggers?: TriggersInput | null | undefined;
  type?: QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateType | null | undefined;
  uuid?: string | null | undefined;
  vcsRootEntries?: VcsRootEntriesInput | null | undefined;
  vcsRootInstances?: VcsRootInstancesInput | null | undefined;
  webUrl?: string | null | undefined;
};
export type ProjectInput = {
  ancestorProjects?: ProjectsInput | null | undefined;
  archived?: boolean | null | undefined;
  buildTypes?: BuildTypesInput | null | undefined;
  cloudProfiles?: CloudProfilesInput | null | undefined;
  defaultTemplate?: BuildTypeInput | null | undefined;
  description?: string | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  internalId?: string | null | undefined;
  links?: LinksInput | null | undefined;
  locator?: string | null | undefined;
  name?: string | null | undefined;
  parameters?: PropertiesInput | null | undefined;
  parentProject?: ProjectInput | null | undefined;
  parentProjectId?: string | null | undefined;
  parentProjectInternalId?: string | null | undefined;
  parentProjectName?: string | null | undefined;
  projectFeatures?: ProjectFeaturesInput | null | undefined;
  projects?: ProjectsInput | null | undefined;
  readOnlyUi?: StateFieldInput | null | undefined;
  templates?: BuildTypesInput | null | undefined;
  uuid?: string | null | undefined;
  vcsRoots?: VcsRootsInput | null | undefined;
  virtual?: boolean | null | undefined;
  webUrl?: string | null | undefined;
};
export type StateFieldInput = {
  inherited?: boolean | null | undefined;
  value?: boolean | null | undefined;
};
export type BuildTypesInput = {
  buildType?: ReadonlyArray<BuildTypeInput> | null | undefined;
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type PropertiesInput = {
  count?: number | null | undefined;
  href?: string | null | undefined;
  property?: ReadonlyArray<PropertyInput> | null | undefined;
};
export type PropertyInput = {
  inherited?: boolean | null | undefined;
  name?: string | null | undefined;
  type?: TypeInput | null | undefined;
  value?: string | null | undefined;
};
export type TypeInput = {
  rawValue?: string | null | undefined;
};
export type VcsRootsInput = {
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
  vcsRoot?: ReadonlyArray<VcsRootInput> | null | undefined;
};
export type VcsRootInput = {
  href?: string | null | undefined;
  id?: string | null | undefined;
  internalId?: string | null | undefined;
  locator?: string | null | undefined;
  modificationCheckInterval?: number | null | undefined;
  name?: string | null | undefined;
  project?: ProjectInput | null | undefined;
  projectLocator?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  repositoryIdStrings?: ItemsInput | null | undefined;
  uuid?: string | null | undefined;
  vcsName?: string | null | undefined;
  vcsRootInstances?: VcsRootInstancesInput | null | undefined;
};
export type VcsRootInstancesInput = {
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
  vcsRootInstance?: ReadonlyArray<VcsRootInstanceInput> | null | undefined;
};
export type VcsRootInstanceInput = {
  commitHookMode?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  lastVersion?: string | null | undefined;
  lastVersionInternal?: string | null | undefined;
  modificationCheckInterval?: number | null | undefined;
  name?: string | null | undefined;
  projectLocator?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  repositoryIdStrings?: ItemsInput | null | undefined;
  repositoryState?: RepositoryStateInput | null | undefined;
  status?: VcsStatusInput | null | undefined;
  vcsName?: string | null | undefined;
  vcsRoot?: VcsRootInput | null | undefined;
  vcsRootId?: string | null | undefined;
  vcsRootInternalId?: string | null | undefined;
};
export type VcsStatusInput = {
  current?: VcsCheckStatusInput | null | undefined;
  previous?: VcsCheckStatusInput | null | undefined;
};
export type VcsCheckStatusInput = {
  requestorType?: string | null | undefined;
  status?: string | null | undefined;
  timestamp?: string | null | undefined;
};
export type RepositoryStateInput = {
  branch?: ReadonlyArray<BranchVersionInput> | null | undefined;
  count?: number | null | undefined;
  timestamp?: string | null | undefined;
};
export type BranchVersionInput = {
  active?: boolean | null | undefined;
  builds?: BuildsInput | null | undefined;
  default?: boolean | null | undefined;
  groupFlag?: boolean | null | undefined;
  internalName?: string | null | undefined;
  lastActivity?: string | null | undefined;
  name?: string | null | undefined;
  unspecified?: boolean | null | undefined;
  version?: string | null | undefined;
};
export type BuildsInput = {
  build?: ReadonlyArray<BuildInput> | null | undefined;
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type ItemsInput = {
  item?: ReadonlyArray<string> | null | undefined;
};
export type ProjectFeaturesInput = {
  count?: number | null | undefined;
  href?: string | null | undefined;
  projectFeature?: ReadonlyArray<ProjectFeatureInput> | null | undefined;
};
export type ProjectFeatureInput = {
  disabled?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  name?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  type?: string | null | undefined;
};
export type ProjectsInput = {
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
  project?: ReadonlyArray<ProjectInput> | null | undefined;
};
export type CloudProfilesInput = {
  cloudProfile?: ReadonlyArray<CloudProfileInput> | null | undefined;
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type CloudProfileInput = {
  cloudProviderId?: string | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  images?: CloudImagesInput | null | undefined;
  name?: string | null | undefined;
  project?: ProjectInput | null | undefined;
};
export type CloudImagesInput = {
  cloudImage?: ReadonlyArray<CloudImageInput> | null | undefined;
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type CloudImageInput = {
  agentPoolId?: number | null | undefined;
  agentTypeId?: number | null | undefined;
  errorMessage?: string | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  instances?: CloudInstancesInput | null | undefined;
  locator?: string | null | undefined;
  name?: string | null | undefined;
  operatingSystemName?: string | null | undefined;
  profile?: CloudProfileInput | null | undefined;
};
export type CloudInstancesInput = {
  cloudInstance?: ReadonlyArray<CloudInstanceInput> | null | undefined;
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type CloudInstanceInput = {
  agent?: AgentInput | null | undefined;
  errorMessage?: string | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  image?: CloudImageInput | null | undefined;
  name?: string | null | undefined;
  networkAddress?: string | null | undefined;
  startDate?: string | null | undefined;
  state?: string | null | undefined;
};
export type AgentInput = {
  authorized?: boolean | null | undefined;
  authorizedInfo?: AuthorizedInfoInput | null | undefined;
  build?: BuildInput | null | undefined;
  builds?: BuildsInput | null | undefined;
  cloudImage?: CloudImageInput | null | undefined;
  cloudInstance?: CloudInstanceInput | null | undefined;
  compatibilityPolicy?: CompatibilityPolicyInput | null | undefined;
  compatibleBuildTypes?: BuildTypesInput | null | undefined;
  connected?: boolean | null | undefined;
  cpuRank?: number | null | undefined;
  currentAgentVersion?: string | null | undefined;
  disconnectionComment?: string | null | undefined;
  enabled?: boolean | null | undefined;
  enabledInfo?: EnabledInfoInput | null | undefined;
  environment?: EnvironmentInput | null | undefined;
  host?: string | null | undefined;
  href?: string | null | undefined;
  id?: number | null | undefined;
  idleSinceTime?: string | null | undefined;
  incompatibleBuildTypes?: CompatibilitiesInput | null | undefined;
  ip?: string | null | undefined;
  javaOutdated?: boolean | null | undefined;
  lastActivityTime?: string | null | undefined;
  links?: LinksInput | null | undefined;
  locator?: string | null | undefined;
  name?: string | null | undefined;
  outdated?: boolean | null | undefined;
  pluginsOutdated?: boolean | null | undefined;
  pool?: AgentPoolInput | null | undefined;
  port?: number | null | undefined;
  properties?: PropertiesInput | null | undefined;
  protocol?: QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsPlannedAgentProtocol | null | undefined;
  registrationTimestamp?: string | null | undefined;
  typeId?: number | null | undefined;
  uptodate?: boolean | null | undefined;
  version?: string | null | undefined;
  webUrl?: string | null | undefined;
};
export type EnabledInfoInput = {
  comment?: CommentInput | null | undefined;
  status?: boolean | null | undefined;
  statusSwitchTime?: string | null | undefined;
};
export type CommentInput = {
  text?: string | null | undefined;
  timestamp?: string | null | undefined;
  user?: UserInput | null | undefined;
};
export type UserInput = {
  avatars?: UserAvatarsInput | null | undefined;
  email?: string | null | undefined;
  enabled2FA?: boolean | null | undefined;
  groups?: GroupsInput | null | undefined;
  hasPassword?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: number | null | undefined;
  lastLogin?: string | null | undefined;
  locator?: string | null | undefined;
  name?: string | null | undefined;
  password?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  realm?: string | null | undefined;
  roles?: RolesInput | null | undefined;
  username?: string | null | undefined;
};
export type RolesInput = {
  role?: ReadonlyArray<RoleInput> | null | undefined;
};
export type RoleInput = {
  href?: string | null | undefined;
  roleId?: string | null | undefined;
  scope?: string | null | undefined;
};
export type GroupsInput = {
  count?: number | null | undefined;
  group?: ReadonlyArray<GroupInput> | null | undefined;
};
export type GroupInput = {
  childGroups?: GroupsInput | null | undefined;
  description?: string | null | undefined;
  href?: string | null | undefined;
  key?: string | null | undefined;
  name?: string | null | undefined;
  parentGroups?: GroupsInput | null | undefined;
  properties?: PropertiesInput | null | undefined;
  roles?: RolesInput | null | undefined;
  users?: UsersInput | null | undefined;
};
export type UsersInput = {
  count?: number | null | undefined;
  user?: ReadonlyArray<UserInput> | null | undefined;
};
export type UserAvatarsInput = {
  urlToSize20?: string | null | undefined;
  urlToSize28?: string | null | undefined;
  urlToSize32?: string | null | undefined;
  urlToSize40?: string | null | undefined;
  urlToSize56?: string | null | undefined;
  urlToSize64?: string | null | undefined;
  urlToSize80?: string | null | undefined;
};
export type AuthorizedInfoInput = {
  comment?: CommentInput | null | undefined;
  status?: boolean | null | undefined;
};
export type EnvironmentInput = {
  osName?: string | null | undefined;
  osType?: string | null | undefined;
};
export type AgentPoolInput = {
  agents?: AgentsInput | null | undefined;
  href?: string | null | undefined;
  id?: number | null | undefined;
  locator?: string | null | undefined;
  maxAgents?: number | null | undefined;
  name?: string | null | undefined;
  ownerProject?: ProjectInput | null | undefined;
  projects?: ProjectsInput | null | undefined;
};
export type AgentsInput = {
  agent?: ReadonlyArray<AgentInput> | null | undefined;
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type CompatibilityPolicyInput = {
  buildTypes?: BuildTypesInput | null | undefined;
  policy?: string | null | undefined;
};
export type CompatibilitiesInput = {
  compatibility?: ReadonlyArray<CompatibilityInput> | null | undefined;
  count?: number | null | undefined;
};
export type CompatibilityInput = {
  agent?: AgentInput | null | undefined;
  buildType?: BuildTypeInput | null | undefined;
  compatible?: boolean | null | undefined;
  unmetRequirements?: RequirementsInput | null | undefined;
};
export type RequirementsInput = {
  description?: string | null | undefined;
};
export type VcsRootEntriesInput = {
  count?: number | null | undefined;
  vcsRootEntry?: ReadonlyArray<VcsRootEntryInput> | null | undefined;
};
export type VcsRootEntryInput = {
  checkoutRules?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  vcsRoot?: VcsRootInput | null | undefined;
};
export type StepsInput = {
  count?: number | null | undefined;
  step?: ReadonlyArray<StepInput> | null | undefined;
};
export type StepInput = {
  disabled?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  name?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  type?: string | null | undefined;
};
export type FeaturesInput = {
  count?: number | null | undefined;
  feature?: ReadonlyArray<FeatureInput> | null | undefined;
};
export type FeatureInput = {
  disabled?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  name?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  type?: string | null | undefined;
};
export type TriggersInput = {
  count?: number | null | undefined;
  trigger?: ReadonlyArray<TriggerInput> | null | undefined;
};
export type TriggerInput = {
  buildCustomization?: BuildTriggerCustomizationInput | null | undefined;
  disabled?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  name?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  type?: string | null | undefined;
};
export type BuildTriggerCustomizationInput = {
  enforceCleanCheckout?: boolean | null | undefined;
  enforceCleanCheckoutForDependencies?: boolean | null | undefined;
  parameters?: PropertiesInput | null | undefined;
};
export type SnapshotDependenciesInput = {
  count?: number | null | undefined;
  snapshotDependency?: ReadonlyArray<SnapshotDependencyInput> | null | undefined;
};
export type SnapshotDependencyInput = {
  disabled?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  name?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  sourceBuildType?: BuildTypeInput | null | undefined;
  type?: string | null | undefined;
};
export type ArtifactDependenciesInput = {
  artifactDependency?: ReadonlyArray<ArtifactDependencyInput> | null | undefined;
  count?: number | null | undefined;
  replace?: string | null | undefined;
};
export type ArtifactDependencyInput = {
  disabled?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  name?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  sourceBuildType?: BuildTypeInput | null | undefined;
  type?: string | null | undefined;
};
export type AgentRequirementsInput = {
  agentRequirement?: ReadonlyArray<AgentRequirementInput> | null | undefined;
  count?: number | null | undefined;
};
export type AgentRequirementInput = {
  disabled?: boolean | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  inherited?: boolean | null | undefined;
  name?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  type?: string | null | undefined;
};
export type BranchesInput = {
  branch?: ReadonlyArray<BranchInput> | null | undefined;
  count?: number | null | undefined;
  href?: string | null | undefined;
};
export type BranchInput = {
  active?: boolean | null | undefined;
  builds?: BuildsInput | null | undefined;
  default?: boolean | null | undefined;
  groupFlag?: boolean | null | undefined;
  internalName?: string | null | undefined;
  lastActivity?: string | null | undefined;
  name?: string | null | undefined;
  unspecified?: boolean | null | undefined;
};
export type InvestigationsInput = {
  count?: number | null | undefined;
  href?: string | null | undefined;
  investigation?: ReadonlyArray<InvestigationInput> | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type InvestigationInput = {
  assignee?: UserInput | null | undefined;
  assignment?: CommentInput | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  resolution?: ResolutionInput | null | undefined;
  responsible?: UserInput | null | undefined;
  scope?: ProblemScopeInput | null | undefined;
  state?: QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsTestOccurrencesTestOccurrenceItemsTestMutesMuteItemsTargetProblemsProblemItemsInvestigationsInvestigationItemsState | null | undefined;
  target?: ProblemTargetInput | null | undefined;
};
export type ProblemScopeInput = {
  applyToChildren?: boolean | null | undefined;
  buildType?: BuildTypeInput | null | undefined;
  buildTypes?: BuildTypesInput | null | undefined;
  project?: ProjectInput | null | undefined;
};
export type ProblemTargetInput = {
  anyProblem?: boolean | null | undefined;
  problems?: ProblemsInput | null | undefined;
  tests?: TestsInput | null | undefined;
};
export type TestsInput = {
  count?: number | null | undefined;
  myTestCounters?: TestCountersInput | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
  test?: ReadonlyArray<TestInput> | null | undefined;
};
export type TestCountersInput = {
  all?: number | null | undefined;
  duration?: number | null | undefined;
  failed?: number | null | undefined;
  ignored?: number | null | undefined;
  muted?: number | null | undefined;
  newFailed?: number | null | undefined;
  success?: number | null | undefined;
};
export type TestInput = {
  href?: string | null | undefined;
  id?: string | null | undefined;
  investigations?: InvestigationsInput | null | undefined;
  locator?: string | null | undefined;
  mutes?: MutesInput | null | undefined;
  name?: string | null | undefined;
  parsedTestName?: ParsedTestNameInput | null | undefined;
  testOccurrences?: TestOccurrencesInput | null | undefined;
};
export type MutesInput = {
  count?: number | null | undefined;
  href?: string | null | undefined;
  mute?: ReadonlyArray<MuteInput> | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type MuteInput = {
  assignment?: CommentInput | null | undefined;
  href?: string | null | undefined;
  id?: number | null | undefined;
  resolution?: ResolutionInput | null | undefined;
  scope?: ProblemScopeInput | null | undefined;
  target?: ProblemTargetInput | null | undefined;
};
export type ResolutionInput = {
  time?: string | null | undefined;
  type?: QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsTestOccurrencesTestOccurrenceItemsTestMutesMuteItemsTargetProblemsProblemItemsInvestigationsInvestigationItemsResolutionType | null | undefined;
};
export type TestOccurrencesInput = {
  count?: number | null | undefined;
  failed?: number | null | undefined;
  href?: string | null | undefined;
  ignored?: number | null | undefined;
  muted?: number | null | undefined;
  newFailed?: number | null | undefined;
  nextHref?: string | null | undefined;
  passed?: number | null | undefined;
  prevHref?: string | null | undefined;
  testCounters?: TestCountersInput | null | undefined;
  testOccurrence?: ReadonlyArray<TestOccurrenceInput> | null | undefined;
};
export type TestOccurrenceInput = {
  build?: BuildInput | null | undefined;
  currentlyInvestigated?: boolean | null | undefined;
  currentlyMuted?: boolean | null | undefined;
  details?: string | null | undefined;
  duration?: number | null | undefined;
  firstFailed?: TestOccurrenceInput | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  ignoreDetails?: string | null | undefined;
  ignored?: boolean | null | undefined;
  invocations?: TestOccurrencesInput | null | undefined;
  logAnchor?: string | null | undefined;
  metadata?: TestRunMetadataInput | null | undefined;
  mute?: MuteInput | null | undefined;
  muted?: boolean | null | undefined;
  name?: string | null | undefined;
  newFailure?: boolean | null | undefined;
  nextFixed?: TestOccurrenceInput | null | undefined;
  runOrder?: string | null | undefined;
  status?: QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsTestOccurrencesTestOccurrenceItemsStatus | null | undefined;
  test?: TestInput | null | undefined;
};
export type TestRunMetadataInput = {
  count?: number | null | undefined;
  typedValues?: ReadonlyArray<TypedValueInput> | null | undefined;
};
export type TypedValueInput = {
  name?: string | null | undefined;
  type?: string | null | undefined;
  value?: string | null | undefined;
};
export type ParsedTestNameInput = {
  testClass?: string | null | undefined;
  testMethodName?: string | null | undefined;
  testNameWithParameters?: string | null | undefined;
  testNameWithoutPrefix?: string | null | undefined;
  testPackage?: string | null | undefined;
  testShortName?: string | null | undefined;
  testSuite?: string | null | undefined;
};
export type ProblemsInput = {
  count?: number | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
  problem?: ReadonlyArray<ProblemInput> | null | undefined;
};
export type ProblemInput = {
  description?: string | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  identity?: string | null | undefined;
  investigations?: InvestigationsInput | null | undefined;
  locator?: string | null | undefined;
  mutes?: MutesInput | null | undefined;
  problemOccurrences?: ProblemOccurrencesInput | null | undefined;
  type?: string | null | undefined;
};
export type ProblemOccurrencesInput = {
  count?: number | null | undefined;
  failed?: number | null | undefined;
  href?: string | null | undefined;
  ignored?: number | null | undefined;
  muted?: number | null | undefined;
  newFailed?: number | null | undefined;
  nextHref?: string | null | undefined;
  passed?: number | null | undefined;
  prevHref?: string | null | undefined;
  problemOccurrence?: ReadonlyArray<ProblemOccurrenceInput> | null | undefined;
};
export type ProblemOccurrenceInput = {
  additionalData?: string | null | undefined;
  build?: BuildInput | null | undefined;
  currentlyInvestigated?: boolean | null | undefined;
  currentlyMuted?: boolean | null | undefined;
  details?: string | null | undefined;
  href?: string | null | undefined;
  id?: string | null | undefined;
  identity?: string | null | undefined;
  logAnchor?: string | null | undefined;
  mute?: MuteInput | null | undefined;
  muted?: boolean | null | undefined;
  newFailure?: boolean | null | undefined;
  problem?: ProblemInput | null | undefined;
  type?: string | null | undefined;
};
export type TagsInput = {
  count?: number | null | undefined;
  tag?: ReadonlyArray<TagInput> | null | undefined;
};
export type TagInput = {
  name?: string | null | undefined;
  owner?: UserInput | null | undefined;
  private?: boolean | null | undefined;
};
export type ApprovalInfoInput = {
  canBeApprovedByCurrentUser?: boolean | null | undefined;
  configurationValid?: boolean | null | undefined;
  groupApprovals?: GroupApprovalsInput | null | undefined;
  status?: string | null | undefined;
  timeoutTimestamp?: string | null | undefined;
  userApprovals?: UserApprovalsInput | null | undefined;
};
export type UserApprovalsInput = {
  count?: number | null | undefined;
  userApproval?: ReadonlyArray<UserApprovalRuleInput> | null | undefined;
};
export type UserApprovalRuleInput = {
  approved?: boolean | null | undefined;
  user?: UserInput | null | undefined;
};
export type GroupApprovalsInput = {
  count?: number | null | undefined;
  groupApproval?: ReadonlyArray<GroupApprovalRuleInput> | null | undefined;
};
export type GroupApprovalRuleInput = {
  currentlyApprovedBy?: UsersInput | null | undefined;
  group?: GroupInput | null | undefined;
  requiredApprovalsCount?: number | null | undefined;
};
export type ProgressInfoInput = {
  currentStageText?: string | null | undefined;
  elapsedSeconds?: number | null | undefined;
  estimatedTotalSeconds?: number | null | undefined;
  lastActivityTime?: string | null | undefined;
  leftSeconds?: number | null | undefined;
  outdated?: boolean | null | undefined;
  outdatedReasonBuild?: BuildInput | null | undefined;
  percentageComplete?: number | null | undefined;
  probablyHanging?: boolean | null | undefined;
};
export type TriggeredByInput = {
  build?: BuildInput | null | undefined;
  buildType?: BuildTypeInput | null | undefined;
  date?: string | null | undefined;
  details?: string | null | undefined;
  displayText?: string | null | undefined;
  properties?: PropertiesInput | null | undefined;
  rawValue?: string | null | undefined;
  type?: string | null | undefined;
  user?: UserInput | null | undefined;
};
export type ChangesInput = {
  change?: ReadonlyArray<ChangeInput> | null | undefined;
  count?: number | null | undefined;
  href?: string | null | undefined;
  nextHref?: string | null | undefined;
  prevHref?: string | null | undefined;
};
export type ChangeInput = {
  attributes?: PropertiesInput | null | undefined;
  canEditComment?: boolean | null | undefined;
  comment?: string | null | undefined;
  commiter?: CommiterInput | null | undefined;
  date?: string | null | undefined;
  files?: FileChangesInput | null | undefined;
  href?: string | null | undefined;
  id?: number | null | undefined;
  internalVersion?: string | null | undefined;
  locator?: string | null | undefined;
  parentChanges?: ChangesInput | null | undefined;
  parentRevisions?: ItemsInput | null | undefined;
  personal?: boolean | null | undefined;
  registrationDate?: string | null | undefined;
  snapshotDependencyLink?: SnapshotDependencyLinkInput | null | undefined;
  status?: ChangeStatusInput | null | undefined;
  storesProjectSettings?: boolean | null | undefined;
  type?: string | null | undefined;
  user?: UserInput | null | undefined;
  username?: string | null | undefined;
  vcsRootInstance?: VcsRootInstanceInput | null | undefined;
  version?: string | null | undefined;
  webUrl?: string | null | undefined;
};
export type SnapshotDependencyLinkInput = {
  build?: BuildInput | null | undefined;
  buildType?: BuildTypeInput | null | undefined;
  buildTypeBranch?: string | null | undefined;
};
export type FileChangesInput = {
  count?: number | null | undefined;
  file?: ReadonlyArray<FileChangeInput> | null | undefined;
};
export type FileChangeInput = {
  afterRevision?: string | null | undefined;
  beforeRevision?: string | null | undefined;
  changeType?: string | null | undefined;
  changeTypeComment?: string | null | undefined;
  directory?: boolean | null | undefined;
  file?: string | null | undefined;
  relativeFile?: string | null | undefined;
};
export type ChangeStatusInput = {
  cancelledBuilds?: number | null | undefined;
  compilationErrorBuilds?: BuildsInput | null | undefined;
  criticalBuilds?: BuildsInput | null | undefined;
  failedBuilds?: number | null | undefined;
  finishedBuilds?: number | null | undefined;
  newFailedTests?: number | null | undefined;
  newTestsFailedBuilds?: BuildsInput | null | undefined;
  notCriticalBuilds?: BuildsInput | null | undefined;
  otherFailedTests?: number | null | undefined;
  pendingBuildTypes?: number | null | undefined;
  queuedBuildsCount?: number | null | undefined;
  runningBuilds?: number | null | undefined;
  runningSuccessfullyBuilds?: number | null | undefined;
  successfulBuilds?: number | null | undefined;
  totalProblems?: number | null | undefined;
};
export type CommiterInput = {
  users?: UsersInput | null | undefined;
  vcsUsername?: string | null | undefined;
};
export type RevisionsInput = {
  count?: number | null | undefined;
  failOnMissingRevisions?: boolean | null | undefined;
  revision?: ReadonlyArray<RevisionInput> | null | undefined;
};
export type RevisionInput = {
  checkoutRules?: string | null | undefined;
  internalVersion?: string | null | undefined;
  vcsBranchName?: string | null | undefined;
  vcsRootInstance?: VcsRootInstanceInput | null | undefined;
  version?: string | null | undefined;
};
export type BuildChangesInput = {
  buildChange?: ReadonlyArray<BuildChangeInput> | null | undefined;
  count?: number | null | undefined;
};
export type BuildChangeInput = {
  nextBuild?: BuildInput | null | undefined;
  prevBuild?: BuildInput | null | undefined;
};
export type FilesInput = {
  count?: number | null | undefined;
  file?: ReadonlyArray<FileInput> | null | undefined;
  href?: string | null | undefined;
};
export type FileInput = {
  children?: FilesInput | null | undefined;
  content?: HrefInput | null | undefined;
  fullName?: string | null | undefined;
  href?: string | null | undefined;
  modificationTime?: string | null | undefined;
  name?: string | null | undefined;
  parent?: FileInput | null | undefined;
  size?: number | null | undefined;
};
export type HrefInput = {
  href?: string | null | undefined;
};
export type IssuesUsagesInput = {
  count?: number | null | undefined;
  href?: string | null | undefined;
  issueUsage?: ReadonlyArray<IssueUsageInput> | null | undefined;
};
export type IssueUsageInput = {
  changes?: ChangesInput | null | undefined;
  issue?: IssueInput | null | undefined;
};
export type IssueInput = {
  id?: string | null | undefined;
  url?: string | null | undefined;
};
export type EntriesInput = {
  count?: number | null | undefined;
  entry?: ReadonlyArray<EntryInput> | null | undefined;
};
export type EntryInput = {
  name?: string | null | undefined;
  value?: string | null | undefined;
};
export type DatasInput = {
  count?: number | null | undefined;
  data?: ReadonlyArray<MetaDataInput> | null | undefined;
};
export type MetaDataInput = {
  entries?: EntriesInput | null | undefined;
  id?: string | null | undefined;
};
export type RelatedInput = {
  builds?: BuildsInput | null | undefined;
};
export type BuildTriggeringOptionsInput = {
  cleanSources?: boolean | null | undefined;
  cleanSourcesInAllDependencies?: boolean | null | undefined;
  freezeSettings?: boolean | null | undefined;
  queueAtTop?: boolean | null | undefined;
  rebuildAllDependencies?: boolean | null | undefined;
  rebuildDependencies?: BuildTypesInput | null | undefined;
  rebuildFailedOrIncompleteDependencies?: boolean | null | undefined;
  tagDependencies?: boolean | null | undefined;
};
export type VcsLabelInput = {
  buildId?: number | null | undefined;
  failureReason?: string | null | undefined;
  status?: QueryGetAllAgentPoolsAgentPoolItemsOwnerProjectDefaultTemplateVcsRootEntriesVcsRootEntryItemsVcsRootVcsRootInstancesVcsRootInstanceItemsRepositoryStateBranchItemsBuildsBuildItemsVcsLabelsItemsStatus | null | undefined;
  text?: string | null | undefined;
  vcsRootInstance?: VcsRootInstanceInput | null | undefined;
};
export type CustomizationsInput = {
  artifactDependencies?: any | null | undefined;
  changes?: any | null | undefined;
  parameters?: any | null | undefined;
};
export type DownloadedArtifactsInput = {
  count?: number | null | undefined;
  downloadInfo?: ReadonlyArray<DownloadInfoInput> | null | undefined;
  unfilteredCount?: number | null | undefined;
};
export type DownloadInfoInput = {
  artifactInfo?: ReadonlyArray<ArtifactDownloadInfoInput> | null | undefined;
  build?: BuildInput | null | undefined;
  count?: number | null | undefined;
};
export type ArtifactDownloadInfoInput = {
  downloadTimestamp?: string | null | undefined;
  path?: string | null | undefined;
};
export type MoveToTopMutation$variables = {
  build?: BuildInput | null | undefined;
  queuePosition: string;
};
export type MoveToTopMutation$data = {
  readonly setQueuedBuildPosition: {
    readonly queuePosition: number | null | undefined;
  } | null | undefined;
};
export type MoveToTopMutation = {
  response: MoveToTopMutation$data;
  variables: MoveToTopMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "build"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queuePosition"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "build"
  },
  {
    "kind": "Variable",
    "name": "queuePosition",
    "variableName": "queuePosition"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "queuePosition",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MoveToTopMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "setQueuedBuildPosition",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MoveToTopMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "setQueuedBuildPosition",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "272f0331dbc9fa0d311d93adcec2acd4",
    "id": null,
    "metadata": {},
    "name": "MoveToTopMutation",
    "operationKind": "mutation",
    "text": "mutation MoveToTopMutation(\n  $queuePosition: String!\n  $build: BuildInput\n) {\n  setQueuedBuildPosition(queuePosition: $queuePosition, input: $build) {\n    queuePosition\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd7f489128d83f291af41d9bc71668b7";

export default node;
