import {Environment, Network, RecordSource, Store} from 'relay-runtime'
import {createMockEnvironment} from 'relay-test-utils'

import {fetchFn} from './fetchFn'

const environment = new Environment({
  network: Network.create(fetchFn),
  store: new Store(new RecordSource()),
  getDataID: fieldValue => fieldValue._id ?? fieldValue.id,
})
export default () => environment

export const getTestEnvironment = () =>
  createMockEnvironment({
    getDataID: fieldValue =>
      fieldValue._id ? (fieldValue._id.startsWith('<mock') ? null : fieldValue._id) : fieldValue.id,
  })
