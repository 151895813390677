/**
 * @generated SignedSource<<4155be5a67835e524a74822a1352ac3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentsPoolsFavoritePageQuery$variables = Record<PropertyKey, never>;
export type AgentsPoolsFavoritePageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AgentsPagesCanViewAgentDetailsFragment">;
};
export type AgentsPoolsFavoritePageQuery = {
  response: AgentsPoolsFavoritePageQuery$data;
  variables: AgentsPoolsFavoritePageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsPoolsFavoritePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AgentsPagesCanViewAgentDetailsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AgentsPoolsFavoritePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6dc9c32239671b036dd4740ab4af003",
    "id": null,
    "metadata": {},
    "name": "AgentsPoolsFavoritePageQuery",
    "operationKind": "query",
    "text": "query AgentsPoolsFavoritePageQuery {\n  ...AgentsPagesCanViewAgentDetailsFragment\n}\n\nfragment AgentsPagesCanViewAgentDetailsFragment on Query {\n  agentPoolsRest {\n    count\n  }\n}\n"
  }
};

(node as any).hash = "dab31c12d7ba76b5bc11541403e551dd";

export default node;
