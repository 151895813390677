/**
 * @generated SignedSource<<c02fca9008fda750cb54ddce669d5817>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AgentsPagesAgentTypesQuery$variables = {
  skipParameters?: boolean | null | undefined;
};
export type AgentsPagesAgentTypesQuery$data = {
  readonly agentPoolsRest: {
    readonly agentPool: ReadonlyArray<{
      readonly agentTypes: {
        readonly agentType: ReadonlyArray<{
          readonly configurationParameters?: {
            readonly property: ReadonlyArray<{
              readonly name: string | null | undefined;
              readonly value: string | null | undefined;
            }> | null | undefined;
          } | null | undefined;
          readonly environment: {
            readonly osName: string | null | undefined;
            readonly osType: string | null | undefined;
          } | null | undefined;
          readonly id: number | null | undefined;
          readonly isCloud: boolean | null | undefined;
          readonly name: string | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly id: number | null | undefined;
      readonly name: string | null | undefined;
    }> | null | undefined;
  } | null | undefined;
};
export type AgentsPagesAgentTypesQuery = {
  response: AgentsPagesAgentTypesQuery$data;
  variables: AgentsPagesAgentTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": true,
    "kind": "LocalArgument",
    "name": "skipParameters"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCloud",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Environment",
  "kind": "LinkedField",
  "name": "environment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "osType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "osName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "condition": "skipParameters",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "locator",
          "value": "name:(value:(teamcity.agent.jbHosted),matchType:equals)"
        }
      ],
      "concreteType": "Properties",
      "kind": "LinkedField",
      "name": "configurationParameters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Property",
          "kind": "LinkedField",
          "name": "property",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "configurationParameters(locator:\"name:(value:(teamcity.agent.jbHosted),matchType:equals)\")"
    }
  ]
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsPagesAgentTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentTypesRest",
                "kind": "LinkedField",
                "name": "agentTypes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentTypeRest",
                    "kind": "LinkedField",
                    "name": "agentType",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentsPagesAgentTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentTypesRest",
                "kind": "LinkedField",
                "name": "agentTypes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentTypeRest",
                    "kind": "LinkedField",
                    "name": "agentType",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "05bd2a3938418f82b9d6eedea929777f",
    "id": null,
    "metadata": {},
    "name": "AgentsPagesAgentTypesQuery",
    "operationKind": "query",
    "text": "query AgentsPagesAgentTypesQuery(\n  $skipParameters: Boolean = true\n) {\n  agentPoolsRest {\n    agentPool {\n      id\n      name\n      agentTypes {\n        agentType {\n          id\n          name\n          isCloud\n          environment {\n            osType\n            osName\n          }\n          configurationParameters(locator: \"name:(value:(teamcity.agent.jbHosted),matchType:equals)\") @skip(if: $skipParameters) {\n            property {\n              name\n              value\n            }\n          }\n          _id\n        }\n      }\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c78a2f5a017178db6299c8b2291ea3f9";

export default node;
