import alertService from '@jetbrains/ring-ui/components/alert-service/alert-service'
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {useAppSelector} from '../../../../hooks/react-redux'
import {useThrottle} from '../../../../hooks/useThrottle'
import {restApi, VsStatusType} from '../../../../services/rest'
import {subscribeOnProjectEvents} from '../../../../utils/subscriber'
import {VERSIONED_SETTINGS_STATUS_UPDATED} from '../../../../utils/subscriptionEvents'
import {useBanners} from '../contexts/Banners/BannersContext'
import {getOriginalVersionedSettings} from '../EditPipelinePage/EditPipelinePage.selectors'
import {getExtractErrorMessage} from '../EditPipelinePage/EditPipelinePage.utils'
import PipelineAlertServiceError from '../EditPipelinePage/PipelineAlertServiceError/PipelineWrapperAlertMessage'
import {pipelinesApi} from '../services/pipelinesApi'
import type {NewPipeline} from '../services/pipelinesApi.types'
import {AlertMessageType} from '../types/AlertMessages'
import {isStoreYamlInVcsEnabled} from '../utils/featureToggles'

import {usePipelineId} from './pipeline'

const THROTTLER_TIMEOUT = 15000

function toHealthItemType(type: VsStatusType) {
  switch (type) {
    case VsStatusType.INFO:
      return AlertMessageType.SUCCESS
    case VsStatusType.WARN:
      return AlertMessageType.ERROR
    default:
      return AlertMessageType.INFO
  }
}

export function useVersionedSettingsStatus() {
  const pipelineId = usePipelineId()
  const {addBanner} = useBanners()
  const original = useAppSelector(state => state.pipelines.pipelineDraft[pipelineId]?.original)
  const storedInRepo = useAppSelector(getOriginalVersionedSettings(pipelineId))?.storedInRepo
  const [fetch, {status}] = restApi.endpoints.getVsStatus.useLazyQuery({
    selectFromResult: ({data}) => ({
      status: data,
    }),
  })
  const fetchVsStatus = useThrottle(fetch, THROTTLER_TIMEOUT)
  const [updatePipeline, {error}] = pipelinesApi.endpoints.updatePipeline.useMutation()
  const location = useLocation()
  const [showInfoMessages, setShowInfoMessages] = useState(location?.state?.isCreated ?? false)

  useEffect(() => {
    if (error != null) {
      const errorMessage = getExtractErrorMessage(error)
      alertService.error(React.createElement(PipelineAlertServiceError, null, errorMessage))
    }
  }, [error])

  const saveOnServerHandler = useCallback(async () => {
    await updatePipeline({
      id: pipelineId,
      body: {
        ...(original as NewPipeline),
        versionedSettings: {storedInRepo: false},
      },
    })
  }, [original, pipelineId, updatePipeline])

  useEffect(() => {
    if (isStoreYamlInVcsEnabled && storedInRepo) {
      subscribeOnProjectEvents(pipelineId, [VERSIONED_SETTINGS_STATUS_UPDATED], () => {
        fetchVsStatus({projectLocator: pipelineId, fields: 'message,type,timestamp'})
      })
    }
  }, [fetchVsStatus, pipelineId, storedInRepo])

  useEffect(() => {
    if (status?.type === VsStatusType.WARN) {
      addBanner(pipelineId, {
        key: `${pipelineId}-${status.timestamp}`,
        message: status.message,
        type: toHealthItemType(status.type),
        actions:
          original != null
            ? [{label: 'Save on TeamCity server', onClick: saveOnServerHandler}]
            : [],
      })
    } else if (showInfoMessages && status?.type === VsStatusType.INFO) {
      addBanner(pipelineId, {
        key: `${pipelineId}-${status.timestamp}`,
        title: 'Initial commit will be made shortly.',
        message: status.message,
        type: toHealthItemType(status.type),
        closeable: true,
      })
      setShowInfoMessages(false)
    }
  }, [original, status, addBanner, showInfoMessages, saveOnServerHandler, pipelineId])
}
