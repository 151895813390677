/**
 * @generated SignedSource<<84ddc1fea4ab1ff3fbf09071f0deeaf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BuildArtifactStorageInfoFeatureQuery$variables = {
  featureLocator: string;
  projectLocator: string;
  skip: boolean;
};
export type BuildArtifactStorageInfoFeatureQuery$data = {
  readonly feature?: {
    readonly properties: {
      readonly property: ReadonlyArray<{
        readonly name: string | null | undefined;
        readonly value: string | null | undefined;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type BuildArtifactStorageInfoFeatureQuery = {
  response: BuildArtifactStorageInfoFeatureQuery$data;
  variables: BuildArtifactStorageInfoFeatureQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "featureLocator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectLocator"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "kind": "Variable",
    "name": "featureLocator",
    "variableName": "featureLocator"
  },
  {
    "kind": "Variable",
    "name": "projectLocator",
    "variableName": "projectLocator"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Properties",
  "kind": "LinkedField",
  "name": "properties",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildArtifactStorageInfoFeatureQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectFeature",
            "kind": "LinkedField",
            "name": "feature",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "BuildArtifactStorageInfoFeatureQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectFeature",
            "kind": "LinkedField",
            "name": "feature",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c24156306912a36965e0367fb06e29a5",
    "id": null,
    "metadata": {},
    "name": "BuildArtifactStorageInfoFeatureQuery",
    "operationKind": "query",
    "text": "query BuildArtifactStorageInfoFeatureQuery(\n  $projectLocator: String!\n  $featureLocator: String!\n  $skip: Boolean!\n) {\n  feature(projectLocator: $projectLocator, featureLocator: $featureLocator) @skip(if: $skip) {\n    properties {\n      property {\n        name\n        value\n      }\n    }\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fd5f0c8a6713a72c7829142ea39d6b2";

export default node;
