/**
 * @generated SignedSource<<18f5c0476dfec7696ae2f961cc00f85c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type QueueActionsBuildsQuery$variables = {
  locator: string;
};
export type QueueActionsBuildsQuery$data = {
  readonly queuedBuilds: {
    readonly build: ReadonlyArray<{
      readonly id: number | null | undefined;
    }> | null | undefined;
  } | null | undefined;
};
export type QueueActionsBuildsQuery = {
  response: QueueActionsBuildsQuery$data;
  variables: QueueActionsBuildsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "locator",
    "variableName": "locator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueueActionsBuildsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Builds",
        "kind": "LinkedField",
        "name": "queuedBuilds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueueActionsBuildsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Builds",
        "kind": "LinkedField",
        "name": "queuedBuilds",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad98f96fcd83cf4253e15ba22eb201c9",
    "id": null,
    "metadata": {},
    "name": "QueueActionsBuildsQuery",
    "operationKind": "query",
    "text": "query QueueActionsBuildsQuery(\n  $locator: String!\n) {\n  queuedBuilds(locator: $locator) {\n    build {\n      id\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b16f900b65f45711c263fc401d7266cb";

export default node;
