import {createSelector} from 'reselect'

import {
  getAgentPoolsTree,
  getAgentSelectionPools,
  getFavoriteAgentTree,
} from '../../AgentsPages.selectors'
import {sortDiverseChildren, sortUnathorizedChildren} from '../../AgentsPages.utils'
import type {AgentsTreeRootNodeChild, AgentsTreeAgentNode} from '../AgentsSidebar.types'

export const sortAgentsTree = <T extends {children: Array<AgentsTreeRootNodeChild>}>(
  agentsTree: Array<T>,
) =>
  agentsTree.map(node => ({
    ...node,
    children: sortDiverseChildren(node.children),
  }))
const sortAgentsUnathorizedTree = <T extends {children: Array<AgentsTreeRootNodeChild>}>(
  agentsTree: Array<T>,
) =>
  agentsTree.map(node => ({
    ...node,
    children: sortUnathorizedChildren(node.children as AgentsTreeAgentNode[]),
  }))

const getAgentSelectionsList = createSelector([getAgentSelectionPools], agentSelectionPools =>
  agentSelectionPools.unauth.count === 0 ? [] : [agentSelectionPools.unauth],
)
export const sortedSelectionsTree = createSelector(
  [getAgentSelectionsList],
  sortAgentsUnathorizedTree,
)
export const sortedAgentsTree = createSelector([getAgentPoolsTree], sortAgentsTree)
export const sortedFavoriteAgentsTree = createSelector([getFavoriteAgentTree], sortAgentsTree)
