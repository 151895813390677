// Those imports magically fix styles order
import '@jetbrains/ring-ui/components/button/button.css'
import '@jetbrains/ring-ui/components/link/link.css'
import './components/common/EditProjectSidebar/EditProjectSidebar.css'

import type {AlertType} from '@jetbrains/ring-ui/components/alert/alert'
import type {AlertItem} from '@jetbrains/ring-ui/components/alert-service/alert-service'
import AlertService from '@jetbrains/ring-ui/components/alert-service/alert-service'
import {Anchor} from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {
  ControlsHeight,
  ControlsHeightContext,
} from '@jetbrains/ring-ui/components/global/controls-height'
import Theme from '@jetbrains/ring-ui/components/global/theme'
import {Type as ListItemType} from '@jetbrains/ring-ui/components/list/consts'
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import type {MessageProps} from '@jetbrains/ring-ui/components/message/message'
import {Directions as PopupDirections} from '@jetbrains/ring-ui/components/popup/popup.consts'
import type {SelectAttrs} from '@jetbrains/ring-ui/components/select/select'
import type {PlaceId} from '@jetbrains/teamcity-api'
import type {AlertKey} from '@jetbrains/teamcity-api/types/services/AlertService.d'
import type {ReactNode} from 'react'
import * as React from 'react'
import {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import {renderToStaticMarkup} from 'react-dom/server'
import {Provider} from 'react-redux'
import type {EntryPoint, EntryPointComponent} from 'react-relay'
import {
  commitMutation,
  EntryPointContainer,
  fetchQuery,
  loadEntryPoint,
  loadQuery,
  RelayEnvironmentProvider,
} from 'react-relay'
import type {PreloadedEntryPoint, PreloadedQuery} from 'react-relay/relay-hooks/EntryPointTypes'
import {useLoaderData} from 'react-router'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import type {OperationType} from 'relay-runtime'

import {
  addPlugin,
  dummyAction,
  openDialog,
  receiveAgentsInCloud,
  receiveServerInfo,
  removePlugin,
  setIsSakuraUI,
  setRouteAvailabilityResponse,
  storeUrlExtensions,
} from './actions'
import {multiplyReceiveBuild, receiveBuild, receiveBuildWSData} from './actions/builds'
import {fetchArchivedProjects, fetchProjectsWithArchivedData} from './actions/projects'
import {setGlobalTheme} from './actions/theme'
import type {Router} from './components/App/App.routes'
import {createAppRouter} from './components/App/App.routes'
import type {HeaderProps} from './components/App/Header/Header'
import HeaderLazy from './components/App/Header/Header.lazy'
import {setActivePageId, setAdminSpaceAvailable} from './components/App/Header/Header.utils'
import type {NodeInfo} from './components/App/Header/HeaderUser/NodeSwitcher/NodeSwitcher.slices'
import {nodeInfo} from './components/App/Header/HeaderUser/NodeSwitcher/NodeSwitcher.slices'
import type {HeaderHealthItemsProps} from './components/App/Header/HealthItem/HeaderHealthItems'
import HeaderHealthItems from './components/App/Header/HealthItem/HeaderHealthItems'
import Version from './components/App/MainPanel/Version/Version'
import type {SelectBuildRunnerProps} from './components/App/SelectBuildRunners/SelectBuildRunners.types'
import AgentsFetcher from './components/classic/Agents/Agents.fetcher'
import type {AgentsOwnProps} from './components/classic/Agents/Agents.types'
import type {Props as BuildTypeOverviewProps} from './components/classic/BuildTypeOverview/BuildTypeOverview'
import {BuildTypeOverviewEntryPoint} from './components/classic/BuildTypeOverview/BuildTypeOverview.entryPoint'
import type {ClassicUIQueryLoaderQuery} from './components/classic/ClassicUIQueryLoader/__generated__/ClassicUIQueryLoaderQuery.graphql'
import {
  ClassicUIQueryLoader,
  classicUIQueryLoaderQuery,
} from './components/classic/ClassicUIQueryLoader/ClassicUIQueryLoader'
import ProblemsSummary from './components/classic/ProblemsSummary/ProblemsSummary'
import type {SakuraReleaseBannerProps} from './components/classic/SakuraReleaseBanner/SakuraReleaseBanner'
import SakuraRelease from './components/classic/SakuraReleaseBanner/SakuraReleaseBanner'
import type {AgentAuthProps} from './components/common/AgentAuth/AgentAuth.types'
import Avatar from './components/common/Avatar/Avatar'
import type {AvatarEditorQuery} from './components/common/AvatarEditor/__generated__/AvatarEditorQuery.graphql'
import type {AvatarEditorProps} from './components/common/AvatarEditor/AvatarEditor'
import AvatarEditor, {avatarEditorQuery} from './components/common/AvatarEditor/AvatarEditor'
import BranchLabel from './components/common/BranchLabel/BranchLabel'
import {BranchSelectEntryPoint} from './components/common/BranchSelect/BranchSelect.entryPoint'
import type {BranchSelectProps} from './components/common/BranchSelect/BranchSelect.types'
import type {BuildActionsDropdownProps} from './components/common/BuildActionsDropdown/BuildActionsDropdown'
import BuildApproval from './components/common/BuildApproval/BuildApproval'
import BuildArtifacts from './components/common/BuildArtifacts/BuildArtifacts.container'
import BuildArtifactsTree from './components/common/BuildArtifactsTree/BuildArtifactsTree'
import BuildDuration from './components/common/BuildDuration/BuildDuration.container'
import BuildNumberContainer, {
  BuildNumberAPI,
} from './components/common/BuildNumber/BuildNumber.container'
import type {BuildsOwnProps} from './components/common/Builds/Builds.types'
import {BuildStatusWidgetEntryPoint} from './components/common/BuildStatusWidget/BuildStatusWidget.entryPoint'
import BuildTag from './components/common/BuildTag/BuildTag'
import {ContentPanelAPI} from './components/common/ContentPanel/ContentPanel'
import HelpDropdown from './components/common/HelpDropdown/HelpDropdown'
import Icon from './components/common/Icon/Icon'
import Loader from './components/common/Loader/Loader'
import type {MarkdownProps} from './components/common/Markdown/Markdown'
import MiddleEllipsis from './components/common/MiddleEllipsis/MiddleEllipsis'
import {submitPager} from './components/common/Pager/Pager.actions.base'
import PluginPlace from './components/common/Plugin/Plugin'
import type {ProjectBuildTypeSelectProps} from './components/common/ProjectBuildTypeSelect/ProjectBuildTypeSelect'
import ProjectOrBuildTypeIcon from './components/common/ProjectOrBuildTypeIcon/ProjectOrBuildTypeIcon'
import type {ProjectsPopupProps} from './components/common/ProjectsPopup/ProjectsPopup'
import {ProjectsPopupEntryPoint} from './components/common/ProjectsPopup/ProjectsPopup.entryPoint'
import {RouterButtonAPI, RouterLinkAPI} from './components/common/RouterLink/RouterLink'
import SkipNav from './components/common/SkipNav/SkipNav'
import StopBuild from './components/common/StopBuild/StopBuild.container'
import type {TagsFiltersProps} from './components/common/TagsList/TagsFilters'
import {TagsFiltersEntryPoint} from './components/common/TagsList/TagsFilters.entryPoint'
import {OpenInSakuraUIEntryPoint} from './components/common/ToggleSakuraUI/OpenInSakuraUI.entryPoint'
import type {OpenInSakuraUIProps} from './components/common/ToggleSakuraUI/ToggleSakuraUI.types'
import {
  getSakuraUIHref,
  redirectToClassicUI,
  redirectToSakuraUI,
} from './components/common/ToggleSakuraUI/ToggleSakuraUI.utils'
import Visible from './components/common/Visible/Visible'
import {observeVisibilityMulticast, stopObserving} from './components/common/Visible/Visible.utils'
import type {BuildTypeDslEditorProps} from './components/packages/Dsl/BuildTypeDslEditor/BuildTypeDslEditor'
import {hideDsl, storeDslFragment} from './components/packages/Dsl/Dsl.actions'
import type {FragmentDslEditorProps} from './components/packages/Dsl/FragmentDslEditor/FragmentDslEditor'
import ShowDsl from './components/packages/Dsl/ShowDsl/ShowDsl'
import {addHint, removeHint} from './components/packages/Hints/Hints.actions'
import type {Hint, HintId} from './components/packages/Hints/Hints.types'
import {BuildProblemInvestigationHistoryPopupDummy} from './components/packages/InvestigationHistory/BuildProblemInvestigationHistoryPopup/BuildProblemInvestigationHistoryPopup'
import {BuildTypeInvestigationHistoryPopupDummy} from './components/packages/InvestigationHistory/BuildTypeInvestigationHistoryPopup/BuildTypeInvestigationHistoryPopup'
import {TestInvestigationHistoryPopupDummy} from './components/packages/InvestigationHistory/TestInvestigationHistoryPopup/TestInvestigationHistoryPopup'
import {MatrixParamBuildEntryPoint} from './components/packages/MatrixParamBuild/MatrixParamBuild.entryPoint'
import type {MatrixParamBuildProps} from './components/packages/MatrixParamBuild/MatrixParamBuild.types'
import {BuildTypeHistoryEntryPoint} from './components/pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.entryPoint'
import type {BaseProps as BuildTypeHistoryProps} from './components/pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.types'
import {CleanupProjectPageEntryPoint} from './components/pages/CleanupProjectPage/CleanupProjectPage.entryPoint'
import type {OuterProps as CleanupPoliciesProps} from './components/pages/CleanupProjectPage/CleanupProjectPage.types'
import {RulesEntryPoint} from './components/pages/CleanupProjectPage/Listings/Rules/Rules.entryPoint'
import type {OuterProps as CleanupProps} from './components/pages/CleanupProjectPage/Listings/Rules/Rules.types'
import MetricsTab from './components/pages/DiagnosticsPage/MetricsTab/MetricsTab'
import {EditFederationEntryPoint} from './components/pages/NodesConfigurationPage/EditFederation/EditFederation.entryPoint'
import PipelinesBetaBanner from './components/pages/PipelinesPages/components/PipelinesBetaBanner/PipelinesBetaBanner'
import PipelinesPromoBanner from './components/pages/PipelinesPages/components/PipelinesPromoBanner/PipelinesPromoBanner'
import {isPipelinesEnabledInExclusiveMode} from './components/pages/PipelinesPages/utils/featureToggles'
import {updateQueue} from './components/pages/QueuePage/QueueBuildList/QueueBuildList.streams'
import type {AgentHistoryProps} from './containers/AgentHistory'
import type {AllBuildsProps} from './containers/AllBuilds'
import BuildLineLayout from './containers/BuildLineLayout'
import BuildsFetcher from './containers/BuildsFetcher'
import {EntityPathAPI} from './containers/EntityPath'
import type {ReactModule} from './containers/wrappedLazy'
import {wrappedLazy} from './containers/wrappedLazy'
import {EntityProvider} from './contexts/entity'
import type {useSetUserPropertyMutation} from './hooks/__generated__/useSetUserPropertyMutation.graphql'
import {
  getSetUserPropertyUpdater,
  useSetUserPropertyMutationDefinition,
} from './hooks/useSetUserProperty'
import type {branchExistsInBuildTypeQuery} from './queries/__generated__/branchExistsInBuildTypeQuery.graphql'
import {branchExistsInBuildTypeQueryDefinition} from './queries/branchExistsInBuildType'
import type {State} from './reducers/types'
import getEnvironment from './relay/getEnvironment'
import type {FetchBranchesParams} from './rest/branches'
import {getBranchesLocator, getIsBranchPresentInBuildTypeVariables} from './rest/branches'
import {getProjectsArg, overviewArg, sidebarArg} from './rest/projects'
import {browserHistory} from './routes'
import {getAgents, getAgentsReady} from './selectors'
import type {Branch} from './services/rest'
import {restApi} from './services/rest'
import * as darkThemeAdapter from './services/theme'
import {isGlobalDarkModeAllowed} from './services/theme/defaults'
import {initialTheme} from './slices/initialTheme'
import store from './store'
import type {
  AgentId,
  Build,
  BuildId,
  BuildTypeId,
  BuildTypeInternalId,
  NormalizedBuild,
  ProblemId,
  ProblemOccurrenceId,
  ProjectId,
  ProjectInternalId,
  RouteAvailabilityResponse,
  ServerInfo,
  TestId,
  UrlExtension,
} from './types'
import {DialogType, getBuildTypeFilter, stringifyId} from './types'
import {defaultBranch, stringifyBranch} from './utils/branchNames'
import type {ColorValueArray} from './utils/colorByString'
import {getColorRgbByString} from './utils/colorByString'
import {getSandbox, removeSandbox} from './utils/fastdom'
import {getDisplayName} from './utils/getDisplayName'
import type {WritableKeyValue} from './utils/object'
import {queryToObject} from './utils/queryParams'
import type {Refetchable} from './utils/refetchable'
import {
  createRefetchableSubscriptions,
  combineRefetchables,
  createRefetchableSubscription,
} from './utils/refetchable'
import setupMoment from './utils/setupMoment'
import showHide from './utils/showHide'
import createSimpleStream from './utils/simpleStream'
import {
  DEFAULT_DEBOUNCE_INTERVAL,
  getTopics,
  PostponeMethod,
  subscribeOnOverallEvents,
} from './utils/subscriber'
import {
  PROJECT_ARCHIVED,
  PROJECT_CREATED,
  PROJECT_DEARCHIVED,
  PROJECT_REMOVED,
} from './utils/subscriptionEvents'
import type {UserProperty} from './utils/userProperties'
import {UserProperties} from './utils/userProperties'
import {
  cleanServiceWorkerCaches,
  registerServiceWorker,
  setupServiceWorker,
  unregisterServiceWorker,
} from './workers/setupServiceWorker'
import setupServiceWorkerChecker from './workers/setupServiceWorkerChecker'
import {requestSWUpdate} from './workers/sw.lifecycle'

import './ring-globals.css'

const ScheduleBuild = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ScheduleBuild", webpackPrefetch: true */
      './components/classic/ScheduleBuild/ScheduleBuild'
    ),
)
const AgentHistory: React.ComponentType<AgentHistoryProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentHistory", webpackPrefetch: true */
      './containers/AgentHistory'
    ),
)
const AllBuilds: React.ComponentType<AllBuildsProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AllBuilds", webpackPrefetch: true */
      './containers/AllBuilds'
    ),
)
const LicensesPage = React.lazy(
  () =>
    /* webpackChunkName: "LicensesPage", webpackPrefetch: true */
    import('./components/pages/LicensesPage/LicensesPage'),
)
const TwoFactorAuthSettingsPage = React.lazy(
  () =>
    import(
      './components/pages/TwoFactorAuthPages/TwoFactorAuthSettingsPage/TwoFactorAuthSettingsPage'
    ),
)
const TwoFactorAuthLoginPage = React.lazy(
  () =>
    import('./components/pages/TwoFactorAuthPages/TwoFactorAuthLoginPage/TwoFactorAuthLoginPage'),
)
const HttpsConfiguration = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "HttpsConfiguration", webpackPrefetch: true */
      './components/pages/HttpsConfigurationPage/HttpsConfigurationPage'
    ),
  <LoaderInline>{'Loading...'}</LoaderInline>,
)
const BuildsContainer: React.ComponentType<BuildsOwnProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Builds", webpackPrefetch: true */
      './components/common/Builds/Builds.container'
    ),
)

const AppearanceDropdown: React.ComponentType = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AppearanceDropdown", webpackPrefetch: true */
      './components/App/Header/ThemeSwitcher/AppearanceDropdown'
    ),
)
const BuildsTvMode = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildsTvMode", webpackPrefetch: true */
      './components/common/BuildsTvMode/BuildsTvMode.container'
    ),
)
const Hints: React.ComponentType = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Hints", webpackPrefetch: true */
      './components/packages/Hints/Hints'
    ),
)
const HintPopup = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HintPopup", webpackPrefetch: true */
      './components/packages/Hints/HintPopup/HintPopup'
    ),
)
const FavoriteBuilds = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FavoriteBuilds", webpackPrefetch: true */
      './components/pages/FavoriteBuildsPage/FavoriteBuilds/FavoriteBuilds'
    ),
)
const ProjectBuildTypeSelect: React.ComponentType<ProjectBuildTypeSelectProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectBuildTypeSelect", webpackPrefetch: true */
      './components/common/ProjectBuildTypeSelect/ProjectBuildTypeSelect'
    ),
)
const AgentAuth: React.ComponentType<AgentAuthProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentAuth", webpackPrefetch: true */
      './components/common/AgentAuth/AgentAuth'
    ),
)
const BuildTypeDslEditor = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildTypeDslEditor", webpackPrefetch: true */
      './components/packages/Dsl/BuildTypeDslEditor/BuildTypeDslEditor'
    ),
)
const FragmentDslEditor: React.ComponentType<FragmentDslEditorProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FragmentDslEditor", webpackPrefetch: true */
      './components/packages/Dsl/FragmentDslEditor/FragmentDslEditor'
    ),
)
const Pager: React.ComponentType<any> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Pager", webpackPrefetch: true */
      '@jetbrains/ring-ui/components/pager/pager'
    ),
)

const Markdown: React.ComponentType<MarkdownProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Markdown", webpackPrefetch: true */
      './components/common/Markdown/Markdown'
    ),
)

const AgentsContainer: React.ComponentType<AgentsOwnProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentsContainer", webpackPrefetch: true */
      './components/classic/Agents/Agents.container'
    ),
)
const Message: React.ComponentType<MessageProps> = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Message", webpackPrefetch: true */
      '@jetbrains/ring-ui/components/message/message'
    ),
)
const ServiceMessage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ServiceMessage", webpackPrefetch: true */
      './components/common/ServiceMessage/ServiceMessage'
    ),
)
const ErrorBoundary = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ErrorBoundary", webpackPrefetch: true */
      './components/common/ErrorBoundary/ErrorBoundary'
    ),
)

const Select = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Select", webpackPrefetch: true */
      '@jetbrains/ring-ui/components/select/select'
    ) as Promise<ReactModule<SelectAttrs>>,
)

const Sorter = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Sorter", webpackPrefetch: true */
      './components/classic/Sorter/Sorter.container'
    ),
)

const AdminSelectBuildRunner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SelectBuildRunners", webpackPrefetch: true */
      './components/App/SelectBuildRunners/SelectBuildRunners'
    ),
)

const BuildActionsDropdown = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildActionsDropdown", webpackPrefetch: true */
      './components/common/BuildActionsDropdown/BuildActionsDropdown'
    ),
)

setupMoment()
const dataCache: WritableKeyValue<string, string> = {}
const dataCacheTimeouts: WritableKeyValue<string, number> = {}
const STALE_TIMEOUT = 60000

const roots = new WeakMap()
function createRootAndRender(element: React.ReactElement, container: Element) {
  let root = roots.get(container)
  if (root == null) {
    root = createRoot(container)
    roots.set(container, root)
  }
  root.render(element)
}

// noinspection JSUnusedGlobalSymbols
const ReactUI = {
  buildNumber: process.env.BUILD_NUMBER ?? ('Local' as string),
  buildId: process.env.TEAMCITY_BUILD_ID,
  isSakuraUI: false,
  store,
  createElement: React.createElement,
  getExperimentalUIHref: getSakuraUIHref, // Depricated
  getSakuraUIHref,

  /* Depricated */
  get isExperimentalUI() {
    // eslint-disable-next-line no-console
    console.warn(
      'ReactUI.isExperimentalUI is deprecated and will be removed in upcoming release. Please, use the isSakuraUI',
    )
    return this.isSakuraUI
  },

  /* Depricated */
  set isExperimentalUI(value: boolean) {
    // eslint-disable-next-line no-console
    console.warn(
      'ReactUI.isExperimentalUI is deprecated and will be removed in upcoming release. Please, use the isSakuraUI',
    )
    this.isSakuraUI = value
  },

  unmountComponentAtNode(el: HTMLElement) {
    try {
      roots.get(el)?.unmount()
      roots.delete(el)
    } catch {
      // ignore errors
    }
  },

  createSimpleStream,
  updateQueue,
  UserProperties,
  containers: new Map() as Map<string, HTMLElement>,

  getContainer(id: string): HTMLElement {
    const placeholder = document.getElementById(id)
    const tagName = placeholder?.tagName ?? 'div'
    let container = this.containers.get(id)

    if (!container?.hasChildNodes()) {
      this.performCleanups(container)
      this.clearUnusedTO(id)
      container = document.createElement(tagName)
      this.containers.set(id, container)
    }

    return container
  },

  unusedContainers: new Map() as Map<string, number>,

  markAsUnused(id: string) {
    this.unusedContainers.set(
      id,
      window.setTimeout(() => this.unmountContainer(id), STALE_TIMEOUT),
    )
  },

  clearUnusedTO(id: string) {
    if (!this.unusedContainers.has(id)) {
      return
    }

    window.clearTimeout(this.unusedContainers.get(id))
    this.unusedContainers.delete(id)
  },

  unmountContainer(id: string) {
    const container = this.getContainer(id)
    this.performCleanups(container)
    this.containers.delete(id)
    this.clearUnusedTO(id)
  },

  alreadyRendered: new WeakSet() as WeakSet<HTMLElement>,
  cleanups: new WeakMap() as WeakMap<HTMLElement, ReadonlyArray<() => unknown>>,

  addCleanup(element: HTMLElement, cleanup: () => unknown) {
    const currentCleanups = this.cleanups.get(element) ?? []
    this.cleanups.set(element, currentCleanups.concat(cleanup))
  },

  performCleanups(element: HTMLElement | null | undefined) {
    if (element == null) {
      return
    }

    if (
      element.hasAttribute('data-react-root') ||
      element.hasAttribute('data-react-refreshable-root')
    ) {
      this.unmountComponentAtNode(element)
    }

    const currentCleanups = this.cleanups.get(element) ?? []
    currentCleanups.forEach(cleanup => cleanup())
    removeSandbox(element)
    this.cleanups.delete(element)
    this.alreadyRendered.delete(element)
    this.elementsToRenderWhenVisible.delete(element)
  },

  unmountWhenDetached(element: HTMLElement) {
    setTimeout(() => {
      this.getSandbox(element).mutate(() => {
        if (document.body?.contains(element)) {
          this.unmountWhenDetached(element)
        } else {
          this.performCleanups(element)
        }
      })
    }, STALE_TIMEOUT)
  },

  render(
    reactElement: React.ReactElement,
    elementOrIdOrNull: (HTMLElement | null | undefined) | string,
    type: React.ComponentType<any> | string = reactElement.type,
    sync = false,
  ) {
    if (elementOrIdOrNull == null) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(`Tried to render ${getDisplayName(type)} into a non-existent DOM element`)
      }

      return
    }

    // Flow needs a temp var to ensure immutability
    const elementOrId = elementOrIdOrNull

    if (typeof elementOrId === 'string') {
      this.clearUnusedTO(elementOrId)
    }

    const container: HTMLElement =
      typeof elementOrId === 'string' ? this.getContainer(elementOrId) : elementOrId
    const render = () => {
      if (typeof elementOrId === 'string') {
        container.id = elementOrId
        container.setAttribute('data-react-refreshable-root', 'true')
      } else if (elementOrId != null) {
        elementOrId.setAttribute('data-react-root', 'true')
      }

      const alreadyRendered = this.alreadyRendered.has(container)
      const observableParent = alreadyRendered
        ? null
        : container.closest('[data-observe-visibility]')

      if (observableParent instanceof HTMLElement) {
        this.renderWhenVisible(reactElement, container, observableParent)
      } else {
        createRootAndRender(reactElement, container)
        this.alreadyRendered.add(container)
        this.unmountWhenDetached(container)
      }

      if (typeof elementOrId === 'string') {
        const placeholder = document.getElementById(elementOrId)

        if (placeholder?.parentNode) {
          placeholder.parentNode.replaceChild(container, placeholder)
        } else {
          this.unmountContainer(elementOrId)
        }
      }
    }

    if (sync) {
      render()
    } else {
      getSandbox(container).mutate(render)
    }
  },

  elementsToRenderWhenVisible: new WeakMap() as WeakMap<HTMLElement, React.ReactElement>,

  renderWhenVisible(
    reactElement: React.ReactElement,
    container: HTMLElement,
    observableParent: HTMLElement,
  ) {
    const alreadyWaiting = this.elementsToRenderWhenVisible.has(container)
    this.elementsToRenderWhenVisible.set(container, reactElement)

    if (!alreadyWaiting) {
      const visibilityHandler = (isVisible: boolean) => {
        if (isVisible) {
          getSandbox(container).mutate(() => {
            const currentElement = this.elementsToRenderWhenVisible.get(container)

            if (currentElement != null) {
              createRootAndRender(currentElement, container)
            }
          })
          this.alreadyRendered.add(container)
          this.unmountWhenDetached(container)
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          stopListening()
        }
      }

      const mouseHandler = () => visibilityHandler(true)

      container.addEventListener('mouseenter', mouseHandler)
      const unsubscribe = observeVisibilityMulticast(observableParent, visibilityHandler)

      const stopListening = () => {
        unsubscribe()
        container.removeEventListener('mouseenter', mouseHandler)
      }

      this.addCleanup(container, stopListening)
    }
  },

  renderToStaticMarkup,

  createAndRender<P>(
    elementOrId: HTMLElement | string,
    Type: React.ComponentType<P>,
    props: P & React.JSX.IntrinsicAttributes,
  ) {
    this.render(<Type {...props} />, elementOrId)
  },

  createAndRenderStatic<P>(
    Type: React.ComponentType<P>,
    props: P & React.JSX.IntrinsicAttributes,
  ): string {
    return this.renderToStaticMarkup(<Type {...props} />)
  },

  wrapWithProviders(router: Router, suspenseFallback: ReactNode = '') {
    return (
      <Provider store={this.store}>
        <ControlsHeightContext.Provider value={ControlsHeight.S}>
          <RelayEnvironmentProvider environment={getEnvironment()}>
            <Suspense fallback={suspenseFallback}>
              <ErrorBoundary>
                <RouterProvider router={router} />
              </ErrorBoundary>
            </Suspense>
          </RelayEnvironmentProvider>
        </ControlsHeightContext.Provider>
      </Provider>
    )
  },

  createConnected<P>(
    Type: React.ComponentType<P>,
    props: P & React.JSX.IntrinsicAttributes,
    withClassicUIQuery = true,
    suspenseFallback?: ReactNode,
  ) {
    const query = withClassicUIQuery
      ? loadQuery<ClassicUIQueryLoaderQuery>(getEnvironment(), classicUIQueryLoaderQuery, {})
      : null
    const router = createBrowserRouter([
      {
        path: '*',
        ErrorBoundary,
        element:
          query != null ? (
            <ClassicUIQueryLoader query={query}>
              <Type {...props} />
            </ClassicUIQueryLoader>
          ) : (
            <Type {...props} />
          ),
      },
    ])
    return this.wrapWithProviders(router, suspenseFallback)
  },

  renderConnected<P>(
    elementOrId: HTMLElement | string,
    Type: React.ComponentType<P>,
    props: P & React.JSX.IntrinsicAttributes,
    withClassicUIQuery = true,
    suspenseFallback?: ReactNode,
  ) {
    this.render(
      this.createConnected(Type, props, withClassicUIQuery, suspenseFallback),
      elementOrId,
      Type,
    )
  },

  getAgents,
  getAgentsReady,
  Anchor,
  AlertService,
  PopupDirections,
  Message,
  ServiceMessage,
  HelpDropdown, // TODO remove
  HintPopup,
  AllBuilds,
  BuildBranch: BranchLabel,
  BuildNumberAPI,
  BuildTag,
  ContentPanelAPI,
  EntityPathAPI,
  ProblemsSummary,
  ProjectOrBuildTypeIcon,
  MiddleEllipsis,
  BuildArtifactsTree,
  Select,
  ListItemType,
  SkipNav,
  ProjectBuildTypeSelect,
  RouterLinkAPI,
  RouterButtonAPI,
  restApi,
  Version,

  onChange<T>(selector: (arg0: State) => T, changeHandler: (arg0: T) => unknown): () => unknown {
    let prevValue: T | null | undefined

    const handler = () => {
      const value = selector(this.store.getState())

      if (value !== prevValue) {
        changeHandler(value)
      }

      prevValue = value
    }

    handler()
    return this.store.subscribe(handler)
  },

  setUserProperty(name: UserProperty, value: string) {
    const updater = getSetUserPropertyUpdater(name, value)

    commitMutation<useSetUserPropertyMutation>(getEnvironment(), {
      mutation: useSetUserPropertyMutationDefinition,
      variables: {name, value},
      optimisticUpdater: updater,
      updater,
    })
  },

  receiveBuild(data: Build) {
    this.store.dispatch(receiveBuild(data))
  },

  receiveBuildAsJSON(buildId: BuildId, encodedData: string) {
    const cacheKey = `build-data-${stringifyId(buildId)}`

    if (dataCache[cacheKey] === encodedData) {
      return
    }

    dataCache[cacheKey] = encodedData
    clearTimeout(dataCacheTimeouts[cacheKey])
    dataCacheTimeouts[cacheKey] = window.setTimeout(() => {
      delete dataCache[cacheKey]
      delete dataCacheTimeouts[cacheKey]
    }, STALE_TIMEOUT)
    const data: Build = JSON.parse(encodedData)
    this.store.dispatch(multiplyReceiveBuild(data))
  },

  receiveAgentsInCloud(data: ReadonlyArray<AgentId>) {
    if (data) {
      this.store.dispatch(receiveAgentsInCloud(data))
    }
  },

  receiveBuildWSData(data: NormalizedBuild) {
    this.store.dispatch(receiveBuildWSData(data))
  },

  setIsSakuraUI() {
    this.isSakuraUI = true
    this.store.dispatch(setIsSakuraUI())
  },

  /* Depricated */
  setIsExperimentalUI() {
    this.setIsSakuraUI()
  },

  // TODO remove
  isSakuraHeaderUsed(): boolean {
    return true
  },

  // TODO remove when vertical navigation is fully released in cloud
  isVerticalNavigationUsed: true,

  // TODO remove
  setSakuraHeaderUsed(): boolean {
    return true
  },

  setAdminSpaceAvailable,
  setActivePageId,

  storeUrlExtensions(urls: ReadonlyArray<UrlExtension<any>>) {
    this.store.dispatch(storeUrlExtensions(urls))
  },

  setRouteAvailabilityResponse(response: RouteAvailabilityResponse) {
    this.store.dispatch(setRouteAvailabilityResponse(response, location.pathname))
  },

  dummyAction() {
    this.store.dispatch(dummyAction())
  },

  renderBuildActions(
    elementOrId: HTMLElement | string,
    buildId: BuildId,
    props: BuildActionsDropdownProps,
  ) {
    this.renderConnected(elementOrId, EntityProvider, {
      buildId,
      children: <BuildActionsDropdown compact {...props} />,
    })
  },

  renderSakuraReleaseBanner(elementOrId: HTMLElement | string, props: SakuraReleaseBannerProps) {
    this.renderConnected(elementOrId, SakuraRelease, props, true)
  },

  sakuraReleaseBannerHref: '',
  setSakuraReleaseBannerHref(href: string) {
    this.sakuraReleaseBannerHref = href
  },

  renderPipelinesBetaBanner(
    elementOrId: HTMLElement | string,
    props: React.ComponentProps<typeof PipelinesBetaBanner>,
  ) {
    this.renderConnected(elementOrId, PipelinesBetaBanner, props, false)
  },

  renderPipelinesPromoBanner(elementOrId: HTMLElement | string) {
    this.renderConnected(elementOrId, PipelinesPromoBanner, {}, true)
  },

  renderBuildArtifacts(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof BuildArtifacts,
      React.ComponentProps<typeof BuildArtifacts>
    >,
  ) {
    this.renderConnected(elementOrId, BuildArtifacts, props)
  },

  renderBuildNumber(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof BuildNumberContainer,
      React.ComponentProps<typeof BuildNumberContainer>
    >,
  ) {
    this.renderConnected(elementOrId, BuildNumberContainer, props)
  },

  renderStop(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof StopBuild,
      React.ComponentProps<typeof StopBuild>
    >,
  ) {
    this.renderConnected(elementOrId, StopBuild, props)
  },

  renderDuration(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof BuildDuration,
      React.ComponentProps<typeof BuildDuration>
    >,
  ) {
    this.renderConnected(elementOrId, BuildDuration, props)
  },

  renderBuilds(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof BuildsContainer,
      React.ComponentProps<typeof BuildsContainer>
    >,
    fetcherProps: React.JSX.LibraryManagedAttributes<
      typeof BuildsFetcher,
      React.ComponentProps<typeof BuildsFetcher>
    >,
    layoutProps: React.JSX.LibraryManagedAttributes<
      typeof BuildLineLayout,
      React.ComponentProps<typeof BuildLineLayout>
    >,
  ) {
    this.renderConnected(elementOrId, BuildsFetcher, {
      withBuildDetails: true,
      ...fetcherProps,
      children: (
        <BuildLineLayout {...layoutProps}>
          <BuildsContainer {...props} />
        </BuildLineLayout>
      ),
    })
  },

  renderAgentHistory(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof AgentHistory,
      React.ComponentProps<typeof AgentHistory>
    >,
  ) {
    this.store.dispatch(fetchProjectsWithArchivedData())
    this.renderConnected(elementOrId, AgentHistory, props)
  },

  renderBuildTypeHistory(elementOrId: HTMLElement | string, props: BuildTypeHistoryProps) {
    this.renderEntryPoint(elementOrId, BuildTypeHistoryEntryPoint, props, props)
  },

  renderShortBuildTypeHistory(elementOrId: HTMLElement | string, props: BuildTypeOverviewProps) {
    this.renderEntryPoint(elementOrId, BuildTypeOverviewEntryPoint, props, {
      buildTypeId: props.buildTypeId,
      branch: stringifyBranch(props.branch),
    })
  },

  renderFavoriteBuilds(elementOrId: HTMLElement | string) {
    this.store.dispatch(fetchProjectsWithArchivedData())
    this.renderConnected(elementOrId, FavoriteBuilds, {})
  },

  renderEntryPoint<Q extends Record<string, OperationType>, P extends {}, EP extends {}>(
    elementOrId: HTMLElement | string,
    entryPoint: EntryPoint<EntryPointComponent<Q, {}, P>, EP>,
    props: P,
    entryPointParams: EP,
    withClassicUIQuery = true,
    suspenseFallback?: ReactNode,
  ) {
    const router = createBrowserRouter([
      {
        path: '*',
        ErrorBoundary,
        loader: () => ({
          classicUIQuery: withClassicUIQuery
            ? loadQuery<ClassicUIQueryLoaderQuery>(getEnvironment(), classicUIQueryLoaderQuery, {})
            : null,
          entryPointReference: loadEntryPoint({getEnvironment}, entryPoint, entryPointParams),
        }),
        Component() {
          const {classicUIQuery, entryPointReference} = useLoaderData() as {
            classicUIQuery: PreloadedQuery<ClassicUIQueryLoaderQuery>
            entryPointReference: PreloadedEntryPoint<EntryPointComponent<Q, {}, P>>
          }
          const element = (
            <EntryPointContainer entryPointReference={entryPointReference} props={props} />
          )
          return withClassicUIQuery ? (
            <ClassicUIQueryLoader query={classicUIQuery}>{element}</ClassicUIQueryLoader>
          ) : (
            element
          )
        },
      },
    ])
    this.render(this.wrapWithProviders(router, suspenseFallback), elementOrId)
  },

  renderOverview(elementOrId: HTMLElement | string, urls: ReadonlyArray<UrlExtension<any>>) {
    this.storeUrlExtensions(urls)
    const bypassSubscription = window.internalProps['teamcity.ui.sidebar.skipWSEventsSubscription']
    const handler = (forceRefetch = true) => {
      const refetchables: Refetchable[] = []
      refetchables.push(
        this.store.dispatch(
          restApi.endpoints.getAllProjectsNormalized.initiate(overviewArg, {forceRefetch}),
        ),
      )
      const state = this.store.getState()
      const {showArchivedProjects} = state.projectsSidebar
      if (showArchivedProjects) {
        refetchables.push(this.store.dispatch(fetchArchivedProjects()))
      }
      return refetchables
    }

    if (bypassSubscription) {
      handler(false)
    } else {
      createRefetchableSubscriptions(handler, callback =>
        subscribeOnOverallEvents(
          getTopics('', [
            PROJECT_REMOVED,
            PROJECT_CREATED,
            PROJECT_ARCHIVED,
            PROJECT_DEARCHIVED, // TODO reenable UPDATED events after making sure that only one request is scheduled in case of multiple open tabs
            // PROJECT_UPDATED,
            // BUILD_TYPE_REGISTERED,
            // BUILD_TYPE_UNREGISTERED,
            // BUILD_TYPE_UPDATED,
          ]),
          callback,
          DEFAULT_DEBOUNCE_INTERVAL,
          PostponeMethod.DEBOUNCE,
        ),
      )
    }

    const element =
      typeof elementOrId === 'string' ? document.getElementById(elementOrId) : elementOrId
    if (element == null) {
      throw new Error(`Can't find element with id '${elementOrId}'`)
    } else {
      this.render(this.wrapWithProviders(createAppRouter()), elementOrId, 'App', true)
    }
  },

  renderOpenInSakuraUI(elementOrId: HTMLElement | string, props: OpenInSakuraUIProps) {
    this.renderEntryPoint(elementOrId, OpenInSakuraUIEntryPoint, props, {})
  },

  renderAdminSelectBuildRunner(elementOrId: HTMLElement | string, props: SelectBuildRunnerProps) {
    this.renderConnected(elementOrId, AdminSelectBuildRunner, props)
  },

  renderScheduleBuild(elementOrId: HTMLElement | string) {
    this.renderConnected(elementOrId, ScheduleBuild, {})
  },

  renderProjectsPopup(elementOrId: HTMLElement | string, props: ProjectsPopupProps = {}) {
    const bypassSubscription = window.internalProps['teamcity.ui.sidebar.skipWSEventsSubscription']
    const handler = () => {
      const refetchables: Refetchable[] = []
      refetchables.push(
        this.store.dispatch(restApi.endpoints.getAllProjectsNormalized.initiate(overviewArg)),
      )
      if (props.parentProjectId != null) {
        refetchables.push(
          this.store.dispatch(
            restApi.endpoints.getAllProjectsNormalized.initiate(
              getProjectsArg(props.parentProjectId, {
                withBuildTypes: true,
                withDescription: true,
                withAncestorProjects: true,
                includeRoot: true,
                withTemplates: props.editMode,
                projectReceiveMeta: {
                  sidebarProjectsLoaded: true,
                },
              }),
              {forceRefetch: true},
            ),
          ),
        )
      } else {
        refetchables.push(
          this.store.dispatch(
            restApi.endpoints.getAllProjectsNormalized.initiate(sidebarArg, {forceRefetch: true}),
          ),
        )
      }
      return combineRefetchables(refetchables)
    }

    if (bypassSubscription) {
      handler()
    } else {
      createRefetchableSubscription(handler, callback =>
        subscribeOnOverallEvents(
          getTopics('', [
            PROJECT_REMOVED,
            PROJECT_CREATED,
            PROJECT_ARCHIVED,
            PROJECT_DEARCHIVED, // TODO reenable UPDATED events after making sure that only one request is scheduled in case of multiple open tabs
            // PROJECT_UPDATED,
            // BUILD_TYPE_REGISTERED,
            // BUILD_TYPE_UNREGISTERED,
            // BUILD_TYPE_UPDATED,
          ]),
          callback,
          DEFAULT_DEBOUNCE_INTERVAL,
          PostponeMethod.DEBOUNCE,
        ),
      )
    }

    this.renderEntryPoint(
      elementOrId,
      ProjectsPopupEntryPoint,
      props,
      props.parentProjectId != null,
    )
  },

  renderAllBuilds(elementOrId: HTMLElement | string, props: AllBuildsProps) {
    this.store.dispatch(fetchProjectsWithArchivedData())
    this.store.dispatch(
      submitPager({
        pageSize: 50,
        lookupLimit: 5000,
        lookupDelta: 5000,
      }),
    )
    this.renderConnected(elementOrId, AllBuilds, props)
  },

  renderLicensesPage(elementOrId: HTMLElement | string) {
    this.store.dispatch(restApi.endpoints.getLicensingData.initiate({fields: 'licenseKeys'}))
    this.renderConnected(elementOrId, LicensesPage, {})
  },

  renderTwoFactorAuthSettingsPage(
    elementOrId: HTMLElement | string,
    props: React.ComponentProps<typeof TwoFactorAuthSettingsPage>,
  ) {
    this.renderConnected(elementOrId, TwoFactorAuthSettingsPage, props)
  },

  renderTwoFactorAuthLoginPage(
    elementOrId: HTMLElement | string,
    props: React.ComponentProps<typeof TwoFactorAuthLoginPage>,
  ) {
    this.renderConnected(elementOrId, TwoFactorAuthLoginPage, props, false)
  },

  renderHttpsConfiguration(
    elementOrId: HTMLElement | string,
    props: React.ComponentProps<typeof HttpsConfiguration>,
  ) {
    this.renderConnected(elementOrId, HttpsConfiguration, props)
  },

  renderBuildsTvMode(elementOrId: HTMLElement | string) {
    this.renderConnected(elementOrId, BuildsTvMode, {})
  },

  renderAgents(elementOrId: HTMLElement | string, props: AgentsOwnProps) {
    this.renderConnected(elementOrId, AgentsContainer, props)
  },

  renderAgentsFetcher(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof AgentsFetcher,
      React.ComponentProps<typeof AgentsFetcher>
    >,
  ) {
    this.store.dispatch(restApi.endpoints.getPoolPermissions.initiate())
    this.renderConnected(elementOrId, AgentsFetcher, props)
  },

  renderAdminMetricsTab(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof MetricsTab,
      React.ComponentProps<typeof MetricsTab>
    >,
  ) {
    this.renderConnected(elementOrId, MetricsTab, props)
  },

  renderSorter(
    elementOrId: HTMLElement | string,
    props: {
      dimensions: ReadonlyArray<string>
    },
  ) {
    this.renderConnected(elementOrId, Sorter, props)
  },

  renderPager(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<typeof Pager, React.ComponentProps<typeof Pager>>,
  ) {
    this.createAndRender(elementOrId, Pager, props)
  },

  renderTagsFilters(elementOrId: HTMLElement | string, props: TagsFiltersProps) {
    this.renderEntryPoint(elementOrId, TagsFiltersEntryPoint, props, props.buildTypeId)
  },

  renderAnimatingIcon(
    elementOrId: HTMLElement | string,
    wrapperId: string,
    props: React.JSX.LibraryManagedAttributes<typeof Icon, React.ComponentProps<typeof Icon>>,
  ) {
    this.render(
      <Visible TagName="span" id={wrapperId}>
        {() => <Icon {...props} />}
      </Visible>,
      elementOrId,
    )
  },

  renderApprovalWidget(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof BuildApproval,
      React.ComponentProps<typeof BuildApproval>
    >,
  ) {
    this.renderConnected(elementOrId, BuildApproval, props)
  },

  renderHealthItems(elementOrId: HTMLElement | string, props: HeaderHealthItemsProps) {
    this.renderConnected(elementOrId, HeaderHealthItems, props)
  },

  renderMarkdown(elementOrId: HTMLElement | string, props: MarkdownProps) {
    this.renderConnected(elementOrId, Markdown, props)
  },

  renderShowDslButton(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<typeof ShowDsl, React.ComponentProps<typeof ShowDsl>>,
  ) {
    this.store.dispatch(restApi.endpoints.getDslOptions.initiate())
    this.renderConnected(elementOrId, ShowDsl, props)
  },

  renderBuildTypeDslEditor(elementOrId: HTMLElement | string, props: BuildTypeDslEditorProps) {
    this.renderConnected(elementOrId, BuildTypeDslEditor, props)
  },

  renderFragmentDslEditor(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof FragmentDslEditor,
      React.ComponentProps<typeof FragmentDslEditor>
    >,
  ) {
    this.renderConnected(elementOrId, FragmentDslEditor, props)
  },

  renderCleanup(elementOrId: HTMLElement | string, props: CleanupProps) {
    this.renderEntryPoint(elementOrId, RulesEntryPoint, props, props.projectId)
  },

  renderProjectCleanup(elementOrId: HTMLElement | string, props: CleanupPoliciesProps) {
    this.store.dispatch(
      restApi.endpoints.getAllProjectsNormalized.initiate(
        getProjectsArg(props.projectId, {
          withBuildTypes: true,
          includeRoot: true,
        }),
      ),
    )
    this.renderEntryPoint(elementOrId, CleanupProjectPageEntryPoint, props, props.projectId)
  },

  renderMatrixParamBuild(elementOrId: HTMLElement | string, props: MatrixParamBuildProps) {
    this.renderEntryPoint(elementOrId, MatrixParamBuildEntryPoint, props, props, true, <Loader />)
  },

  renderHintsBoard(elementOrId: HTMLElement | string) {
    this.renderConnected(elementOrId, Hints, {})
  },

  registerHint(hint: Hint) {
    this.store.dispatch(addHint(hint))
  },

  unregisterHint(id: HintId) {
    this.store.dispatch(removeHint(id))
  },

  renderHeader(elementOrId: HTMLElement | string, props: HeaderProps) {
    this.renderConnected(elementOrId, HeaderLazy, props, true)
  },

  redirectToExperimentalUI: redirectToSakuraUI, // Depricated
  redirectToSakuraUI,
  redirectToClassicUI() {
    redirectToClassicUI(this.store.getState())
  },

  showBuildInvestigationHistoryPopup(
    element: HTMLElement,
    buildId: BuildId,
    buildTypeId: BuildTypeId,
    projectInternalId: ProjectInternalId,
  ) {
    this.store.dispatch(openDialog(stringifyId(buildId), DialogType.INVESTIGATION_HISTORY))
    if (!this.isSakuraUI) {
      this.renderConnected(element, BuildTypeInvestigationHistoryPopupDummy, {
        id: buildId,
        buildTypeId,
        projectInternalId,
      })
    }
  },

  showBuildTypeInvestigationHistoryPopup(
    element: HTMLElement,
    buildTypeId: BuildTypeId,
    projectInternalId: ProjectInternalId,
  ) {
    this.store.dispatch(openDialog(stringifyId(buildTypeId), DialogType.INVESTIGATION_HISTORY))
    if (!this.isSakuraUI) {
      this.renderConnected(element, BuildTypeInvestigationHistoryPopupDummy, {
        id: buildTypeId,
        buildTypeId,
        projectInternalId,
      })
    }
  },

  showBuildStatusWidgetPopup(
    element: HTMLElement,
    buildTypeId: BuildTypeId,
    buildTypeInternalId?: BuildTypeInternalId,
    initiallySelectedBranch?: Branch,
  ) {
    this.store.dispatch(openDialog(stringifyId(buildTypeId), DialogType.BUILD_STATUS_WIDGET))
    this.renderEntryPoint(
      element,
      BuildStatusWidgetEntryPoint,
      {
        buildTypeId,
        buildTypeInternalId,
        initiallySelectedBranch,
        overrideCloseDialog: () => this.performCleanups(element),
      },
      buildTypeId,
    )
  },

  showTestInvestigationHistoryPopup(
    element: HTMLElement,
    projectId: ProjectId,
    testId: TestId,
    testName: string,
    projectInternalId: ProjectInternalId,
  ) {
    this.store.dispatch(openDialog(stringifyId(testId), DialogType.INVESTIGATION_HISTORY))
    if (!this.isSakuraUI) {
      this.renderConnected(element, TestInvestigationHistoryPopupDummy, {
        projectId,
        testId,
        testName,
        projectInternalId,
      })
    }
  },

  showBuildProblemInvestigationHistoryPopup(
    element: HTMLElement,
    projectId: ProjectId,
    buildTypeId: BuildTypeId,
    buildId: BuildId,
    problemOccurrenceId: ProblemOccurrenceId,
    problemId: ProblemId,
    problemName: string,
    projectInternalId: ProjectInternalId,
  ) {
    this.store.dispatch(
      openDialog(stringifyId(problemOccurrenceId), DialogType.INVESTIGATION_HISTORY),
    )
    if (!this.isSakuraUI) {
      this.renderConnected(element, BuildProblemInvestigationHistoryPopupDummy, {
        projectId,
        buildTypeId,
        buildId,
        problemOccurrenceId,
        problemId,
        problemName,
        projectInternalId,
      })
    }
  },

  closeEditDsl(controlId: string) {
    this.store.dispatch(hideDsl(controlId))
  },

  storeDslFragment(content: string) {
    this.store.dispatch(storeDslFragment(content))
  },

  onShow(element: HTMLElement, callback: () => unknown) {
    const unsubscribe = observeVisibilityMulticast(element, isVisible => {
      if (isVisible) {
        callback()
        unsubscribe()
      }
    })
  },

  extendServerInfo(serverInfo: ServerInfo) {
    this.store.dispatch(receiveServerInfo(serverInfo))
  },

  insertPlugin(placeId: PlaceId, name: string) {
    this.store.dispatch(addPlugin(placeId, name))
  },

  renderPluginPlace(
    elementOrId: HTMLElement | string,
    props: React.JSX.LibraryManagedAttributes<
      typeof PluginPlace,
      React.ComponentProps<typeof PluginPlace>
    >,
  ) {
    this.renderConnected(elementOrId, PluginPlace, props)
  },

  renderThemeSwitcher(elementOrId: HTMLElement | string) {
    this.renderConnected(elementOrId, AppearanceDropdown, {})
  },

  addMarkdownAlert(
    source = '',
    type?: AlertType,
    timeout?: number,
    options?: Partial<AlertItem>,
  ): AlertKey {
    const md = <Markdown>{source}</Markdown>
    return String(AlertService.addAlert(md, type, timeout, options))
  },

  removePlugin(placeId: PlaceId, name: string) {
    this.store.dispatch(removePlugin(placeId, name))
  },

  requestSWUpdate,

  renderAvatarEditor(elementOrId: HTMLElement | string, props: AvatarEditorProps) {
    const query = loadQuery<AvatarEditorQuery>(getEnvironment(), avatarEditorQuery, {
      userLocator: `id:${props.userId}`,
    })
    this.renderConnected(elementOrId, AvatarEditor, {...props, query}, true)
  },

  renderEditFederation(elementOrId: HTMLElement | string) {
    this.renderEntryPoint(
      elementOrId,
      EditFederationEntryPoint,
      {},
      {},
      true,
      <LoaderInline>{'Loading servers...'}</LoaderInline>,
    )
  },

  renderBranchSelect(
    elementOrId: HTMLElement | string,
    props: BranchSelectProps & FetchBranchesParams,
  ) {
    this.renderEntryPoint(elementOrId, BranchSelectEntryPoint, props, {
      ...props,
      node: props.projectOrBuildTypeNode,
    })
  },

  stopObservingVisibility: stopObserving,
  showHide,
  getSandbox,
  queryToObject,
  history: browserHistory,
  AgentAuth, // Used in cloud
  Avatar,

  async checkDefaultExcluded(id: BuildTypeId): Promise<boolean> {
    const result = await fetchQuery<branchExistsInBuildTypeQuery>(
      getEnvironment(),
      branchExistsInBuildTypeQueryDefinition,
      getIsBranchPresentInBuildTypeVariables(
        id,
        getBranchesLocator({
          node: getBuildTypeFilter(id),
          branch: defaultBranch,
          inPath: true,
        }),
      ),
    ).toPromise()
    return !result?.branchExistsInBuildType
  },
  serviceWorkers: {
    setup: () => {
      setupServiceWorker()
      setupServiceWorkerChecker()
    },
    disable: unregisterServiceWorker,
    enable: registerServiceWorker,
    cleanServiceWorkerCaches,
  },
  async checkHasBranches(id: BuildTypeId): Promise<boolean> {
    const result = await fetchQuery<branchExistsInBuildTypeQuery>(
      getEnvironment(),
      branchExistsInBuildTypeQueryDefinition,
      getIsBranchPresentInBuildTypeVariables(
        id,
        getBranchesLocator({
          node: getBuildTypeFilter(id),
          inPath: true,
        }),
      ),
    ).toPromise()
    return result?.branchExistsInBuildType === true
  },
  getColorRgbByString: (key: string | null | undefined): ColorValueArray =>
    getColorRgbByString(key),
  darkThemeAdapter,
  setGlobalTheme(theme: Theme | '') {
    const nextTheme = theme || Theme.AUTO
    this.store.dispatch(initialTheme.actions.set(nextTheme))
    if (isPipelinesEnabledInExclusiveMode || isGlobalDarkModeAllowed) {
      setGlobalTheme(nextTheme)
    }
  },
  setNodeInfo(info: NodeInfo) {
    this.store.dispatch(nodeInfo.actions.set(info))
  },
}

declare global {
  interface Window {
    ReactUI: typeof ReactUI
  }
}
window.ReactUI = ReactUI

export default ReactUI
