/**
 * @generated SignedSource<<9f513808ecbecdeb71fbdbb60f947077>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AgentPoolPagePoolQuery$variables = {
  agentPoolId: string;
  isDefault?: boolean | null | undefined;
};
export type AgentPoolPagePoolQuery$data = {
  readonly agentPool: {
    readonly __typename: string;
    readonly id: string;
    readonly maxAgentsNumber: number | null | undefined;
    readonly name: string | null | undefined;
    readonly permissions: {
      readonly manage: boolean | null | undefined;
      readonly manageAgents: boolean | null | undefined;
      readonly manageProjects: boolean | null | undefined;
    } | null | undefined;
    readonly projects: {
      readonly count: number;
    } | null | undefined;
    readonly rawId: string;
  } | null | undefined;
  readonly defaultPool?: {
    readonly id: string;
    readonly permissions: {
      readonly manageAgents: boolean | null | undefined;
      readonly manageProjects: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AgentPoolPagePoolQuery = {
  response: AgentPoolPagePoolQuery$data;
  variables: AgentPoolPagePoolQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentPoolId"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "isDefault"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manageAgents",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manageProjects",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "agentPoolId"
    }
  ],
  "concreteType": null,
  "kind": "LinkedField",
  "name": "agentPool",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxAgentsNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AgentPoolProjectsConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AgentPoolPermissions",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manage",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "id",
    "value": "0"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AgentPoolPermissions",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPoolPagePoolQuery",
    "selections": [
      (v5/*: any*/),
      {
        "condition": "isDefault",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "defaultPool",
            "args": (v6/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": "agentPool(id:\"0\")"
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentPoolPagePoolQuery",
    "selections": [
      (v5/*: any*/),
      {
        "condition": "isDefault",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "defaultPool",
            "args": (v6/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "agentPool",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v1/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": "agentPool(id:\"0\")"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "3f01eb142041d79af73fc7110994ec67",
    "id": null,
    "metadata": {},
    "name": "AgentPoolPagePoolQuery",
    "operationKind": "query",
    "text": "query AgentPoolPagePoolQuery(\n  $agentPoolId: ID!\n  $isDefault: Boolean = false\n) {\n  agentPool(id: $agentPoolId) {\n    id\n    rawId\n    name\n    maxAgentsNumber\n    projects {\n      count\n    }\n    permissions {\n      manage\n      manageAgents\n      manageProjects\n    }\n    __typename\n  }\n  defaultPool: agentPool(id: \"0\") @skip(if: $isDefault) {\n    __typename\n    id\n    permissions {\n      manageAgents\n      manageProjects\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0be22b5222e6f6627a404228d80492f4";

export default node;
