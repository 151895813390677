import {graphql} from 'react-relay'

export const query = graphql`
  query AgentPipelinePageQuery($locator: String!) {
    ...AgentsPagesCanViewAgentDetailsFragment
    agentRest(agentLocator: $locator) {
      id
      name
      typeId
      ...AgentPipelinePage_agentRest
      properties(locator: "name:(value:(teamcity.agent.jbHosted),matchType:equals)") {
        property {
          name
          value
        }
      }
    }
  }
`
