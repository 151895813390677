import {produce} from 'immer'

import isEmpty from 'lodash/isEmpty'
import mapKeys from 'lodash/mapKeys'
import {createSelector} from 'reselect'

import type {State} from '../../../../reducers/types'
import {mapValues} from '../../../../utils/object'
import type {Job, SuggestionType} from '../types'
import {isStoreYamlInVcsEnabled} from '../utils/featureToggles'
import {isValueNotEmptyMessage, matchError} from '../utils/validators'
import type {GetValidators} from '../utils/validators.types'

import {getDependencyName, parsePipeline, stringifyPipeline} from './EditPipelinePage.utils'
import {IntegrationType} from './SettingsSidebarContent/Integrations/Integrations.consts'
import {jobNameValidators} from './SettingsSidebarContent/JobSettingsSidebar/JobName/JobNameValidationError.consts'
import {generatedFields} from './SettingsSidebarContent/JobSettingsSidebar/JobSteps/JobStep/JobStepHotFields/JobStepHotFields.descriptors'
import {errorValidators} from './SettingsSidebarContent/JobSettingsSidebar/JobSteps/JobStep/SmartDetectors/CliErrorMessage/useCliErrorMessage.consts'
import {pipelineNameValidators} from './SettingsSidebarContent/PipelineSettingsSidebar/PipelineName/PipelineNameValidationError.consts'
import {
  parameterNameValidators,
  parameterValueValidators,
} from './SettingsSidebarContent/PipelineSettingsSidebar/PipelineParameters/PipelineParameterForm/usePipelineParameterForm.const'
import {
  getRepositoryKey,
  ROOT_REPOSITORY_KEY,
} from './SettingsSidebarContent/PipelineSettingsSidebar/PipelineRepositories/PipelineRepository/PipelineRepository.utils'
import {
  secretNameValidators,
  secretValueValidators,
} from './SettingsSidebarContent/PipelineSettingsSidebar/PipelineSecrets/PipelineSecretForm/PipelineSecretForm.consts'
import type {PipelineError} from './slices/EditPipelinePage.slices'
import {MIN_PARALLELISM_COUNT} from './slices/EditPipelinePage.slices.consts'
import type {PipelineDraft, PipelineFormParams} from './slices/EditPipelinePage.slices.types'
import {FormType} from './slices/EditPipelinePage.slices.types'
import {deleteIfEmpty, getPipelineFormKey} from './slices/EditPipelinePage.slices.utils'

const getOriginal = (state: State, id: string) => state.pipelines.pipelineDraft[id]?.original

export const getPipelineOriginalIntegrations = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.integrations

export const getOriginalPipelineTriggers = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.triggers

export const getOriginalPipelineSettings = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.settings

export const getOriginalVersionedSettings = (id: string) => (state: State) =>
  state.pipelines.pipelineDraft[id]?.original?.versionedSettings

export const getDraft = (state: State, id: string) => state.pipelines.pipelineDraft[id]?.draft

export const getDraftJobs = (state: State, id: string) => getDraft(state, id)?.settings.jobs
export const getSteps = (state: State, id: string, jobId: string) =>
  getDraftJobs(state, id)?.[jobId]?.steps

export const makePipelineErrorsSelector = () =>
  createSelector(
    [
      (state: State) => state.pipelines.pipelineErrors,
      (_: State, {pipelineId}: PipelineError) => pipelineId,
      (_: State, {jobId}: PipelineError) => jobId,
    ],
    (errors, pipelineId, jobId) => errors.filter(matchError({pipelineId, jobId})),
  )

export const makeTotalCountPipelineErrorsSelector = () =>
  createSelector([makePipelineErrorsSelector()], errors => errors.length)

export const getPipelineErrorMessage = (filter: PipelineError) => (state: State) =>
  state.pipelines.pipelineErrors.find(matchError(filter))?.message

export const getJobName = (state: State, pipelineId: string, id: string) =>
  getDraftJobs(state, pipelineId)?.[id]?.name ?? id

export const getRunsOn = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['runs-on']

export const getOriginalRunsOn = (jobId: string, id: string) => (state: State) =>
  getOriginal(state, id)?.settings?.jobs?.[jobId]?.['runs-on']

export const getJobCheckoutWorkingDirectoriesOnly = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['checkout-working-directories-only']

export const getJobAllowReuse = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['allow-reuse'] !== false

export const isPipelineFormOpened = (state: State, {pipelineId, ...rest}: PipelineFormParams) =>
  state.pipelines.pipelineForm[pipelineId]?.[getPipelineFormKey(rest)]?.open
export const isPipelineFormAdd = (state: State, {pipelineId, ...rest}: PipelineFormParams) =>
  state.pipelines.pipelineForm[pipelineId]?.[getPipelineFormKey(rest)]?.isAdd

export const getSkippedSuggestions = (state: State, stepId: string) =>
  state.pipelines.suggestions.skippedSuggestions[stepId]

export const isSuggestionSkipped = (state: State, stepId: string, type: SuggestionType) =>
  getSkippedSuggestions(state, stepId)?.includes(type)

export const isSuccessMessageVisible = (state: State, stepId: string, type: SuggestionType) =>
  state.pipelines.suggestions.successMessages[stepId] === type

export const isDraftChanged = createSelector(
  (state?: PipelineDraft) => state?.deleted,
  (state?: PipelineDraft) => state?.renamed,
  (state?: PipelineDraft) => state?.reordered,
  (deleted, renamed, reordered) => Boolean(deleted || renamed || reordered),
)

export const isDraftSettingsChanged = createSelector(
  (state?: PipelineDraft) => {
    const {jobs, ...deleted} = state?.deleted ?? {}
    return isEmpty(deleted) ? null : deleted
  },
  (state?: PipelineDraft) => state?.renamed,
  (state?: PipelineDraft) => state?.added,
  (deleted, renamed, added) => Boolean(deleted || renamed || added),
)

const getResolvedSettingsFromDraft = createSelector(
  (state: PipelineDraft) => state?.draft,
  (state: PipelineDraft, params?: GetResolvedSettingsOptions) =>
    params?.excludeDeleted ? null : state?.deleted,
  (state: PipelineDraft) => state?.renamed,
  (state: PipelineDraft, params?: GetResolvedSettingsOptions) =>
    params?.excludeReordered ? null : state?.reordered,
  (draft, deleted, renamed, reordered) =>
    produce(draft, pipeline => {
      if (pipeline != null) {
        if (pipeline.integrations != null) {
          if (deleted?.integrations != null) {
            pipeline.integrations = pipeline.integrations.filter(
              item => !deleted.integrations?.includes(item.id!),
            )
          }
          pipeline.integrations.forEach(({type, parameters}) => {
            switch (type) {
              case IntegrationType.DOCKER:
                parameters.repositoryUrl = parameters.repositoryUrl?.trim() ?? ''
                parameters.userName = parameters.userName?.trim() ?? ''
                break
              case IntegrationType.NPM:
                parameters.npmRegistryHost = parameters.npmRegistryHost?.trim() ?? ''
                parameters.npmRegistryScope = parameters.npmRegistryScope?.trim() ?? ''
                break
              default:
            }
          })
        }
        if (
          pipeline.additionalVcsRoots != null &&
          (deleted?.additionalVcsRoots?.length || deleted?.incompleteAdditionalVcsRoots?.length)
        ) {
          pipeline.additionalVcsRoots = pipeline.additionalVcsRoots.filter(({url}, index) =>
            url
              ? !deleted?.additionalVcsRoots?.includes(url)
              : !deleted?.incompleteAdditionalVcsRoots?.includes(index),
          )
        }
        const {settings} = pipeline
        if (settings.jobs != null) {
          deleted?.jobs?.forEach(id => {
            delete settings.jobs![id]
          })
          Object.entries(settings.jobs).forEach(([jobId, job]) => {
            if (deleted?.steps?.[jobId] != null || reordered?.steps?.[jobId] != null) {
              job.steps = job.steps
                ?.map((_, i) => {
                  const originalIndex = reordered?.steps?.[jobId]?.[i] ?? i
                  return {
                    value: job.steps![originalIndex],
                    isDeleted: deleted?.steps?.[jobId]?.includes(originalIndex),
                  }
                })
                .filter(({isDeleted}) => !isDeleted)
                .map(({value}) => value)
            }
            if (
              job.parallelism != null &&
              (!job.parallelism || job.parallelism < MIN_PARALLELISM_COUNT)
            ) {
              delete job.parallelism
            }
            if (job.repositories?.length && deleted?.additionalVcsRoots?.length) {
              job.repositories = job.repositories?.filter(repository => {
                const [url] = Object.keys(repository)

                return !deleted?.additionalVcsRoots?.includes(url)
              })

              if (!job.repositories?.length) {
                deleteIfEmpty(job, 'repositories')
              }
            }
          })
        }
        if (settings.parameters != null) {
          deleted?.parameters?.forEach(name => {
            delete settings.parameters![name]
          })
          if (renamed?.parameters != null) {
            settings.parameters = mapKeys(
              settings.parameters,
              (_, name) => renamed.parameters![name] ?? name,
            )
          }

          deleteIfEmpty(settings, 'parameters')
        }
        if (settings.secrets != null) {
          deleted?.secrets?.forEach(name => {
            delete settings.secrets![name]
          })
          if (renamed?.secrets != null) {
            settings.secrets = mapKeys(
              settings.secrets,
              (_, name) => renamed.secrets![name] ?? name,
            )
          }

          deleteIfEmpty(settings, 'secrets')
        }
      }
    }),
)

export const getJobEnableDependencyCache = (jobId: string, id: string) => (state: State) =>
  getDraft(state, id)?.settings?.jobs?.[jobId]?.['enable-dependency-cache']

type GetResolvedSettingsOptions = {
  excludeDeleted?: boolean
  excludeReordered?: boolean
}

export const getResolvedSettings = (
  state: State,
  pipelineId: string,
  params?: GetResolvedSettingsOptions,
) => getResolvedSettingsFromDraft(state.pipelines.pipelineDraft[pipelineId] ?? {}, params)

const yamlSelector = createSelector(
  [
    (state: State, props: {pipelineId: string}) =>
      state.pipelines.pipelineYamlValidity[props.pipelineId]?.yaml,
    (state: State, props: {pipelineId: string}) =>
      state.pipelines.pipelineYamlValidity[props.pipelineId]?.isParsed,
    (state: State, props: {pipelineId: string}) =>
      getResolvedSettings(state, props.pipelineId)?.settings,
  ],
  (invalidYaml, isParsed, settings) =>
    (isStoreYamlInVcsEnabled && !isParsed
      ? invalidYaml
      : settings &&
        stringifyPipeline(settings, {
          noRefs: true,
        })) ?? '',
)

export const getYaml = (state: State, pipelineId: string) => yamlSelector(state, {pipelineId})

export function getNewYaml(state: State, pipelineId: string) {
  const yaml = getYaml(state, pipelineId)
  return state.pipelines.pipelineYamlDraft[pipelineId]?.yaml ?? yaml
}

export const getNewSettings = (state: State, pipelineId: string) =>
  parsePipeline(getNewYaml(state, pipelineId))

export function getStringifiedDependencies(jobs?: Record<string, Job>) {
  const result: Record<string, string[]> = {}
  if (jobs != null) {
    Object.entries(jobs).forEach(([id, job]) => {
      result[id] = job.dependencies?.map(getDependencyName) ?? []
    })
  }
  return JSON.stringify(result)
}

export function getAllFormsToValidate(state: State, pipelineId: string): PipelineFormParams[] {
  const {draft, deleted} = state.pipelines.pipelineDraft[pipelineId] ?? {}
  if (draft == null) {
    return []
  }
  const {settings, additionalVcsRoots = []} = draft
  const {jobs = {}, parameters = {}, secrets = {}} = settings
  const jobIds = Object.keys(jobs).filter(id => !deleted?.jobs?.includes(id))
  return [
    {pipelineId, type: FormType.NAME},
    ...Object.keys(parameters)
      .filter(name => !deleted?.parameters?.includes(name))
      .map(name => ({pipelineId, id: name, type: FormType.PARAMETER})),
    ...Object.keys(secrets)
      .filter(name => !deleted?.secrets?.includes(name))
      .map(name => ({pipelineId, id: name, type: FormType.SECRET})),
    {pipelineId, id: getRepositoryKey(true), type: FormType.REPOSITORY},
    ...additionalVcsRoots
      .filter(({url}, index) =>
        url
          ? !deleted?.additionalVcsRoots?.includes(url)
          : !deleted?.incompleteAdditionalVcsRoots?.includes(index),
      )
      .map((_, index) => ({
        pipelineId,
        id: getRepositoryKey(false, index),
        type: FormType.REPOSITORY,
      })),
    ...jobIds.flatMap(jobId => [
      {pipelineId, jobId, type: FormType.NAME},
      ...(jobs[jobId].steps
        ?.map((_, index) => index)
        .filter(index => !deleted?.steps?.[jobId]?.includes(index))
        .map(index => ({pipelineId, jobId, id: index, type: FormType.STEP})) ?? []),
    ]),
  ]
}

function getAllParameterNames(state: State, pipelineId: string): string[] {
  const {draft, deleted, renamed} = state.pipelines.pipelineDraft[pipelineId] ?? {}
  if (draft == null) {
    return []
  }
  const {parameters} = draft.settings
  return Object.keys(parameters ?? {})
    .filter(name => !deleted?.parameters?.includes(name))
    .map(name => renamed?.parameters?.[name] ?? name)
}

function getAllSecretNames(state: State, pipelineId: string): string[] {
  const {draft, deleted, renamed} = state.pipelines.pipelineDraft[pipelineId] ?? {}
  if (draft == null) {
    return []
  }
  const {secrets} = draft.settings
  return Object.keys(secrets ?? {})
    .filter(name => !deleted?.secrets?.includes(name))
    .map(name => renamed?.secrets?.[name] ?? name)
}

const getRepositoryByFormId = (
  state: State,
  pipelineId: string,
  id: string | number | undefined,
) => {
  const {vcsRoot, additionalVcsRoots} = state.pipelines.pipelineDraft[pipelineId]?.draft ?? {}
  return id === ROOT_REPOSITORY_KEY ? vcsRoot : additionalVcsRoots?.[Number(id)]
}

export const validatorSelectors: Partial<Record<FormType, Record<string, GetValidators>>> = {
  [FormType.NAME]: {
    name: (state, {pipelineId, jobId}) =>
      jobId != null
        ? {
            value: getJobName(state, pipelineId, jobId),
            compareArray: Object.keys(getDraftJobs(state, pipelineId) ?? {}).map(otherJobId =>
              getJobName(state, pipelineId, otherJobId),
            ),
            validators: jobNameValidators,
          }
        : {
            value: getDraft(state, pipelineId)?.settings.name,
            validators: pipelineNameValidators,
          },
  },
  [FormType.STEP]: mapValues<typeof errorValidators, Record<string, GetValidators>>(
    errorValidators,
    (validators, fieldName) =>
      (state, {pipelineId, jobId, id}) => {
        const step = getSteps(state, pipelineId, jobId!)?.[Number(id)]
        const definedFields = generatedFields[step?.type ?? '']
        return {
          value:
            step?.[fieldName] ??
            (definedFields?.some(field => fieldName === field.name) ? '' : undefined),
          validators,
        }
      },
  ),
  [FormType.PARAMETER]: {
    name: (state, {pipelineId, id}) => ({
      value: state.pipelines.pipelineDraft[pipelineId]?.renamed?.parameters?.[id!] ?? String(id),
      compareArray: getAllParameterNames(state, pipelineId),
      relatedArray: getAllSecretNames(state, pipelineId),
      validators: parameterNameValidators,
    }),
    value: (state, {pipelineId, id}) => ({
      value: state.pipelines.pipelineDraft[pipelineId]?.draft?.settings.parameters?.[id!] ?? '',
      validators: parameterValueValidators,
    }),
  },
  [FormType.SECRET]: {
    name: (state, {pipelineId, id}) => ({
      value: state.pipelines.pipelineDraft[pipelineId]?.renamed?.secrets?.[id!] ?? String(id),
      compareArray: getAllSecretNames(state, pipelineId),
      relatedArray: getAllParameterNames(state, pipelineId),
      validators: secretNameValidators,
    }),
    value: (state, {pipelineId, id}) => ({
      value: state.pipelines.pipelineDraft[pipelineId]?.draft?.settings.secrets?.[id!] ?? '',
      validators: secretValueValidators,
    }),
  },
  [FormType.REPOSITORY]: {
    url: (state, {pipelineId, id}) => ({
      value: getRepositoryByFormId(state, pipelineId, id)?.url,
      validators: [
        {
          validator: isValueNotEmptyMessage,
          message: 'Repository URL should not be empty',
        },
      ],
    }),
    branch: (state, {pipelineId, id}) => {
      const {url, branch} = getRepositoryByFormId(state, pipelineId, id) ?? {}
      return {
        disabled: !url,
        value: branch,
        validators: [
          {
            validator: isValueNotEmptyMessage,
            message: 'Branch name should not be empty',
          },
        ],
      }
    },
  },
}

export const getPipelineErrors = (state: State, filter: PipelineError) =>
  state.pipelines.pipelineErrors.filter(matchError(filter))

export const getIsPipelineValid = createSelector(
  [
    (state: State, props: {pipelineId: string}) =>
      !state.pipelines.pipelineYamlDraft[props.pipelineId]?.diagnostics?.length,
    (state: State, props: {pipelineId: string}) =>
      state.pipelines.pipelineYamlValidity[props.pipelineId]?.isParsed ?? true,
  ],
  (isValid, isParsed) => isValid && isParsed,
)

export const getIsStoreInRepoEnabled = createSelector(
  [
    (state: State, props: {pipelineId: string}) =>
      state.pipelines.pipelineDraft[props.pipelineId]?.draft?.versionedSettings?.storedInRepo,
    (state: State, props: {pipelineId: string}) =>
      state.pipelines.pipelineDraft[props.pipelineId]?.original?.versionedSettings?.storedInRepo,
  ],
  (storedInRepoDraft, storedInRepoOriginal) => !storedInRepoOriginal && storedInRepoDraft,
)
