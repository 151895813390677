/**
 * @generated SignedSource<<d31b30d012e16e2339442da7cdbdd5d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AgentPoolPageUpdateAgentPoolMutation$variables = {
  maxAgentsNumber?: number | null | undefined;
  name?: string | null | undefined;
  rawId: string;
};
export type AgentPoolPageUpdateAgentPoolMutation$data = {
  readonly updateAgentPool: {
    readonly agentPool: {
      readonly id: string;
      readonly maxAgentsNumber: number | null | undefined;
      readonly name: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AgentPoolPageUpdateAgentPoolMutation = {
  response: AgentPoolPageUpdateAgentPoolMutation$data;
  variables: AgentPoolPageUpdateAgentPoolMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxAgentsNumber"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rawId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "maxAgentsNumber",
            "variableName": "maxAgentsNumber"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "rawId",
            "variableName": "rawId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateAgentPoolPayload",
    "kind": "LinkedField",
    "name": "updateAgentPool",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPool",
        "kind": "LinkedField",
        "name": "agentPool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxAgentsNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPoolPageUpdateAgentPoolMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AgentPoolPageUpdateAgentPoolMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c3350622322ef7b1873f584b144d23e8",
    "id": null,
    "metadata": {},
    "name": "AgentPoolPageUpdateAgentPoolMutation",
    "operationKind": "mutation",
    "text": "mutation AgentPoolPageUpdateAgentPoolMutation(\n  $rawId: ID!\n  $name: String\n  $maxAgentsNumber: Int\n) {\n  updateAgentPool(input: {rawId: $rawId, name: $name, maxAgentsNumber: $maxAgentsNumber}) {\n    agentPool {\n      id\n      name\n      maxAgentsNumber\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "104fc8192e7fed88274bbc7a41ca79a3";

export default node;
