import type {AppThunk} from '../../../../actions/types'
import {getValidationErrorMessage} from '../utils/validators'

import {getAllFormsToValidate, validatorSelectors} from './EditPipelinePage.selectors'
import type {DeleteJobPayload} from './slices/EditPipelinePage.job.slice'
import {pipelineDraft, pipelineErrors} from './slices/EditPipelinePage.slices'
import type {PipelineFormParams} from './slices/EditPipelinePage.slices.types'

export const validateField =
  (formParams: PipelineFormParams, fieldName: string): AppThunk<boolean> =>
  (dispatch, getState) => {
    const {pipelineId, jobId, type, id} = formParams
    dispatch(
      pipelineErrors.actions.clear({pipelineId, jobId, formType: type, formId: id, fieldName}),
    )
    const state = getState()
    const validators = validatorSelectors[formParams.type]?.[fieldName]?.(state, formParams)
    const message = validators != null ? getValidationErrorMessage(validators) : ''
    if (message) {
      dispatch(
        pipelineErrors.actions.add({
          pipelineId,
          jobId,
          formType: type,
          formId: id,
          fieldName,
          message,
        }),
      )
      return false
    }

    return true
  }

export const validateForm =
  (formParams: PipelineFormParams): AppThunk<boolean> =>
  dispatch => {
    const results = Object.keys(validatorSelectors[formParams.type] ?? {}).map(fieldName =>
      dispatch(validateField(formParams, fieldName)),
    )
    return results.every(Boolean)
  }

export const validateAllForms =
  (
    pipelineId: string,
    filter: (form: PipelineFormParams) => boolean = () => true,
  ): AppThunk<boolean> =>
  (dispatch, getState) => {
    const results = getAllFormsToValidate(getState(), pipelineId)
      .filter(filter)
      .map(formParams => dispatch(validateForm(formParams)))
    return results.every(Boolean)
  }

export const deleteJob =
  (payload: DeleteJobPayload): AppThunk =>
  dispatch => {
    dispatch(pipelineDraft.actions.deleteJob(payload))
    const {id, jobId} = payload
    dispatch(pipelineErrors.actions.clear({pipelineId: id, jobId}))
  }
