import {graphql, useFragment} from 'react-relay'
import {useParams} from 'react-router-dom'

import type {QueryParam, SetQueryParam} from '../../../../hooks/routes'
import {useQueryParamState} from '../../../../hooks/routes'
import {hasPipelineHeadParameter} from '../EditPipelinePage/EditPipelinePage.utils'

import type {
  pipelineHeadFragment$data,
  pipelineHeadFragment$key,
} from './__generated__/pipelineHeadFragment.graphql'

export const usePipelineId = (): string => {
  const {pipelineId = ''} = useParams()
  return pipelineId
}
export const useJobIdState = (): [QueryParam, SetQueryParam] => useQueryParamState('job')

const PipelineHeadFragment = graphql`
  fragment pipelineHeadFragment on ProjectRest {
    buildTypes {
      buildType {
        id
        internalId
        parameters {
          property {
            name
          }
        }
      }
    }
  }
`

const getPipelineHead = (data: pipelineHeadFragment$data | null) =>
  data?.buildTypes?.buildType?.find(job => hasPipelineHeadParameter(job.parameters))
export const usePipelineHead = (key: pipelineHeadFragment$key | null) => {
  const data = useFragment(PipelineHeadFragment, key)
  return getPipelineHead(data)
}

export const usePipelineHeadId = (key: pipelineHeadFragment$key | null) => {
  const {id} = usePipelineHead(key) || {}
  return id
}
