/**
 * @generated SignedSource<<5279e8b6dbae5f2f5e5a34c7f68fa072>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AgentPoolPageAgentPoolProjectsQuery$variables = {
  agentPoolId: string;
};
export type AgentPoolPageAgentPoolProjectsQuery$data = {
  readonly agentPool: {
    readonly __typename: string;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly projects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly agentPools: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly __typename: string;
                readonly id: string;
                readonly name: string | null | undefined;
                readonly rawId: string;
              };
            }>;
          } | null | undefined;
          readonly ancestorProjects: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly archived: boolean | null | undefined;
                readonly id: string;
                readonly name: string | null | undefined;
                readonly rawId: string;
              };
            }>;
          } | null | undefined;
          readonly archived: boolean | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly permissions: {
            readonly manageAgentPoolsForProject: boolean | null | undefined;
          } | null | undefined;
          readonly rawId: string;
        };
      }>;
      readonly excludedCount: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AgentPoolPageAgentPoolProjectsQuery = {
  response: AgentPoolPageAgentPoolProjectsQuery$data;
  variables: AgentPoolPageAgentPoolProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentPoolId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "agentPoolId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "agentPool",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "archived": null
            }
          }
        ],
        "concreteType": "AgentPoolProjectsConnection",
        "kind": "LinkedField",
        "name": "projects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolProjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectPermissions",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "manageAgentPoolsForProject",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectAgentPoolsConnection",
                    "kind": "LinkedField",
                    "name": "agentPools",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectAgentPoolEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v4/*: any*/),
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectsConnection",
                    "kind": "LinkedField",
                    "name": "ancestorProjects",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Project",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v4/*: any*/),
                              (v3/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "excludedCount",
            "storageKey": null
          }
        ],
        "storageKey": "projects(filter:{\"archived\":null})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPoolPageAgentPoolProjectsQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentPoolPageAgentPoolProjectsQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "e611d5e5caea43af453111e7fe1ee92f",
    "id": null,
    "metadata": {},
    "name": "AgentPoolPageAgentPoolProjectsQuery",
    "operationKind": "query",
    "text": "query AgentPoolPageAgentPoolProjectsQuery(\n  $agentPoolId: ID!\n) {\n  agentPool(id: $agentPoolId) {\n    id\n    __typename\n    name\n    projects(filter: {archived: null}) {\n      edges {\n        node {\n          id\n          rawId\n          name\n          archived\n          permissions {\n            manageAgentPoolsForProject\n          }\n          agentPools {\n            edges {\n              node {\n                id\n                rawId\n                name\n                __typename\n              }\n            }\n          }\n          ancestorProjects {\n            edges {\n              node {\n                id\n                rawId\n                name\n                archived\n              }\n            }\n          }\n        }\n      }\n      excludedCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5aceacf92109206107ce5e2ab40ee102";

export default node;
