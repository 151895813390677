import {
  isValueArrayNotDuplicate,
  isValueNotEmptyMessage,
  isValueNotLong,
  isValueNotValidSymbol,
  isValueRelatedArrayNotDuplicate,
} from '../../../../../utils/validators'

const maxSecretNameLength = 300

const errorMessageNotEmptyField = 'This field must not be empty'

export const secretNameValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: 'Secret name should not be empty',
  },
  {
    validator: isValueRelatedArrayNotDuplicate,
    message: 'This name is already used in parameters',
  },
  {
    validator: isValueArrayNotDuplicate,
    message: 'This secret name already exists',
  },
  {
    validator: isValueNotLong(maxSecretNameLength),
    message: 'The secret name is too long',
  },
  {
    validator: isValueNotValidSymbol(/^[a-zA-Z0-9._\-*]+$/),
    message:
      'A secret name must contain only the [a-zA-Z0-9._-*] characters and start with an ASCII letter',
  },
]

export const secretValueValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: errorMessageNotEmptyField,
  },
]
