import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {NewIntegrationDto} from '../../services/pipelinesApi.types'
import {integrations} from '../SettingsSidebarContent/Integrations/Integrations.consts'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {getJob, deleteIfEmpty} from './EditPipelinePage.slices.utils'

export const deleteIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    id: string
    name: string
  }>
> = (state, action) => {
  const {pipelineId, id, name} = action.payload
  state[pipelineId] ??= {}
  const jobs = state[pipelineId]!.draft?.settings.jobs

  Object.keys(jobs ?? []).forEach(key => {
    const allIntegrations = jobs![key].integrations
    const hasIntegration = allIntegrations?.includes(name)

    if (hasIntegration) {
      jobs![key].integrations = allIntegrations?.filter(integrationsId => integrationsId !== name)

      if (!jobs![key].integrations?.length) {
        deleteIfEmpty(jobs![key], 'integrations')
      }
    }
  })

  state[pipelineId]!.deleted ??= {}
  state[pipelineId]!.deleted!.integrations ??= []
  state[pipelineId]!.deleted!.integrations!.push(id)
}

export const restoreIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    id: string
  }>
> = (state, action) => {
  const {pipelineId, id} = action.payload
  const {deleted} = state[pipelineId] ?? {}
  if (deleted?.integrations?.includes(id)) {
    deleted.integrations = deleted.integrations.filter(featureId => id !== featureId)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    deleteIfEmpty(deleted, 'integrations') && deleteIfEmpty(state[pipelineId], 'deleted')
  }
}

export type AddIntegrationPayload = NewIntegrationDto & {
  pipelineId: string
  jobId?: string | null
}
export const addIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<AddIntegrationPayload>
> = (state, action) => {
  const {pipelineId, jobId, type, parameters} = action.payload
  const id = parameters.displayName!

  const {draft} = state[pipelineId] ?? {}
  if (draft != null) {
    draft.integrations ??= []
    draft.integrations!.push({
      id,
      type,
      parameters: {
        ...integrations[type].defaultParameters,
        ...parameters,
      },
    })
    if (jobId != null) {
      const job = getJob(state, pipelineId, jobId)
      if (job != null && id != null) {
        job.integrations ??= []
        job.integrations!.push(id)
      }
    }
  }
}

export const editIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<
    Partial<NewIntegrationDto> & {
      pipelineId: string
    }
  >
> = (state, action) => {
  const {pipelineId, id, parameters} = action.payload
  const integration = state[pipelineId]?.draft?.integrations?.find(item => item.id === id)
  if (integration != null) {
    Object.assign(integration.parameters, parameters)
  }
}

export const toggleIntegration: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    jobId: string
    id: string
    disabled: boolean
  }>
> = (state, action) => {
  const {pipelineId, jobId, id, disabled} = action.payload
  const job = getJob(state, pipelineId, jobId)
  if (job != null) {
    if (disabled) {
      if (job.integrations?.includes(id)) {
        job.integrations = job.integrations.filter(item => item !== id)

        if (!job.integrations?.length) {
          deleteIfEmpty(job, 'integrations')
        }
      }
    } else {
      job.integrations ??= []
      if (!job.integrations?.includes(id)) {
        job.integrations.push(id)
      }
    }
  }
}
