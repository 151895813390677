import {createContext} from 'react'
import {graphql} from 'react-relay'

import type {currentUserKeyFragment$data} from './__generated__/currentUserKeyFragment.graphql'

export const currentUserKeyFragment = graphql`
  fragment currentUserKeyFragment on User {
    ...useMyIdFragment
    ...useMyUsernameFragment
    ...useUserPropertyFragment
    ...useMyProfileFragment
    ...HeaderUserFragment
    ...AllUserSelectFragment
  }
`

export const CurrentUserKeyContext = createContext<currentUserKeyFragment$data | null>(null)
