import type {ListDataItem} from '@jetbrains/ring-ui/components/list/consts'
import {Type as ListItemType} from '@jetbrains/ring-ui/components/list/consts'
import Tag from '@jetbrains/ring-ui/components/tag/tag'
import {Fragment, useCallback, useMemo} from 'react'

import {useAppSelector} from '../../../../hooks/react-redux'
import {useIsPipelinesSelected} from '../../../../hooks/routes'
import {useSetUserProperty} from '../../../../hooks/useSetUserProperty'
import {getServerInfo} from '../../../../selectors'
import {BS, internalProps} from '../../../../types/BS_types'
import cssVariables from '../../../../utils/cssVariables'
import {truthy} from '../../../../utils/guards'
import {resolveHelpURL, resolveRelative} from '../../../../utils/url'
import {UserProperties} from '../../../../utils/userProperties'
import {useIsHintsModeEnabled} from '../../../packages/Hints/Hints.hooks'
import {TRY_PIPELINES_LINK} from '../../../pages/PipelinesPages/components/PipelinesPromoBanner/PipelinesPromoPopup/PipelinesPromoPopup.const'
import {
  isPipelinesEnabled,
  isPipelinesEnabledInExclusiveMode,
  isPipelinesPromoBannerEnabled,
} from '../../../pages/PipelinesPages/utils/featureToggles'

import {whatsNewHref} from './HelpDropdownPopup.consts'
import {getSupportUrl} from './HelpDropdownPopup.utils'

import styles from './HelpDropdownPopup.css'

const supportLinkEnabled = internalProps['teamcity.ui.supportLink.enabled']

export function useHelpDropdownContent(): ReadonlyArray<ListDataItem> {
  const isPipelinesSelected = useIsPipelinesSelected()
  const isPipelines =
    isPipelinesEnabledInExclusiveMode || (isPipelinesEnabled && isPipelinesSelected)
  const {isEnabled, isGuestOrRoot} = useIsHintsModeEnabled()
  const setUserProperty = useSetUserProperty()
  const toggleTipsMode = useCallback(() => {
    setUserProperty(UserProperties.TIPS_MODE, isEnabled ? 'false' : 'true')
  }, [setUserProperty, isEnabled])

  const {licenseIsCloud, licenseIsEnterpise, version} = useAppSelector(getServerInfo) || {}
  const showLicenseAgreement = internalProps['teamcity.ui.showLicenseAgreementToAllUsers'] !== false

  return useMemo(() => {
    if (isPipelines) {
      return [
        {
          label: 'Documentation',
          href: 'https://www.jetbrains.com/help/teamcity/pipelines/teamcity-pipelines.html',
          rgItemType: ListItemType.LINK,
          target: '_blank',
          rel: 'noreferrer',
        },
        {
          label: 'Submit support request',
          href: getSupportUrl({
            licenseIsCloud,
            licenseIsEnterpise,
            version,
            type: 'teamcity_pipelines',
          }),
          rgItemType: ListItemType.LINK,
          target: '_blank',
          rel: 'noreferrer',
        },
      ]
    }

    return [
      {
        label: 'Documentation',
        href: resolveHelpURL('?TeamCity Documentation'),
        rgItemType: ListItemType.LINK,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'TeamCity Kotlin DSL',
        href: resolveRelative('/app/dsl-documentation/index.html'),
        rgItemType: ListItemType.LINK,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Getting started',
        href: resolveHelpURL('?Configure and Run Your First Build'),
        rgItemType: ListItemType.LINK,
        target: '_blank',
        rel: 'noreferrer',
      },
      !isGuestOrRoot && {
        key: 'enable-tips',
        label: `${isEnabled ? 'Hide' : 'Show'} hints`,
        onClick: toggleTipsMode,
      },
      showLicenseAgreement && {
        label: 'License Agreement',
        href: resolveRelative('/showAgreement.html'),
        rgItemType: ListItemType.LINK,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        rgItemType: ListItemType.SEPARATOR,
      },
      isPipelinesPromoBannerEnabled && {
        key: 'try-pipelines',
        className: styles.tryPipelinesLink,
        href: TRY_PIPELINES_LINK,
        children: (
          <Fragment>
            {'Try Pipelines'}
            <div className={styles.tryPipelinesNewTagWrapper}>
              <Tag
                textColor={cssVariables['--ring-success-color']}
                backgroundColor={cssVariables['--ring-added-background-color']}
                readOnly
              >
                {'New'}
              </Tag>
            </div>
          </Fragment>
        ),
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Share feedback',
        href: BS?.feedbackUrl,
        rgItemType: ListItemType.LINK,
        target: '_blank',
        rel: 'noreferrer',
      },
      supportLinkEnabled &&
        (licenseIsCloud || licenseIsEnterpise) && {
          label: 'Submit support request',
          href: getSupportUrl({licenseIsCloud, licenseIsEnterpise, version}),
          rgItemType: ListItemType.LINK,
          target: '_blank',
          rel: 'noreferrer',
        },
      {
        key: 'whats-new',
        href: whatsNewHref,
        children: `What's new`,
        target: '_blank',
        rel: 'noreferrer',
      },
    ].filter(truthy)
  }, [
    isPipelines,
    licenseIsCloud,
    licenseIsEnterpise,
    version,
    isGuestOrRoot,
    isEnabled,
    toggleTipsMode,
    showLicenseAgreement,
  ])
}
