import type {
  AgentsTree,
  AgentsTreeAgentNode,
  AgentsTreeAgentPoolNode,
  AgentsTreeAgentSelectionNode,
  AgentsTreeAgentTypeNode,
  AgentsTreeFolderTypeNode,
  AgentsTreeRootNode,
} from '../AgentsSidebar.types'
import {AgentTreeItemType} from '../AgentsSidebar.types'

const matchTitle = (title: string | undefined, searchQuery: string) =>
  !!title?.toLowerCase()?.includes(searchQuery.trim().toLowerCase())
const matchAgentNode = (node: AgentsTreeAgentNode, searchQuery: string): AgentsTreeAgentNode => ({
  ...node,
  matching: matchTitle(node.cloud ? node.name : node.title, searchQuery),
})
const matchAgentTypeNode = (
  node: AgentsTreeAgentTypeNode,
  searchQuery: string,
  parentIsMatching: boolean,
): AgentsTreeAgentTypeNode => {
  const children = []
  let containsMatching = false
  const matching = matchTitle(node.title, searchQuery)

  for (const child of node.children) {
    const matchedChild = matchAgentNode(child, searchQuery)

    if (matchedChild.matching === true) {
      containsMatching = true
    }

    if (matchedChild.matching === true || matching || parentIsMatching) {
      children.push(matchedChild)
    }
  }

  return {...node, children, matching, containsMatching}
}
const matchAgentPoolNode = (
  node: AgentsTreeAgentPoolNode,
  searchQuery: string,
): AgentsTreeAgentPoolNode => {
  let containsMatching = false
  const matching = matchTitle(node.title, searchQuery)
  const children = matching ? node.children : []

  for (const child of node.children) {
    const matchedChild =
      child.type === AgentTreeItemType.AGENT_TYPE
        ? matchAgentTypeNode(child, searchQuery, matching)
        : matchAgentNode(child, searchQuery)

    if (matchedChild.matching === true || matchedChild.containsMatching === true) {
      if (!matching) {
        children.push(matchedChild)
      }
      containsMatching = true
    }
  }

  return {...node, children, matching, containsMatching}
}

const matchAgentFolderNode = (
  node: AgentsTreeFolderTypeNode,
  searchQuery: string,
): AgentsTreeFolderTypeNode => {
  const children: AgentsTreeFolderTypeNode['children'] = []
  let containsMatching = false

  function match(child: AgentsTreeAgentTypeNode | AgentsTreeFolderTypeNode | AgentsTreeAgentNode) {
    if (child.type === AgentTreeItemType.AGENT) {
      return matchAgentNode(child, searchQuery)
    } else if (child.type === AgentTreeItemType.FOLDER) {
      return matchAgentFolderNode(child, searchQuery)
    } else {
      return matchAgentTypeNode(child, searchQuery, false)
    }
  }

  for (const child of node.children) {
    const matchedChild = match(child)

    if (matchedChild.matching === true || matchedChild.containsMatching === true) {
      containsMatching = true
      children.push(matchedChild)
    }
  }

  return {...node, children, containsMatching}
}

const matchAgentSelectionNode = (
  node: AgentsTreeAgentSelectionNode,
  searchQuery: string,
): AgentsTreeAgentSelectionNode => {
  const children: AgentsTreeAgentSelectionNode['children'] = []
  let containsMatching = false

  function match(child: AgentsTreeAgentNode | AgentsTreeAgentPoolNode | AgentsTreeFolderTypeNode) {
    if (child.type === AgentTreeItemType.AGENT) {
      return matchAgentNode(child, searchQuery)
    } else if (child.type === AgentTreeItemType.FOLDER) {
      return matchAgentFolderNode(child, searchQuery)
    } else {
      return matchAgentPoolNode(child, searchQuery)
    }
  }

  for (const child of node.children) {
    const matchedChild = match(child)

    if (matchedChild.matching === true || matchedChild.containsMatching === true) {
      containsMatching = true
      children.push(matchedChild)
    }
  }

  return {...node, children, containsMatching}
}

export function matchAgentsTree(
  agentsTree: AgentsTree,
  searchState: {searchQuery: string},
): AgentsTree {
  const {searchQuery} = searchState
  if (searchQuery === '') {
    return agentsTree
  }

  const matchingAgentsTree: Array<AgentsTreeRootNode> = []

  for (const node of agentsTree) {
    if (node.type === AgentTreeItemType.POOL) {
      const poolNode = matchAgentPoolNode(node, searchQuery)

      if (poolNode.matching === true || poolNode.containsMatching === true) {
        matchingAgentsTree.push(poolNode)
      }
    } else {
      const selectionNode = matchAgentSelectionNode(node, searchQuery)

      if (selectionNode.containsMatching === true) {
        matchingAgentsTree.push(selectionNode)
      }
    }
  }

  return matchingAgentsTree
}
