let adminSpaceAvailable: boolean
export const setAdminSpaceAvailable = (value: boolean) => {
  adminSpaceAvailable = value
}
export const getAdminSpaceAvailable = () => adminSpaceAvailable

let activePageId: string
export const setActivePageId = (id: string) => {
  activePageId = id
}
export const getActivePageId = () => activePageId
