import type {State} from '../../../../../../reducers/types'
import {getExtensionEndpoint} from '../../../../../../selectors'
import type {FullLogTarget, BuildLogSearchState} from '../../../BuildLog.types'

export const isSearchEnabled = (state: State): boolean =>
  Boolean(getExtensionEndpoint(state, 'fetchExpandedBuildLogMessage'))
export const getBuilLogSearchState = (state: State, target: FullLogTarget): BuildLogSearchState =>
  state.buildLog.searchStates[target]
export const getBuilLogSearchQuery = (
  state: State,
  target: FullLogTarget,
): string | null | undefined => getBuilLogSearchState(state, target).query
export const getBuilLogSearchFoundId = (
  state: State,
  target: FullLogTarget,
): number | null | undefined => getBuilLogSearchState(state, target).foundId
export const getBuilLogSearchMatchPosition = (
  state: State,
  target: FullLogTarget,
): number | null | undefined => getBuilLogSearchState(state, target).matchPosition
