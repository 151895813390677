/**
 * @generated SignedSource<<f1a36b8a2bb20946cac800fba45202b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BuildApprovalDialogQuery$variables = {
  locator: string;
};
export type BuildApprovalDialogQuery$data = {
  readonly approvalInfo: {
    readonly approvalReasons: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly url: string | null | undefined;
    }> | null | undefined;
    readonly buildChainBuilds: ReadonlyArray<{
      readonly approvalReason: {
        readonly description: string | null | undefined;
        readonly url: string | null | undefined;
      };
      readonly builds: ReadonlyArray<{
        readonly buildId: string | null | undefined;
        readonly buildTypeId: string | null | undefined;
        readonly buildTypeName: string | null | undefined;
      }> | null | undefined;
    }> | null | undefined;
  } | null | undefined;
};
export type BuildApprovalDialogQuery = {
  response: BuildApprovalDialogQuery$data;
  variables: BuildApprovalDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "buildLocator",
        "variableName": "locator"
      }
    ],
    "concreteType": "ApprovalInfo",
    "kind": "LinkedField",
    "name": "approvalInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApprovalReason",
        "kind": "LinkedField",
        "name": "approvalReasons",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BuildWithReasons",
        "kind": "LinkedField",
        "name": "buildChainBuilds",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ApprovableBuild",
            "kind": "LinkedField",
            "name": "builds",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "buildId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "buildTypeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "buildTypeName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApprovalReason",
            "kind": "LinkedField",
            "name": "approvalReason",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildApprovalDialogQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildApprovalDialogQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1ec95927e1423d7fd8dfa16d9335a53a",
    "id": null,
    "metadata": {},
    "name": "BuildApprovalDialogQuery",
    "operationKind": "query",
    "text": "query BuildApprovalDialogQuery(\n  $locator: String!\n) {\n  approvalInfo(buildLocator: $locator) {\n    approvalReasons {\n      description\n      url\n    }\n    buildChainBuilds {\n      builds {\n        buildId\n        buildTypeId\n        buildTypeName\n      }\n      approvalReason {\n        description\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ffb126c126b351c92c7a3884d2ab670";

export default node;
