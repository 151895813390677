import buildType12pxIcon from '@jetbrains/icons/buildType-12px.svg'
import chevronDownIcon from '@jetbrains/icons/chevron-down'
import chevronRightIcon from '@jetbrains/icons/chevron-right'
import newWindowIcon from '@jetbrains/icons/new-window-12px.svg'
import Button from '@jetbrains/ring-ui/components/button/button'
import ButtonSet from '@jetbrains/ring-ui/components/button-set/button-set'
import Collapse from '@jetbrains/ring-ui/components/collapse/collapse'
import CollapseContent from '@jetbrains/ring-ui/components/collapse/collapse-content'
import CollapseControl from '@jetbrains/ring-ui/components/collapse/collapse-control'
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog'
import {Content, Header} from '@jetbrains/ring-ui/components/island/island'
import Panel from '@jetbrains/ring-ui/components/panel/panel'
import {graphql, useLazyLoadQuery} from 'react-relay'

import {useAppSelector} from '../../../../hooks/react-redux'
import {getBuildHref} from '../../../../routes'
import {getIsSakuraUI} from '../../../../selectors'
import type {BuildId} from '../../../../types'
import {resolveWebEntityLink, WebEntityType} from '../../../../utils/entityLinks'
import Link from '../../Link/Link'
import SvgIcon from '../../SvgIcon/SvgIcon'

import type {BuildApprovalDialogQuery} from './__generated__/BuildApprovalDialogQuery.graphql'

import styles from './BuildApprovalDialog.css'

const query = graphql`
  query BuildApprovalDialogQuery($locator: String!) {
    approvalInfo(buildLocator: $locator) {
      approvalReasons {
        description
        url
      }
      buildChainBuilds {
        builds {
          buildId
          buildTypeId
          buildTypeName
        }
        approvalReason {
          description
          url
        }
      }
    }
  }
`

type BuildApprovalDialogProps = {
  buildId: BuildId
  show: boolean
  onClose: () => void
  onApprove: () => void
  onApproveAll: () => void
}

function BuildApprovalDialogContent({
  buildId,
  show,
  onClose,
  onApprove,
  onApproveAll,
}: BuildApprovalDialogProps) {
  const approvalInfoData = useLazyLoadQuery<BuildApprovalDialogQuery>(query, {
    locator: `id:${buildId}`,
  })
  const isSakuraUI = useAppSelector(getIsSakuraUI)
  const approvalInfo = approvalInfoData?.approvalInfo
  const cntBuilds = new Set(
    approvalInfo?.buildChainBuilds?.flatMap(reasonInfo =>
      reasonInfo.builds?.map(buildInfo => buildInfo.buildId),
    ),
  ).size
  return (
    <Dialog show={show}>
      <Header>{cntBuilds === 1 ? 'Approve build to start' : 'Approve build chain to start'}</Header>
      <Content className={styles.approvalDialogContent}>
        <div>
          {cntBuilds === 1
            ? approvalInfo?.approvalReasons?.map(reason => (
                <span key={reason.description} className={styles.mainReason}>
                  {reason.description}
                  {reason.url != null ? (
                    <Link href={reason.url} target="_blank" rel="noopener noreferrer">
                      <SvgIcon icon={newWindowIcon} />
                    </Link>
                  ) : null}
                </span>
              ))
            : null}
          {cntBuilds > 1 && (
            <div className={styles.buildChainReasons}>
              {approvalInfo?.buildChainBuilds?.map(reasonWithBuilds => (
                <div key={reasonWithBuilds.approvalReason.description}>
                  <Collapse>
                    <CollapseControl>
                      {(collapsed: boolean) => {
                        const reasonDescription = `${reasonWithBuilds.builds?.length} ${
                          reasonWithBuilds.builds?.length === 1 ? 'build' : 'builds'
                        } ${reasonWithBuilds.approvalReason.description}`
                        const buttonText = (
                          <span className={styles.triggerButtonText}>
                            {reasonDescription}
                            {reasonWithBuilds.approvalReason.url != null ? (
                              <Link
                                href={reasonWithBuilds.approvalReason.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <SvgIcon icon={newWindowIcon} />
                              </Link>
                            ) : null}
                          </span>
                        )
                        return (
                          <span
                            style={{
                              display: 'flex',
                            }}
                          >
                            {collapsed ? (
                              <Button
                                aria-label="Expand"
                                icon={chevronRightIcon}
                                className={styles.triggerButton}
                              >
                                {buttonText}
                              </Button>
                            ) : (
                              <Button
                                aria-label="Collapse"
                                icon={chevronDownIcon}
                                className={styles.triggerButton}
                              >
                                {buttonText}
                              </Button>
                            )}
                          </span>
                        )
                      }}
                    </CollapseControl>
                    <CollapseContent>
                      <div className={styles.buildsList}>
                        {reasonWithBuilds.builds?.map(build => (
                          <div key={build.buildId}>
                            {
                              <span className={styles.buildsListEntry}>
                                <SvgIcon icon={buildType12pxIcon} />
                                <Link
                                  href={
                                    isSakuraUI
                                      ? getBuildHref(build.buildId!, build.buildTypeId!)
                                      : resolveWebEntityLink(WebEntityType.BUILD, build.buildId)!
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={styles.buildsListLink}
                                >
                                  {build.buildTypeName}
                                </Link>
                              </span>
                            }
                          </div>
                        ))}
                      </div>
                    </CollapseContent>
                  </Collapse>
                </div>
              ))}
            </div>
          )}
        </div>
      </Content>
      <Panel>
        {cntBuilds > 1 ? (
          <ButtonSet>
            <Button
              primary
              onClick={() => {
                onApproveAll()
                onClose()
              }}
            >
              {'Approve build chain'}
            </Button>
            <Button onClick={onClose}>{'Close'}</Button>
          </ButtonSet>
        ) : (
          <ButtonSet>
            <Button
              primary
              onClick={() => {
                onApprove()
                onClose()
              }}
            >
              {'Approve'}
            </Button>
            <Button onClick={onClose}>{'Close'}</Button>
          </ButtonSet>
        )}
      </Panel>
    </Dialog>
  )
}

export default function BuildApprovalDialog({
  buildId,
  show,
  onClose,
  onApprove,
  onApproveAll,
}: BuildApprovalDialogProps) {
  return show ? (
    <BuildApprovalDialogContent
      buildId={buildId}
      show={show}
      onClose={onClose}
      onApprove={onApprove}
      onApproveAll={onApproveAll}
    />
  ) : null
}
