import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'

import type {ThinNestedEntryPointParamsObject} from '../../../types/entryPoints'
import {makeResource} from '../../../utils/makeResource'
import {AgentTypePageEntryPoint} from '../../pages/AgentsPages/AgentTypePage/AgentTypePage.entryPoint'
import {AgentTypePipelinePageEntryPoint} from '../../pages/PipelinesPages/AgentsPage/AgentType/AgentTypePipelinePage.entryPoint'
import {isPipelinesAgentPageEnabled} from '../../pages/PipelinesPages/utils/featureToggles'

import type {AppAgentTypeComponent, AppAgentTypeNestedEntryPoints} from './AppAgentType'

export const AppAgentTypeEntryPoint: EntryPoint<AppAgentTypeComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'AppAgentType',
    () => import(/* webpackChunkName: "AppAgentType", webpackPrefetch: true */ './AppAgentType'),
  ),
  getPreloadProps: entryPointParams => {
    const entryPoints: ThinNestedEntryPointParamsObject<AppAgentTypeNestedEntryPoints> = {}

    if (isPipelinesAgentPageEnabled()) {
      entryPoints.pipeline = {
        entryPoint: AgentTypePipelinePageEntryPoint,
        entryPointParams,
      }
    } else {
      entryPoints.common = {
        entryPoint: AgentTypePageEntryPoint,
        entryPointParams,
      }
    }

    return {entryPoints}
  },
}
