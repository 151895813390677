import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {getAgentsPagesQueries} from '../AgentsPages.queries'

import type {UnauthorizedAgentsPageContainerComponent} from './UnauthorizedAgentsPage'
import {query} from './UnauthorizedAgentsPage.queries'

export const UnauthorizedAgentsPageEntryPoint: EntryPoint<UnauthorizedAgentsPageContainerComponent> =
  {
    root: makeResource(
      'UnauthorizedAgentsPage',
      () =>
        import(
          /* webpackChunkName: "UnauthorizedAgentsPage", webpackPrefetch: true */ './UnauthorizedAgentsPage'
        ),
    ),
    getPreloadProps: () => ({
      queries: {
        ...getAgentsPagesQueries(),
        main: {
          parameters: getRequest(query),
          variables: {},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
    }),
  }
