export enum SelfHostedRequirementType {
  OS = 'OS',
  CPU_COUNT = 'CPU count',
  CPU_ARCH = 'CPU arch',
  RAM_AMOUNT = 'RAM amount',
  CUSTOM = 'Custom',
}

export type SelfHostedRequirement =
  | CpuCountRequirement
  | ArchTypeRequirement
  | RamAmountRequirement
  | OSFamilyRequirement
  | OSVersionRequirement
  | CustomRequirement

export function isCpuRequirement(
  requirement: SelfHostedRequirement,
): requirement is CpuCountRequirement {
  return 'cpu' in requirement
}

export function isArchRequirement(
  requirement: SelfHostedRequirement,
): requirement is ArchTypeRequirement {
  return 'arch' in requirement
}

export function isRamRequirement(
  requirement: SelfHostedRequirement,
): requirement is RamAmountRequirement {
  return 'ram' in requirement
}

export function isOsFamilyRequirement(
  requirement: SelfHostedRequirement,
): requirement is OSFamilyRequirement {
  return 'os-family' in requirement
}

export function isOsVersionRequirement(
  requirement: SelfHostedRequirement,
): requirement is OSVersionRequirement {
  return 'os-version' in requirement
}

export function isCustomRequirement(
  requirement: SelfHostedRequirement,
): requirement is CustomRequirement {
  return 'requirement' in requirement
}

export interface OSFamilyRequirement {
  ['os-family']: string
}

export interface OSVersionRequirement {
  ['os-version']: string
}

export interface CustomRequirement {
  name: string
  requirement: CustomRequirementCondition
  parameter: string
  value?: string
}

export const anyVersion = 'Any'

export interface ArchTypeRequirement {
  arch: ArchType
}

export enum ArchType {
  X86 = 'x86',
  X86_64 = 'x86_64',
  ARM = 'arm',
  AARCH64 = 'aarch64',
  IA64 = 'ia64',
  SPARC = 'sparc',
  RISCV64 = 'riscv64',
}

export interface CpuCountRequirement {
  cpu: number
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const cpuCountList = [1, 2, 3, 4]

export interface RamAmountRequirement {
  ram: string
}

export const ramAmountList = ['2GB', '4GB']

export enum CustomRequirementCondition {
  EXISTS = 'exists',
  NOT_EXISTS = 'not-exists',
  MATCHES = 'matches',
  EQUALS = 'equals',
  NOT_EQUALS = 'not-equals',
  MORE_THAN = 'more-than',
  LESS_THAN = 'less-than',
  MORE_THAN_OR_EQUALS = 'more-than-or-equals',
  LESS_THAN_OR_EQUALS = 'less-than-or-equals',
}

export const conditionSign: Record<CustomRequirementCondition, string | undefined> = {
  [CustomRequirementCondition.EXISTS]: undefined,
  [CustomRequirementCondition.NOT_EXISTS]: undefined,
  [CustomRequirementCondition.MATCHES]: undefined,
  [CustomRequirementCondition.EQUALS]: '=',
  [CustomRequirementCondition.NOT_EQUALS]: '≠',
  [CustomRequirementCondition.MORE_THAN]: '>',
  [CustomRequirementCondition.LESS_THAN]: '<',
  [CustomRequirementCondition.MORE_THAN_OR_EQUALS]: '≥',
  [CustomRequirementCondition.LESS_THAN_OR_EQUALS]: '≤',
}

export const conditionText = {
  [CustomRequirementCondition.EXISTS]: 'Exists',
  [CustomRequirementCondition.NOT_EXISTS]: 'Not exists',
  [CustomRequirementCondition.MATCHES]: 'Matches',
  [CustomRequirementCondition.EQUALS]: 'Equals',
  [CustomRequirementCondition.NOT_EQUALS]: 'Not equals',
  [CustomRequirementCondition.MORE_THAN]: 'More than',
  [CustomRequirementCondition.LESS_THAN]: 'Less than',
  [CustomRequirementCondition.MORE_THAN_OR_EQUALS]: 'More than or equals',
  [CustomRequirementCondition.LESS_THAN_OR_EQUALS]: 'Less than or equals',
}
