import {graphql} from 'react-relay'

export const appContainerQuery = graphql`
  query AppContainerQuery {
    user(userLocator: "current") {
      id
      properties {
        property {
          name
          value
        }
      }
      ...AppFragment
    }
  }
`
