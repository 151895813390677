import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

import type {ExpandState, ProjectId} from '../../../../../types'
import type {KeyValue} from '../../../../../utils/object'

type ActiveItemData = {
  activeBuildTypeId?: string | null
  activeProjectId?: string | null
  isFavoriteItemOpen: boolean
}
type ProjectSidebarData = {
  searchQuery: string
  searchActive: boolean
  allProjectsCollapsed: boolean
  showArchivedProjects: boolean
  activeItem: ActiveItemData
}

export const allProjectsExpandState = createSlice({
  name: 'allProjectsExpandState',
  initialState: {} as KeyValue<ProjectId, ExpandState>,
  reducers: {
    toggle(state, action: PayloadAction<ExpandState[]>) {
      action.payload.forEach(item => {
        state[item.id!] = item
      })
    },
  },
})

const sidebarInitialStaet: ProjectSidebarData = {
  searchQuery: '',
  searchActive: false,
  allProjectsCollapsed: true,
  showArchivedProjects: false,
  activeItem: {
    activeBuildTypeId: null,
    activeProjectId: null,
    isFavoriteItemOpen: true,
  },
}
export const projectsSidebar = createSlice({
  name: 'projectsSidebar',
  initialState: sidebarInitialStaet,
  reducers: {
    changeSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload
    },
    changeSearchActive(state, action: PayloadAction<boolean>) {
      state.searchActive = action.payload
      if (!action.payload) {
        state.searchQuery = ''
        state.showArchivedProjects = false
      }
    },
    changeAllProjectsCollapsed(state, action: PayloadAction<boolean>) {
      state.allProjectsCollapsed = action.payload
    },
    toggleArchivedProjectsInSidebar(state) {
      state.showArchivedProjects = !state.showArchivedProjects
    },
    changeActiveItem(state, action: PayloadAction<ActiveItemData>) {
      state.activeItem = action.payload
    },
  },
})

export const searchProjectsExpandState = createSlice({
  name: 'searchProjectsExpandState',
  initialState: {} as KeyValue<ProjectId, ExpandState>,
  reducers: {
    toggle(state, action: PayloadAction<ExpandState[]>) {
      action.payload.forEach(item => {
        state[item.id!] = item
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(projectsSidebar.actions.changeSearchQuery, () => ({}))
  },
})
