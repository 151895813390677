import {graphql} from 'react-relay'

export const agentQuery = graphql`
  query AgentDomainBreadcrumbsAgentQuery($agentLocator: String!, $fetchAgent: Boolean!) {
    agent: agentRest(agentLocator: $agentLocator) @include(if: $fetchAgent) {
      id
      name
      pool {
        id
      }
      typeId
    }
  }
`
