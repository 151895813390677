import classNames from 'classnames'
import * as React from 'react'
import type {CSSProperties} from 'react'

import styles from './MiddleEllipsis.css'

type DefaultProps = {
  children: string
  tailLength: number
}
type MiddleEllipsisProps = DefaultProps & {
  className?: string
  visibleClassName?: string
  title?: string | null
  style?: CSSProperties
  'aria-hidden'?: boolean
}

class MiddleEllipsis extends React.PureComponent<MiddleEllipsisProps> {
  static defaultProps: DefaultProps = {
    children: '',
    tailLength: 5,
  }

  getHead(): string {
    const {children, tailLength} = this.props
    const text = children.trim()
    return tailLength > 0 ? text.slice(0, -tailLength) : text
  }

  render(): React.ReactNode {
    const {
      children,
      className,
      visibleClassName,
      tailLength,
      title = children,
      ...restProps
    } = this.props
    const text = children.trim()
    const tail = tailLength > 0 ? text.slice(-tailLength) : ''
    const head = this.getHead()
    const classes = classNames(styles.middleEllipsis, className)
    const visibleClasses = classNames(styles.visible, visibleClassName)
    return (
      <span {...restProps} className={classes} title={title ?? undefined} aria-label={children}>
        <span className={styles.searchable}>{text}</span>
        <span
          className={visibleClasses}
          data-head={head.replace(/\s$/, '\u00a0')}
          data-tail={tail.replace(/^\s/, '\u00a0')}
        />
      </span>
    )
  }
}

export default MiddleEllipsis
