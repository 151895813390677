import Button from '@jetbrains/ring-ui/components/button/button'
import ButtonSet from '@jetbrains/ring-ui/components/button-set/button-set'
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog'
import {H1} from '@jetbrains/ring-ui/components/heading/heading'
import {memo} from 'react'

import Link from '../../../../../common/Link/Link'
import SvgIcon from '../../../../../common/SvgIcon/SvgIcon'

import {PIPELINES_INFO_PAGE, TRY_PIPELINES_LINK} from './PipelinesPromoPopup.const'

import styles from './PipelinesPromoPopup.css'

type Props = {
  isOpen: boolean
  onClose: () => void
}

function PipelinesPromoPopup({isOpen, onClose}: Props) {
  return (
    <div>
      <Dialog
        label="Change"
        className={styles.overlay}
        contentClassName={styles.dialog}
        onCloseAttempt={onClose}
        autoFocusFirst={false}
        show={isOpen}
        showCloseButton
        closeButtonInside
        trapFocus
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <H1 className={styles.heading}>{'Pipelines are coming to TeamCity'}</H1>
          </div>
          <div className={styles.content}>
            <div className={styles.promoText}>
              <p>
                {`Experience `}
                <Link href={PIPELINES_INFO_PAGE} target="_blank" rel="noreferrer">
                  {'TeamCity Pipelines'}
                </Link>
                {`, our latest cloud-based CI/CD solution that will soon be available as a part of TeamCity!
                Built with users in mind, Pipelines offer a powerful suite of features, including:`}
              </p>
              <p>
                <SvgIcon className={styles.listCheckmark} icon="checkmark-20px" />
                {'A smart visual editor'}
              </p>
              <p>
                <SvgIcon className={styles.listCheckmark} icon="checkmark-20px" />
                {'Intelligent configuration assistance'}
              </p>
              <p>
                <SvgIcon className={styles.listCheckmark} icon="checkmark-20px" />
                {'YAML configuration as code'}
              </p>
              <p>
                {`Can’t wait to try it? Click the button below to claim your own cloud instance of standalone Pipelines today!`}
              </p>
              <p>
                {'Find the link to Pipelines anytime under '}
                <span className={styles.boldText}>{'Help | Try Pipelines'}</span>
              </p>
            </div>
            <div className={styles.promoImage} />
          </div>
          <div className={styles.footer}>
            <ButtonSet>
              <Button href={TRY_PIPELINES_LINK} target="_blank" rel="noreferrer" primary>
                <SvgIcon className={styles.tryNowIcon} icon="new-window" />
                <span>{'Try now'}</span>
              </Button>
              <Button onClick={onClose}>{'Later'}</Button>
            </ButtonSet>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default memo(PipelinesPromoPopup)
