import {getSWRegistration, requestSkipWaiting} from './sw.lifecycle'

export default async function setupServiceWorkerChecker() {
  const registration = await getSWRegistration()

  if (registration == null) {
    return
  }

  if (registration.waiting) {
    requestSkipWaiting()
  }

  registration.addEventListener('updatefound', () => {
    if (registration.installing) {
      registration.installing.addEventListener('statechange', () => {
        if (registration.waiting && navigator.serviceWorker?.controller) {
          requestSkipWaiting()
        }
      })
    }
  })
}
