import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {OverviewAgentTabNamesEnum, toAgentId} from '../../../../types'
import type {ThinNestedEntryPointParamsObject} from '../../../../types/entryPoints'
import {makeResource} from '../../../../utils/makeResource'
import {queryToObject} from '../../../../utils/queryParams'
import {getAgentsPagesQueries} from '../AgentsPages.queries'

import type {AgentPageContainerComponent, EntryPoints} from './AgentPage.container'
import {query} from './AgentPage.queries'
import {AgentParametersEntryPoint} from './AgentParameters/AgentParameters.entryPoint'
import {isNewAgentParametersTab} from './AgentParameters/utils/isNewAgentParametersTab'

export const AgentPageEntryPoint: EntryPoint<AgentPageContainerComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'AgentPage',
    () =>
      import(/* webpackChunkName: "AgentPage", webpackPrefetch: true */ './AgentPage.container'),
  ),
  getPreloadProps: ({params, request}) => {
    const entryPoints: ThinNestedEntryPointParamsObject<EntryPoints> = {}
    const url = new URL(request.url)
    const {tab} = queryToObject(url.search)
    if (tab === OverviewAgentTabNamesEnum.PARAMETERS && isNewAgentParametersTab()) {
      entryPoints.parametersTab = {
        entryPoint: AgentParametersEntryPoint,
        entryPointParams: toAgentId(params.agentId!),
      }
    }
    return {
      queries: {
        ...getAgentsPagesQueries(),
        main: {
          parameters: getRequest(query),
          variables: {},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints,
    }
  },
}
