import {graphql} from 'react-relay'

export const query = graphql`
  query BuildPageQuery($btLocator: String!, $headLocator: String!) {
    buildType(btLocator: $btLocator) {
      project {
        internalId
        buildTypes(locator: $headLocator) {
          buildType {
            id
          }
        }
      }
    }
  }
`

export const runIdQuery = graphql`
  query BuildPageRunIdQuery($buildLocator: String!, $skip: Boolean!) {
    build(buildLocator: $buildLocator) @skip(if: $skip) {
      id
    }
  }
`
