import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {PermissionQuery} from '../../hooks/usePermission'
import {getProjectPermissionsLocator} from '../../rest/permission'
import {
  isAgentDomainBreadcrumbsScreen,
  isAgentsScreen,
  isPipelinesEditScreen,
  isPipelinesRunScreen,
  isPipelinesScreen,
  isProjectDomainBreadcrumbsScreen,
  isQueueScreen,
  isScreenWithSidebar,
} from '../../routes'
import {Permission, ROOT_PROJECT_ID} from '../../types'
import type {ThinQueryParamsObject, ThinNestedEntryPointParamsObject} from '../../types/entryPoints'
import {makeResource} from '../../utils/makeResource'
import {queryToObject} from '../../utils/queryParams'
import {AgentDomainBreadcrumbsEntryPoint} from '../common/AgentDomainBreadcrumbs/AgentDomainBreadcrumbs.entryPoint'
import {BuildDetailsContentEntryPoint} from '../common/Builds/BuildDetails/BuildDetailsContent/BuildDetailsContent.entryPoint'
import {OverviewBreadcrumbsEntryPoint} from '../common/OverviewBreadcrumbs/OverviewBreadcrumbs.entryPoint'
import {AgentsSidebarEntryPoint} from '../pages/AgentsPages/AgentsSidebar/AgentsSidebar.entryPoint'
import EditPipelineBreadcrumbsEntryPoint from '../pages/PipelinesPages/EditPipelinePage/EditPipelineBreadcrumbs/EditPipelineBreadcrumbs.entryPoint'
import PipelineRunBreadcrumbsEntryPoint from '../pages/PipelinesPages/PipelineRunPage/PipelineRunBreadcrumbs/PipelineRunBreadcrumbs.entryPoint'

import type {AppNestedEntryPoints, AppOuterContainerComponent, AppQueries} from './App.container'
import {appContainerQuery} from './App.queries'
import {ProjectsSidebarEntryPoint} from './SidebarPanel/SidebarPanelContent/ProjectsSidebar/ProjectsSidebar.entryPoint'
import {QueueSidebarEntryPoint} from './SidebarPanel/SidebarPanelContent/QueueSidebar/QueueSidebar.entryPoint'

export const AppEntryPoint: EntryPoint<AppOuterContainerComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'App',
    () => import(/* webpackChunkName: "App", webpackPrefetch: true */ './App.container'),
  ),
  getPreloadProps: args => {
    const {request, params} = args
    const queries: ThinQueryParamsObject<AppQueries> = {
      main: {
        parameters: getRequest(appContainerQuery),
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
      canCreateSubproject: {
        parameters: getRequest(PermissionQuery),
        variables: {
          locator: getProjectPermissionsLocator(Permission.CREATE_SUB_PROJECT, ROOT_PROJECT_ID),
          skip: false,
        },
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    }
    const entryPoints: ThinNestedEntryPointParamsObject<AppNestedEntryPoints> = {}
    const url = new URL(request.url)
    const queryParams = queryToObject(url.search)
    const buildId = Number(url.hash)
    if (buildId) {
      entryPoints.buildDetailsContent = {
        entryPoint: BuildDetailsContentEntryPoint,
        entryPointParams: buildId,
      }
    }
    if (isScreenWithSidebar(url.pathname)) {
      if (isQueueScreen(url.pathname)) {
        entryPoints.queueSidebar = {entryPoint: QueueSidebarEntryPoint, entryPointParams: {}}
      } else if (isAgentsScreen(url.pathname)) {
        entryPoints.agentsSidebar = {entryPoint: AgentsSidebarEntryPoint, entryPointParams: {}}
      } else if (!isPipelinesScreen(url.pathname)) {
        entryPoints.projectsSidebar = {entryPoint: ProjectsSidebarEntryPoint, entryPointParams: {}}
      }
    }
    if (isProjectDomainBreadcrumbsScreen(url.pathname)) {
      entryPoints.breadcrumbs = {entryPoint: OverviewBreadcrumbsEntryPoint, entryPointParams: args}
    } else if (isAgentDomainBreadcrumbsScreen(url.pathname)) {
      entryPoints.breadcrumbs = {
        entryPoint: AgentDomainBreadcrumbsEntryPoint,
        entryPointParams: args,
      }
    } else if (isPipelinesEditScreen(queryParams)) {
      entryPoints.breadcrumbs = {
        entryPoint: EditPipelineBreadcrumbsEntryPoint,
        entryPointParams: args,
      }
    } else if (isPipelinesRunScreen(url.pathname, params)) {
      entryPoints.breadcrumbs = {
        entryPoint: PipelineRunBreadcrumbsEntryPoint,
        entryPointParams: args,
      }
    }
    return {queries, entryPoints}
  },
}
