/**
 * @generated SignedSource<<41d51115d6dfd32ae2b2e770214ad9a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AgentsPagesPoolAuthorizePermissionsQuery$variables = {
  agentPoolId: string;
};
export type AgentsPagesPoolAuthorizePermissionsQuery$data = {
  readonly agentPool: {
    readonly id: string;
    readonly permissions: {
      readonly authorizeAgents: boolean | null | undefined;
      readonly manageAgents: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AgentsPagesPoolAuthorizePermissionsQuery = {
  response: AgentsPagesPoolAuthorizePermissionsQuery$data;
  variables: AgentsPagesPoolAuthorizePermissionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentPoolId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "agentPoolId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AgentPoolPermissions",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorizeAgents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manageAgents",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsPagesPoolAuthorizePermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "agentPool",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentsPagesPoolAuthorizePermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "agentPool",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f44da6711cb6cc9df45bd0d3572b8831",
    "id": null,
    "metadata": {},
    "name": "AgentsPagesPoolAuthorizePermissionsQuery",
    "operationKind": "query",
    "text": "query AgentsPagesPoolAuthorizePermissionsQuery(\n  $agentPoolId: ID!\n) {\n  agentPool(id: $agentPoolId) {\n    __typename\n    id\n    permissions {\n      authorizeAgents\n      manageAgents\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "58efa228d6fb218f76ef3fbc4d32063b";

export default node;
