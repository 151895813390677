import type {KeyValue} from '../../../../../utils/object'
import type {NewPipeline, Pipeline} from '../../services/pipelinesApi.types'

export type PipelineDraft = {
  original?: Pipeline
  draft?: NewPipeline
  isCollisionApi?: boolean
  deleted?: {
    jobs?: string[]
    parameters?: string[]
    steps?: Record<string, number[]>
    integrations?: string[]
    secrets?: string[]
    additionalVcsRoots?: string[]
    incompleteAdditionalVcsRoots?: number[]
  }
  renamed?: {
    parameters?: Record<string, string>
    integrations?: Record<string, string>
    secrets?: Record<string, string>
  }
  reordered?: {
    steps?: Record<string, number[]>
  }
  added?: {
    parameters?: Record<string, boolean>
    secrets?: Record<string, boolean>
  }
}

export type PipelineDraftState = KeyValue<string, PipelineDraft>

export enum FormType {
  PIPELINE_INTEGRATION = 'pipelineIntegration',
  BLOCK_HEADER = 'blockHeader',
  REPOSITORY = 'repository',
  NAME = 'name',
  STEP = 'step',
  PARAMETER = 'parameter',
  SECRET = 'secret',
  TRIGGER = 'trigger',
}

type PipelineFormState = {
  open: boolean
  isAdd?: boolean
}
export type PipelineFormsState = KeyValue<string, KeyValue<string, PipelineFormState>>
export type PipelineFormParams = {
  pipelineId: string
  jobId?: string | null
  id?: string | number
  type: FormType
  isAdd?: boolean
}
