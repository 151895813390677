import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {getAgentsPagesQueries} from '../AgentsPages.queries'

import type {AgentsOverviewPageComponent} from './AgentsOverviewPage'
import {query} from './AgentsOverviewPage.queries'

export const AgentsOverviewPageEntryPoint: EntryPoint<AgentsOverviewPageComponent> = {
  root: makeResource(
    'AgentsOverviewPage',
    () =>
      import(
        /* webpackChunkName: "AgentsOverviewPage", webpackPrefetch: true */ './AgentsOverviewPage'
      ),
  ),
  getPreloadProps: () => ({
    queries: {
      ...getAgentsPagesQueries(),
      main: {
        parameters: getRequest(query),
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
