import {mapProps} from 'recompose'

import buttonStyles from '@jetbrains/ring-ui/components/button/button.css'
import type {ClickableLinkProps} from '@jetbrains/ring-ui/components/link/clickableLink'
import type {LinkProps} from '@jetbrains/ring-ui/components/link/link'
import {linkHOC} from '@jetbrains/ring-ui/components/link/link'
import type {
  RouterLinkProps as RouterLinkAPIProps,
  RouterButtonProps as RouterButtonAPIProps,
} from '@jetbrains/teamcity-api'
import classNames from 'classnames'
import type {ComponentType, ComponentProps} from 'react'
import type {To} from 'react-router'
import {Link, useLocation} from 'react-router-dom'

import {getHrefWithQueryParams} from '../../../routes'
import type {QueryParams} from '../../../utils/queryParams'

import styles from './RouterLink.css'

type RouterLinkInnerProps = ClickableLinkProps &
  Omit<ComponentProps<typeof Link>, 'to'> & {
    to?: string | To
    params?: QueryParams | ((prevParams: QueryParams) => QueryParams)
    hash?: string
    activeClassName?: string | null
    withMergeParams?: boolean
  }
export type RouterLinkProps = LinkProps<RouterLinkInnerProps>

function RouterLink({
  to,
  params,
  hash,
  activeClassName,
  className,
  withMergeParams,
  onPlainLeftClick,
  ...restProps
}: RouterLinkInnerProps) {
  const location = useLocation()
  const paramsToPass =
    withMergeParams === true ? (prevParams: QueryParams) => ({...prevParams, ...params}) : params
  return (
    <Link
      className={classNames(className, styles.link)}
      to={
        (paramsToPass || hash != null) && (typeof to === 'string' || to == null)
          ? getHrefWithQueryParams(location, to, paramsToPass, hash)
          : (to ?? location)
      }
      {...restProps}
    />
  )
}
const RouterLinkContainer: ComponentType<RouterLinkProps> = linkHOC(RouterLink)
export default RouterLinkContainer
export const RouterLinkAPI = linkHOC(Link) as ComponentType<RouterLinkAPIProps>
type RouterButtonProps = RouterLinkInnerProps & {
  isActive?: boolean
  isLoading?: boolean
  text?: boolean
}
const buttonClassName = classNames(styles.button, buttonStyles.button, buttonStyles.heightS)
export const RouterButton: ComponentType<RouterButtonProps> = mapProps(
  ({isActive, isLoading, className, text, disabled, ...restProps}: RouterButtonProps) => ({
    ...restProps,
    disabled,
    className: classNames(className, buttonClassName, {
      [buttonStyles.active]: isActive,
      [buttonStyles.loader]: isLoading,
      [buttonStyles.text]: text,
      [styles.disabled]: disabled,
    }),
  }),
)(RouterLink)

export function RouterButtonAPI({className, children, ...restProps}: RouterButtonAPIProps) {
  return (
    <Link className={classNames(className, buttonClassName)} {...restProps}>
      {children}
    </Link>
  )
}
