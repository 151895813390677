import Link from '@jetbrains/ring-ui/components/link/link'
import type {ReactNode} from 'react'

import styles from './Integrations.css'

export enum IntegrationType {
  NPM = 'NpmRegistry',
  DOCKER = 'Docker',
  UNITY = 'Unity',
}

type IntegrationElements = {
  displayName: string
  note?: (parameters: Record<string, string | null>) => ReactNode
  defaultParameters: Record<string, string>
}

export const integrations: Record<string, IntegrationElements> = {
  [IntegrationType.NPM]: {
    displayName: 'NPM',
    note: parameters => {
      const url = parameters.npmRegistryHost
      const scope = parameters.npmRegistryScope
      return (
        <>
          {url && (
            <Link target="_blank" className={styles.link} href={url} rel="noreferrer">
              {url}
            </Link>
          )}
          {scope && ` as ${scope}`}
        </>
      )
    },
    defaultParameters: {
      npmRegistryHost: 'https://registry.npmjs.org',
      'secure:npmRegistryToken': '',
      npmRegistryScope: '',
    },
  },
  [IntegrationType.DOCKER]: {
    displayName: 'Docker',
    note: parameters => {
      const url = parameters.repositoryUrl
      return (
        url && (
          <Link target="_blank" className={styles.link} href={url} rel="noreferrer">
            {url}
          </Link>
        )
      )
    },
    defaultParameters: {repositoryUrl: 'https://docker.io', userName: '', 'secure:userPass': ''},
  },
  [IntegrationType.UNITY]: {
    displayName: 'Unity',
    note: parameters => parameters.username,
    defaultParameters: {
      unityLicenseType: 'professionalLicense',
      username: '',
      'secure:password': '',
      'secure:serialNumber': '',
    },
  },
}
