import {castDraft} from 'immer'

import type {GraphQLResolveInfo} from 'graphql/type'
import uniqBy from 'lodash/uniqBy'
import type {
  CacheConfig,
  GraphQLResponseWithData,
  PayloadData,
  RequestParameters,
  Variables,
} from 'relay-runtime'
import {Observable} from 'relay-runtime'

import {checkIfNonValidResponse} from '../actions/utils'
import {subscribeOnServiceWorkerMessage} from '../rest/caching/events'
import processResponse, {processErrors, processGraphQLErrors} from '../rest/processResponse'
import request from '../rest/request'
import {getEssentialHeader} from '../services/rest'
import {base_uri} from '../types/BS_types'
import {objectToQuery} from '../utils/queryParams'
import type {Unsubscribe} from '../utils/subscriber'
import {
  CACHE_GRAPHQL,
  SW_CACHING_HEADER_NAME,
  SW_FORCE_REVALIDATE_HEADER_NAME,
} from '../workers/sw.consts'

import type {Request, Field} from './rootValue'

const getRootValueImport = import(
  /* webpackChunkName: "GraphQL rootValue", webpackPrefetch: true */
  './rootValue'
)

const graphQLTypesImport = import(
  /* webpackChunkName: "GraphQL types", webpackPrefetch: true */
  'graphql/type'
)

const graphQLImport = import(
  /* webpackChunkName: "GraphQL", webpackPrefetch: true */
  'graphql/graphql'
)
const schemaPromise = Promise.all([
  import(
    /* webpackChunkName: "schema", webpackPrefetch: true */
    '../../schema.graphql'
  ),
  import(
    /* webpackChunkName: "GraphQL utilities", webpackPrefetch: true */
    'graphql/utilities'
  ),
]).then(([schemaImport, {buildSchema}]) => buildSchema(schemaImport.default))

const caseToDash: Record<string, string> = {
  parentGroups: 'parent-groups',
  childGroups: 'child-groups',
  beforeRevision: 'before-revision',
  afterRevision: 'after-revision',
  relativeFile: 'relative-file',
  vcsRootInstance: 'vcs-root-instance',
  checkoutRules: 'checkout-rules',
  sourceBuildType: 'source-buildType',
  artifactDependency: 'artifact-dependency',
  runningInfo: 'running-info',
  snapshotDependencies: 'snapshot-dependencies',
  artifactDependencies: 'artifact-dependencies',
  customArtifactDependencies: 'custom-artifact-dependencies',
  vcsRootId: 'vcs-root-id',
  vcsRoot: 'vcs-root',
  vcsRootEntry: 'vcs-root-entry',
  snapshotDependency: 'snapshot-dependency',
  agentRequirement: 'agent-requirement',
  vcsRootEntries: 'vcs-root-entries',
  agentRequirements: 'agent-requirements',
}

const getFieldName = (name: string) => (caseToDash[name] ? caseToDash[name] : name)

const fieldsResolver = (fields: Field[]) =>
  new Proxy(
    {},
    {
      get(_, name): ((_: unknown, __: unknown, info: GraphQLResolveInfo) => unknown) | undefined {
        if (name === '_id') {
          return () => {
            fields.push({name: 'id', children: []})
            return ''
          }
        }
        if (typeof name === 'string' && name !== 'then') {
          return async (args, ___, {returnType}) => {
            const {GraphQLList, GraphQLNonNull, GraphQLScalarType, GraphQLEnumType} =
              await graphQLTypesImport
            const children: Field[] = []
            const {locator} = args as {locator: string | undefined}
            if (locator != null) {
              children.push({name: `\$locator(${locator})`, children: []})
            }
            fields.push({name: getFieldName(name), children})
            if (returnType instanceof GraphQLScalarType || returnType instanceof GraphQLEnumType) {
              return null
            }
            if (
              returnType instanceof GraphQLNonNull &&
              returnType.ofType instanceof GraphQLScalarType
            ) {
              switch (returnType.ofType.name) {
                case 'ID':
                case 'String':
                  return ''
                case 'Int':
                  return 0
                case 'Boolean':
                  return false
                default:
                  return null
              }
            }
            const result = fieldsResolver(children)
            return returnType instanceof GraphQLList ||
              (returnType instanceof GraphQLNonNull && returnType.ofType instanceof GraphQLList)
              ? [result]
              : result
          }
        }
        return undefined
      },
    },
  )

const stringifyFields = (fields: Field[]): string =>
  uniqBy(fields, 'name')
    .map(field =>
      field.children.length > 0 ? `${field.name}(${stringifyFields(field.children)})` : field.name,
    )
    .join(',')

const isAnyType = (data: unknown): boolean =>
  data != null && typeof data === 'object' && 'pipelineBuildDirectoryStructure' in data

const resultResolver = (data: unknown): unknown => {
  if (isAnyType(data)) {
    return data
  }

  return Array.isArray(data)
    ? data.map(resultResolver)
    : data != null && typeof data === 'object'
      ? new Proxy(data, {
          get(target, name, receiver) {
            const id = Reflect.get(target, 'id', receiver)
            switch (name) {
              case '_id':
                return (_: unknown, __: unknown, {parentType}: GraphQLResolveInfo) =>
                  `${parentType.name}:${id}`
              case 'then':
                return Reflect.get(target, name, receiver)
              default:
                return (_: unknown, __: unknown, {path}: GraphQLResolveInfo) =>
                  resultResolver(Reflect.get(target, getFieldName(String(path.key)), receiver))
            }
          },
        })
      : data
}

const getEndpoint = ({url, params, fields}: Request) =>
  `${url}?${objectToQuery({
    ...params,
    fields: fields && stringifyFields(fields),
  })}`

async function execute(req: Request, data: PayloadData, isRefetch: boolean, essential: boolean) {
  const {name, method, input, text, emptyResponse, textBody, formData} = req
  const headers: Record<string, string> = {
    Accept: text ? 'text/plain' : 'application/json',
    ...getEssentialHeader(essential),
    ...req.headers,
  }
  if (formData == null) {
    headers['Content-Type'] = textBody != null ? 'text/plain' : 'application/json'
  }
  if (method == null || method === 'GET') {
    headers[SW_CACHING_HEADER_NAME] = CACHE_GRAPHQL
    if (isRefetch) {
      headers[SW_FORCE_REVALIDATE_HEADER_NAME] = 'true'
    }
  }

  const response = await request(base_uri, getEndpoint(req), {
    method,
    headers,
    body: formData ?? textBody ?? (input != null ? JSON.stringify(input) : null),
  })
  await processErrors(response)
  data[name] = await (text || emptyResponse ? response.text() : response.json())
}

export const fetchFn = (
  operation: RequestParameters,
  variables: Variables,
  cacheConfig: CacheConfig,
) =>
  Observable.create(sink => {
    const subsriptions: Unsubscribe[] = []
    let isCanceled = false
    let isNativeGraphQL = false
    const markAsNativeGraphQL = () => {
      isNativeGraphQL = true
    }
    const requests: Request[] = []

    async function executeQuery() {
      try {
        const [{graphql}, schema, getRootValue] = await Promise.all([
          graphQLImport,
          schemaPromise,
          getRootValueImport,
        ])
        const {errors} = await graphql({
          schema,
          source: operation.text!,
          variableValues: variables,
          rootValue: getRootValue.default(requests, markAsNativeGraphQL, fieldsResolver),
        })

        if (errors != null) {
          sink.next({errors: castDraft(errors)})
          sink.complete()

          return undefined
        }

        if (isNativeGraphQL) {
          const result = await request(base_uri, 'app/graphql', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: operation.text,
              variables,
            }),
          })
          const response: GraphQLResponseWithData | ReadonlyArray<GraphQLResponseWithData> =
            await processResponse(result)
          if (operation.operationKind === 'query') {
            processGraphQLErrors(response instanceof Array ? response[0] : response)
          }
          sink.next(response)
          sink.complete()

          return undefined
        }

        const data: PayloadData = {}
        const workerData: PayloadData = {}
        const workerMessages: Promise<void>[] = []
        let shouldUpdateFromWorker = false
        const {isRefetch, essential} = cacheConfig.metadata ?? {}

        await Promise.all(
          requests.map(req => {
            const promise = execute(req, data, Boolean(isRefetch), Boolean(essential))
            if (!isCanceled && !isRefetch && (req.method == null || req.method === 'GET')) {
              const workerMessage = new Promise<void>(resolve => {
                const unsubscribe = subscribeOnServiceWorkerMessage<unknown>({
                  url: `${base_uri}/${getEndpoint(req)}`,
                  handler: response => {
                    if (!checkIfNonValidResponse(response)) {
                      shouldUpdateFromWorker = true
                      workerData[req.name] = response.payload
                    }
                    resolve()
                  },
                })
                subsriptions.push(unsubscribe)
              })

              workerMessages.push(workerMessage)
            }
            return promise
          }),
        )

        const result = await graphql({
          schema,
          source: operation.text!,
          variableValues: variables,
          rootValue: resultResolver(data),
        })
        sink.next(result)

        await Promise.all(workerMessages)
        if (shouldUpdateFromWorker) {
          const workerResult = await graphql({
            schema,
            source: operation.text!,
            variableValues: variables,
            rootValue: resultResolver({...data, ...workerData}),
          })
          sink.next(workerResult)
        }

        sink.complete()
      } catch (error) {
        sink.next({errors: [error]})
        sink.complete()
      }

      return undefined
    }

    executeQuery()

    return () => {
      subsriptions.forEach(unsubscribe => unsubscribe())
      isCanceled = true
    }
  })
