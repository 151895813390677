/**
 * @generated SignedSource<<9bc87407624d757d1cc622e87ae91dda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectsPopupFederationServersQuery$variables = {
  skip?: boolean | null | undefined;
};
export type ProjectsPopupFederationServersQuery$data = {
  readonly federationServers?: {
    readonly " $fragmentSpreads": FragmentRefs<"projectTreesFederationServersFragment">;
  } | null | undefined;
};
export type ProjectsPopupFederationServersQuery = {
  response: ProjectsPopupFederationServersQuery$data;
  variables: ProjectsPopupFederationServersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "skip"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsPopupFederationServersQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FederationServers",
            "kind": "LinkedField",
            "name": "federationServers",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "projectTreesFederationServersFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectsPopupFederationServersQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FederationServers",
            "kind": "LinkedField",
            "name": "federationServers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FederationServer",
                "kind": "LinkedField",
                "name": "server",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a530d506db21a9bb764f2c922c60f421",
    "id": null,
    "metadata": {},
    "name": "ProjectsPopupFederationServersQuery",
    "operationKind": "query",
    "text": "query ProjectsPopupFederationServersQuery(\n  $skip: Boolean = false\n) {\n  federationServers @skip(if: $skip) {\n    ...projectTreesFederationServersFragment\n  }\n}\n\nfragment projectTreesFederationServersFragment on FederationServers {\n  server {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "219367a3e3fa210c61262f04df0c1d45";

export default node;
