import {isSpaceConnectionEnabled} from '../utils/featureToggles'

import {ROOT_PROJECT_ID} from 'src/types'
import {internalProps} from 'src/types/BS_types'

export const githubConnectionId = internalProps['teamcity.pipelines.gitHubConnectionId']
export const spaceConnectionId = internalProps['teamcity.pipelines.spaceConnectionId']
export const connectionId = isSpaceConnectionEnabled ? spaceConnectionId : githubConnectionId
export const GITHUB_REPOSITORIES_ENDPOINT = `/oauth/github/listRepositories.html?projectId=${ROOT_PROJECT_ID}&connectionId=${githubConnectionId}`
export const SPACE_REPOSITORIES_ENDPOINT = `/oauth/space/repositories.html?projectId=${ROOT_PROJECT_ID}&connectionId=${spaceConnectionId}&showMode=createProjectMenu&onlyUnconnected=false&content=embed`
