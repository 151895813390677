import {createAction} from '@reduxjs/toolkit'

import type {AppThunk} from '../../../actions/types'
import {getAgentPreviewsArg, getSingleAgentArg} from '../../../rest/agents'
import {getTabsArg} from '../../../rest/tabs'
import {restApi} from '../../../services/rest'
import type {AgentId, AgentRequestOptions} from '../../../types'
import {internalProps} from '../../../types/BS_types'
import {notNull} from '../../../utils/guards'
import {combineRefetchables, createRefetchableSubscription} from '../../../utils/refetchable'
import type {Unsubscribe} from '../../../utils/subscriber'
import {
  getTopics,
  subscribeOnAgentEvents,
  subscribeOnOverallEvents,
} from '../../../utils/subscriber'
import * as SubscriptionEvents from '../../../utils/subscriptionEvents'
import {getTabParamsKey} from '../../../utils/tabs'

const FREQUENT_AGENT_EVENT_TYPES = [
  SubscriptionEvents.AGENT_PARAMETERS_UPDATED,
  SubscriptionEvents.BUILD_STARTED,
  SubscriptionEvents.BUILD_FINISHED,
]
const AGENT_TYPE_EVENT_TYPES = [
  SubscriptionEvents.AGENT_REGISTERED,
  SubscriptionEvents.AGENT_REMOVED,
  SubscriptionEvents.AGENT_UNREGISTERED,
  SubscriptionEvents.AGENT_POOL_TOUCHED,
]
const REGULAR_AGENT_EVENT_TYPES = [
  ...AGENT_TYPE_EVENT_TYPES,
  SubscriptionEvents.AGENT_STATUS_CHANGED,
]
export const AGENT_EVENT_TYPES = [...FREQUENT_AGENT_EVENT_TYPES, ...REGULAR_AGENT_EVENT_TYPES]
export const FREQUENT_OVERALL_AGENT_EVENT_TOPICS = getTopics('', FREQUENT_AGENT_EVENT_TYPES)
export const REGULAR_OVERALL_AGENT_EVENT_TOPICS = getTopics('', REGULAR_AGENT_EVENT_TYPES)
export const OVERALL_AGENT_POOL_EVENT_TOPICS = getTopics('', [
  SubscriptionEvents.AGENT_POOL_TOUCHED,
])
export const OVERALL_PROJECT_EVENT_TOPICS = getTopics('', [
  SubscriptionEvents.PROJECT_RESTORED,
  SubscriptionEvents.PROJECT_PERSISTED,
  SubscriptionEvents.PROJECT_REMOVED,
  SubscriptionEvents.PROJECT_CREATED,
  SubscriptionEvents.PROJECT_ARCHIVED,
  SubscriptionEvents.PROJECT_DEARCHIVED,
  SubscriptionEvents.PROJECT_UPDATED,
])

const AGENT_PREVIEWS_UPDATE_TIMEOUT = Number(
  internalProps['teamcity.ui.agentPreviewsUpdateTimeout'],
)
const AGENT_DATA_UPDATE_TIMEOUT = Number(internalProps['teamcity.ui.agentDataUpdateTimeout'])
export const subscribeOnAgents = (): AppThunk<any> => dispatch => {
  const fetchAgentPreviewsData = () =>
    dispatch(restApi.endpoints.getAllAgentPreviewsNormalized.initiate(getAgentPreviewsArg()))
  const unsubscribeFromAgents = createRefetchableSubscription(fetchAgentPreviewsData, handler =>
    subscribeOnOverallEvents(
      REGULAR_OVERALL_AGENT_EVENT_TOPICS,
      handler,
      AGENT_DATA_UPDATE_TIMEOUT,
    ),
  )
  const unsubscribeFromFrequentAgentEvents = createRefetchableSubscription(
    fetchAgentPreviewsData,
    handler =>
      subscribeOnOverallEvents(
        FREQUENT_OVERALL_AGENT_EVENT_TOPICS,
        handler,
        AGENT_PREVIEWS_UPDATE_TIMEOUT,
      ),
  )
  return () => {
    unsubscribeFromAgents()
    unsubscribeFromFrequentAgentEvents()
  }
}

export const subscribeOnAgent =
  (agentId: AgentId, requestOptions: AgentRequestOptions = {}): AppThunk<Unsubscribe> =>
  dispatch =>
    createRefetchableSubscription(
      () =>
        combineRefetchables<unknown>(
          [
            dispatch(
              restApi.endpoints.getAgentNormalized.initiate(
                getSingleAgentArg(agentId, requestOptions),
              ),
            ),
            requestOptions?.tabs
              ? dispatch(
                  restApi.endpoints.getTabs.initiate(getTabsArg(getTabParamsKey({agentId}), false)),
                )
              : undefined,
          ].filter(notNull),
        ),
      handler =>
        subscribeOnAgentEvents(
          agentId,
          AGENT_EVENT_TYPES.filter(
            eventType =>
              ![SubscriptionEvents.AGENT_REMOVED, SubscriptionEvents.AGENT_UNREGISTERED].includes(
                eventType,
              ),
          ),
          handler,
        ),
    )

export const markAgentDeleted = createAction<AgentId>('markAgentDeleted')
