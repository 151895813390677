import type {IconType} from '@jetbrains/ring-ui/components/icon/icon'

import type {AgentId, AgentPoolId, AgentTypeId, BuildId, OSType} from '../../../../types'

export type AgentsTreeAgentNode = {
  id: AgentId
  poolId?: AgentPoolId
  type: AgentTreeItemType.AGENT
  title: string
  name: string
  authorized: boolean
  osType: OSType | null | undefined
  busy: boolean
  connected: boolean
  enabled: boolean
  buildId?: BuildId | null | undefined
  ip: string
  cloud: boolean
  matching?: boolean
  containsMatching?: never
  removable?: boolean
}
export type AgentsTreeCloudImageNode = {
  type: AgentTreeItemType.CLOUD_IMAGE
  agentTypeId: AgentTypeId
  agentTypeRawId: string
  title: string
  children: Array<AgentsTreeAgentNode>
  count: number
  busyCount: number
  disconnectedCount: number
  matching?: boolean
  containsMatching?: boolean
  osType: OSType | null | undefined
}
export type AgentsTreeAgentTypeNode = {
  type: AgentTreeItemType.AGENT_TYPE
  id: AgentTypeId
  rawId: string
  title: string
  children: Array<AgentsTreeAgentNode>
  count: number
  busyCount: number
  disconnectedCount: number
  matching?: boolean
  containsMatching?: boolean
  osType: OSType | null | undefined
}

export type AgentsTreeFolderTypeNode = {
  type: AgentTreeItemType.FOLDER
  id: string
  href?: string
  title: string
  children: Array<AgentsTreeAgentTypeNode | AgentsTreeFolderTypeNode | AgentsTreeAgentNode>
  count: number
  busyCount: number
  disconnectedCount: number
  matching?: boolean
  containsMatching?: boolean
}

export enum AgentTreeItemCategory {
  MAIN = 'Main',
  FAVORITE = 'Favorite',
}

export enum AgentTreeTitleType {
  FAVORITE = 'agent-pools-favorite-title',
  POOLS = 'agent-pools-all-title',
}

export enum AgentTreeItemType {
  TITLE = 'agents-tree-title',
  FAVORITE_LINK = 'agent-pools-favorite-tree-link',
  POOL = 'agent-pool',
  AGENT = 'agent',
  CLOUD_IMAGE = 'cloud-image',
  AGENT_TYPE = 'agent-type',
  SELECTION = 'agent-selection',
  FOLDER = 'folder',
}

export const UNAUTHORIZED_AGENTS_SELECTION_ID = 'unauthorized-agents'
export const DETACHED_AGENTS_SELECTION_ID = 'detached-agents'
export const POOLS_SELECTION_ID = 'pools'

export type AgentSelectionId = 'unauthorized-agents' | 'detached-agents' | 'pools' | 'all-agents'
export type AgentsTreeRootNodeChild =
  | AgentsTreeFolderTypeNode
  | AgentsTreeAgentTypeNode
  | AgentsTreeAgentNode
  | AgentsTreeAgentPoolNode
export type AgentsTreeAgentSelectionNode = {
  id: AgentSelectionId
  type: AgentTreeItemType.SELECTION
  title: string
  href?: string
  children: Array<AgentsTreeAgentNode | AgentsTreeAgentPoolNode | AgentsTreeFolderTypeNode>
  count: number
  containsMatching?: boolean
  disconnectedCount: number
}
export type AgentsTreeAgentPoolNode = {
  id?: AgentPoolId
  type: AgentTreeItemType.POOL
  title?: string
  children: Array<AgentsTreeAgentTypeNode | AgentsTreeAgentNode>
  count: number
  busyCount: number
  disconnectedCount: number
  matching?: boolean
  containsMatching?: boolean
}
export type AgentsTreeRootNode = AgentsTreeAgentSelectionNode | AgentsTreeAgentPoolNode
export type AgentsTree = ReadonlyArray<AgentsTreeRootNode>
export type AgentBranchId = AgentPoolId | AgentSelectionId | AgentTypeId | string
export type AgentBranch =
  | AgentTreeItemType.POOL
  | AgentTreeItemType.SELECTION
  | AgentTreeItemType.AGENT_TYPE
  | AgentTreeItemType.FOLDER

export type AgentsTreeNode = AgentsTreeRootNode | AgentsTreeRootNodeChild
type AgentsTreeItem = {
  key: string
  title?: string
  level: number
  height?: number
}
type AgentsTreeSelectableItem = AgentsTreeItem & {
  active: boolean
}

export type AgentsTreeItemTitle = AgentsTreeItem & {
  id: AgentTreeTitleType
  editable: boolean
  type: AgentTreeItemType.TITLE
}

type AgentPoolsFavoriteTreeItemLink = AgentsTreeSelectableItem & {
  id: string
  type: AgentTreeItemType.FAVORITE_LINK
  to: string
  disconnectedCount?: number
  focusable: boolean
}
export type AgentsTreeItemPool = AgentsTreeSelectableItem & {
  id?: AgentPoolId
  category: AgentTreeItemCategory
  type: AgentTreeItemType.POOL
  containsActive: boolean
  count: number
  busyCount: number
  disconnectedCount: number
  matching?: boolean
  focusable: boolean
  expandable: boolean
  expanded?: boolean
}
export type AgentsTreeItemSelection = AgentsTreeSelectableItem & {
  id: AgentSelectionId
  category: AgentTreeItemCategory
  type: AgentTreeItemType.SELECTION
  count: number
  disconnectedCount: number
  containsActive: boolean
  focusable: boolean
  expandable: boolean
  href?: string
  expanded?: boolean
}
export type AgentsTreeItemAgentType = AgentsTreeSelectableItem & {
  type: AgentTreeItemType.AGENT_TYPE
  id: AgentTypeId
  category: AgentTreeItemCategory
  count: number
  busyCount: number
  disconnectedCount: number
  containsActive: boolean
  osType: OSType | null | undefined
  matching?: boolean
  focusable: boolean
  expandable: boolean
  expanded?: boolean
  icon?: IconType | string | null | undefined
}
export type AgentsTreeItemFolder = AgentsTreeSelectableItem & {
  type: AgentTreeItemType.FOLDER
  id: string
  category: AgentTreeItemCategory
  count: number
  busyCount: number
  disconnectedCount: number
  containsActive: boolean
  matching?: boolean
  focusable: boolean
  expandable: boolean
  expanded?: boolean
  href?: string
}
export type AgentsTreeItemAgent = AgentsTreeSelectableItem & {
  name: string
  id: AgentId
  category: AgentTreeItemCategory
  type: AgentTreeItemType.AGENT
  authorized: boolean
  osType: OSType | null | undefined
  busy?: boolean
  connected?: boolean
  enabled?: boolean
  cloud: boolean
  matching?: boolean
  focusable: boolean
}
export type AgentsTreeItemType =
  | AgentsTreeItemTitle
  | AgentsTreeItemAgent
  | AgentsTreeItemAgentType
  | AgentsTreeItemPool
  | AgentsTreeItemSelection
  | AgentPoolsFavoriteTreeItemLink
  | AgentsTreeItemFolder
export type FlatAgentsTree = ReadonlyArray<AgentsTreeItemType>
export type ActiveItemFilter =
  | {
      id: AgentId
      type: AgentTreeItemType.AGENT
    }
  | {
      id?: AgentPoolId
      type: AgentTreeItemType.POOL
    }
  | {
      id: AgentTypeId
      type: AgentTreeItemType.AGENT_TYPE
    }
  | {
      id: AgentSelectionId
      type: AgentTreeItemType.SELECTION
    }
  | {
      id: string
      type: AgentTreeItemType.FAVORITE_LINK
    }
  | {
      id: string
      type: AgentTreeItemType.FOLDER
    }
export type AgentsRouteProps = {
  agentId?: AgentId | null | undefined
  agentTypeId?: AgentTypeId | null | undefined
  agentPoolId?: AgentPoolId | null | undefined
}
