import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {Step} from '../../types'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {deleteIfEmpty, getJob} from './EditPipelinePage.slices.utils'

export const setSteps: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    steps: Step[]
  }>
> = (state, action) => {
  const {id, jobId, steps} = action.payload
  const job = getJob(state, id, jobId)
  if (job != null) {
    job.steps = steps
  }
}

export const setStepParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; jobId: string; index: number; name: string; value: string | null}>
> = (state, action) => {
  const {id, jobId, index, name, value} = action.payload
  const step = getJob(state, id, jobId)?.steps?.[index]
  if (step != null) {
    if (value == null) {
      delete step[name]
    } else {
      step[name] = value
    }
  }
}

export const deleteStep: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    index: number
  }>
> = (state, action) => {
  const {id, jobId, index} = action.payload
  state[id] ??= {}
  state[id]!.deleted ??= {}
  state[id]!.deleted!.steps ??= {}
  state[id]!.deleted!.steps![jobId] ??= []
  state[id]!.deleted!.steps![jobId]!.push(index)
}

export const restoreStep: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    index: number
  }>
> = (state, action) => {
  const {id, jobId, index} = action.payload
  const deleted = state[id]?.deleted?.steps
  if (deleted?.[jobId]?.includes(index)) {
    deleted[jobId] = deleted[jobId].filter(i => i !== index)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    deleteIfEmpty(deleted, jobId) &&
      deleteIfEmpty(state[id]!.deleted, 'steps') &&
      deleteIfEmpty(state[id], 'deleted')
  }
}

export const reorderSteps: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; jobId: string; order: number[]}>
> = (state, action) => {
  const {id, jobId, order} = action.payload
  state[id] ??= {}
  state[id]!.reordered ??= {}
  state[id]!.reordered!.steps ??= {}
  state[id]!.reordered!.steps![jobId] = order
}
