/**
 * @generated SignedSource<<2f14f994ecf1ec4ad459db3a48a939ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvatarFragment$data = {
  readonly avatars: {
    readonly urlToSize20: string | null | undefined;
    readonly urlToSize28: string | null | undefined;
    readonly urlToSize32: string | null | undefined;
    readonly urlToSize40: string | null | undefined;
    readonly urlToSize56: string | null | undefined;
    readonly urlToSize64: string | null | undefined;
    readonly urlToSize80: string | null | undefined;
  } | null | undefined;
  readonly id: number | null | undefined;
  readonly name: string | null | undefined;
  readonly username: string | null | undefined;
  readonly " $fragmentType": "AvatarFragment";
};
export type AvatarFragment$key = {
  readonly " $data"?: AvatarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AvatarFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AvatarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAvatars",
      "kind": "LinkedField",
      "name": "avatars",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlToSize20",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlToSize28",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlToSize32",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlToSize40",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlToSize56",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlToSize64",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlToSize80",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ef6a6d840ba191736f31d16753fb07eb";

export default node;
