import commonPrefix from 'common-prefix'
import {createSelector} from 'reselect'

import type {State} from '../../../reducers/types'
import Routes, {getHref} from '../../../routes'
import {getAllAgentPreviews} from '../../../selectors'
import type {AgentPool} from '../../../services/rest'
import type {AgentId, NormalizedAgentPreview, AgentTypeId, OSType} from '../../../types'
import {stringifyId, toAgentPoolId, toAgentTypeId} from '../../../types'
import {emptyArray} from '../../../utils/empty'
import {objectValues} from '../../../utils/object'
import type {WritableKeyValue} from '../../../utils/object'

import type {AgentsPagesNativeAgentTypesQuery$data} from './__generated__/AgentsPagesNativeAgentTypesQuery.graphql'
import {
  AgentTreeItemType,
  DETACHED_AGENTS_SELECTION_ID,
  UNAUTHORIZED_AGENTS_SELECTION_ID,
} from './AgentsSidebar/AgentsSidebar.types'
import type {
  AgentSelectionId,
  AgentsTreeAgentNode,
  AgentsTreeAgentPoolNode,
  AgentsTreeAgentSelectionNode,
  AgentsTreeRootNode,
  AgentsTreeAgentTypeNode,
  AgentsTreeFolderTypeNode,
} from './AgentsSidebar/AgentsSidebar.types'

const UNAUTHORIZED_SELECTION_NAME = 'UNAUTHORIZED AGENTS'
const DETACHED_SELECTION_NAME = 'DETACHED AGENTS'
export const POOLS_SELECTION_NAME = 'ALL POOLS'

export const createAgentSelectionNode: (arg0: {
  id: AgentSelectionId
  name: string
  href?: string
}) => AgentsTreeAgentSelectionNode = ({id, name, href}) => ({
  id,
  title: name,
  href,
  type: AgentTreeItemType.SELECTION,
  children: [],
  count: 0,
  disconnectedCount: 0,
})

const createAgentPoolNode: (arg0: {
  id?: number
  name?: string
}) => AgentsTreeAgentPoolNode = pool => ({
  id: pool.id,
  type: AgentTreeItemType.POOL,
  title: pool.name,
  children: [],
  count: 0,
  busyCount: 0,
  disconnectedCount: 0,
})

export const createAgentNode: (
  arg0: NormalizedAgentPreview,
  arg1: {
    title?: string
    cloud?: boolean
  },
) => AgentsTreeAgentNode = (
  {id, name, connected, enabled = false, authorized = false, osType, ip = '', pool, ...rest},
  {title, cloud = false},
) => ({
  id,
  poolId: pool.id,
  title: title != null ? title : name,
  name,
  busy: 'build' in rest,
  buildId: rest.build,
  connected,
  enabled,
  authorized,
  osType,
  type: AgentTreeItemType.AGENT,
  cloud,
  ip,
})

type AgentType = {
  id: AgentTypeId
  name: string | undefined | null
  isCloud: boolean | undefined | null
  agentIds: Array<AgentId>
  agentPool: AgentPool
  osType: OSType
}

export type WritableAgentTypesHash = WritableKeyValue<AgentTypeId, AgentType>

export const getAgentIdListByAgentType = createSelector([getAllAgentPreviews], agentPreviewsHash =>
  objectValues(agentPreviewsHash).reduce<WritableKeyValue<AgentTypeId, Array<AgentId>>>(
    (result, agentPreview) => {
      if (agentPreview != null) {
        const {id, typeId} = agentPreview

        if (result[typeId] == null) {
          result[typeId] = [id]
        } else {
          result[typeId]?.push(id)
        }
      }

      return result
    },
    {},
  ),
)

const MISSING_AGENT_POOL_ID = -2

export const getAgentTypesHash = createSelector(
  [getAgentIdListByAgentType, (_: State, props: {pools: AgentPoolArray}) => props.pools],
  (agentTypeAgents, pools) => {
    const agentTypesHash: WritableAgentTypesHash = {}

    if (pools != null) {
      for (const pool of pools) {
        pool?.agentTypes?.agentType?.forEach(type => {
          if (type.id != null) {
            const id = toAgentTypeId(type.id)
            agentTypesHash[id] = {
              id,
              name: type.name,
              isCloud: type.isCloud,
              agentIds: agentTypeAgents[id] || (emptyArray as Array<AgentId>),
              agentPool: {
                id: toAgentPoolId(pool.id!),
                name: pool.name!,
              },
              osType: (type.environment?.osType ?? 'Unknown') as OSType,
            }
          }
        })
      }
    }

    return agentTypesHash
  },
)

export const getAgentSelectionPools = createSelector(
  [getAllAgentPreviews, getAgentTypesHash],
  (agentPreviewsHash, agentTypesHash) => {
    const unauth = createAgentSelectionNode({
      id: UNAUTHORIZED_AGENTS_SELECTION_ID,
      name: UNAUTHORIZED_SELECTION_NAME,
      href: getHref(Routes.AGENTS_UNAUTHORIZED, {}),
    })
    const detached = createAgentSelectionNode({
      id: DETACHED_AGENTS_SELECTION_ID,
      name: DETACHED_SELECTION_NAME,
    })

    objectValues(agentPreviewsHash).forEach(agentPreview => {
      if (agentPreview != null) {
        const isDetached = agentPreview.pool.id === MISSING_AGENT_POOL_ID

        if (isDetached || !agentPreview.authorized) {
          const agentType = agentTypesHash[agentPreview.typeId]

          const isCloud = agentType?.isCloud ?? false
          const agentNode = createAgentNode(agentPreview, {
            cloud: isCloud,
          })

          appendAgentNode(agentPreview.authorized ? detached : unauth, agentNode)
        }
      }
    })

    return {
      unauth,
      detached,
    }
  },
)

type AgentTypeItem = {
  environment?: {
    osName?: string | null
    osType?: string | null
  } | null
  isCloud?: boolean | null
  name?: string | null
  id?: number | null
  configurationParameters?: {
    property?: ReadonlyArray<{
      name?: string | null
      value?: string | null
    }> | null
  } | null
}

type AgentPoolItem = {
  agentTypes?: {
    agentType?: ReadonlyArray<AgentTypeItem> | null
  } | null
  name?: string | null
  id?: number | null
}
export type AgentPoolArray = readonly AgentPoolItem[]

export const getAgentPoolsFromNative = createSelector(
  [
    (props: {nativeAgentTypesQueryResponse: AgentsPagesNativeAgentTypesQuery$data}) =>
      props.nativeAgentTypesQueryResponse,
  ],
  nativeAgentTypesQueryResponse => {
    const agentPoolHash: Record<string, AgentPoolItem> = {}
    const edges = nativeAgentTypesQueryResponse.agentTypes?.edges ?? []
    edges.forEach(({node}) => {
      let pool = agentPoolHash[node.agentPool.rawId]
      if (pool == null) {
        agentPoolHash[node.agentPool.rawId] = {
          name: node.agentPool.name,
          id: Number(node.agentPool.rawId),
          agentTypes: {
            agentType: [],
          },
        }
      }

      pool = agentPoolHash[node.agentPool.rawId]
      const {agentTypes} = pool ?? {}

      if (agentTypes?.agentType != null) {
        agentTypes.agentType = agentTypes.agentType.concat({
          id: Number(node.rawId),
          name: node.name,
          environment:
            node.environment?.os != null
              ? {
                  osType: node.environment.os.type,
                  osName: node.environment.os.type,
                }
              : null,
          isCloud: node.isCloud,
        })
      }
    })

    return Object.values(agentPoolHash)
  },
)

export const getAgentPoolsTree = createSelector(
  [
    getAllAgentPreviews,
    getAgentIdListByAgentType,
    (_: State, props: {pools: AgentPoolArray}) => props.pools,
  ],
  (agentPreviewsHash, agentIdListByAgentType, pools) => {
    const tree: AgentsTreeAgentPoolNode[] = []

    pools?.forEach(pool => {
      const poolId = pool.id

      if (poolId != null && pool.name != null) {
        const poolNode = createAgentPoolNode({
          id: poolId,
          name: pool.name,
        })

        tree.push(poolNode)

        const agentTypes = pool.agentTypes?.agentType ?? []

        agentTypes.forEach(agentType => {
          const agentTypeId = agentType.id
          const agentTypeName = agentType.name

          if (agentTypeId == null || agentTypeName == null) {
            return
          }

          function appendAgentList(props: {
            branch: AgentsTreeRootNode | AgentsTreeAgentTypeNode
            agentTypeIdToAdd: number
            cloud: boolean
          }) {
            const {branch, agentTypeIdToAdd, cloud} = props
            const agentList = agentIdListByAgentType[agentTypeIdToAdd] ?? []

            agentList.forEach(agentId => {
              const agent = agentPreviewsHash[agentId]
              if (agent == null) {
                return
              }
              if (!agent.authorized) {
                return
              }

              const agentNode = createAgentNode(agent, {
                title:
                  cloud && branch.title != null
                    ? agent.name.replace(
                        RegExp(`^${commonPrefix([branch.title, agent.name])}-?`),
                        '',
                      )
                    : undefined,
                cloud,
              })

              appendAgentNode(branch, agentNode)
            })
          }

          if (agentType.isCloud) {
            const agentTypeNode = createAgentTypeNode(
              agentTypeId,
              agentTypeName,
              (agentType.environment?.osType ?? 'Unknown') as OSType,
            )
            appendAgentList({
              branch: agentTypeNode,
              agentTypeIdToAdd: agentTypeId,
              cloud: true,
            })
            appendAgentTypeNode(poolNode, agentTypeNode)
          } else {
            appendAgentList({
              branch: poolNode,
              agentTypeIdToAdd: agentTypeId,
              cloud: false,
            })
          }
        })
      }
    })

    return tree
  },
)

export const getFavoriteAgentTree = createSelector(
  [getAgentPoolsTree, (_: State, props: {favoritePools: Array<number>}) => props.favoritePools],
  (pools, favoritePools) =>
    pools.filter(pool => pool.id != null && favoritePools.includes(pool.id)),
)

export function createAgentTypeNode(
  id: AgentTypeId,
  name: string,
  osType: OSType,
): AgentsTreeAgentTypeNode {
  return {
    id,
    rawId: stringifyId(id),
    title: name,
    osType,
    type: AgentTreeItemType.AGENT_TYPE,
    children: [],
    count: 0,
    busyCount: 0,
    disconnectedCount: 0,
  }
}

export function createFolderNode(id: string, title: string): AgentsTreeFolderTypeNode {
  return {
    id,
    title,
    type: AgentTreeItemType.FOLDER,
    children: [],
    count: 0,
    busyCount: 0,
    disconnectedCount: 0,
  }
}

export function appendAgentNode(
  branch: AgentsTreeRootNode | AgentsTreeAgentTypeNode | AgentsTreeFolderTypeNode,
  child: AgentsTreeAgentNode,
) {
  branch.children.push(child)

  branch.count += 1

  if (branch.type === AgentTreeItemType.POOL || branch.type === AgentTreeItemType.AGENT_TYPE) {
    branch.busyCount += child.buildId ? 1 : 0
  }
  branch.disconnectedCount += child.connected ? 0 : 1
}

function appendAgentTypeNode(branch: AgentsTreeAgentPoolNode, child: AgentsTreeAgentTypeNode) {
  branch.children.push(child)

  branch.count += child.count

  if (branch.type === AgentTreeItemType.POOL) {
    branch.busyCount += child.busyCount
  }

  branch.disconnectedCount += child.disconnectedCount
}
