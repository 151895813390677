import {useEffect, useRef} from 'react'
import type {EntryPoint, EntryPointComponent} from 'react-relay'
import {EntryPointContainer, loadEntryPoint} from 'react-relay'
import type {PreloadedEntryPoint} from 'react-relay/relay-hooks/EntryPointTypes'
import type {LoaderFunctionArgs, RouteObject} from 'react-router'
import {useLoaderData} from 'react-router'
import type {IEnvironment, OperationType} from 'relay-runtime'

export type EntryPointRoute<
  Q extends Record<string, OperationType>,
  EP extends Record<string, EntryPoint<any, any> | undefined>,
> = RouteObject & {
  entryPoint?: EntryPoint<EntryPointComponent<Q, EP>, LoaderFunctionArgs>
}

export const entryPointRoute = <
  Q extends Record<string, OperationType>,
  EP extends Record<string, EntryPoint<any, any> | undefined>,
>(
  {entryPoint, ...route}: EntryPointRoute<Q, EP>,
  environment?: IEnvironment,
): RouteObject => {
  const result: RouteObject =
    entryPoint != null
      ? {
          ...route,
          loader:
            environment &&
            (arg => loadEntryPoint({getEnvironment: () => environment}, entryPoint, arg)),
          Component: () => {
            const entryPointReference = useLoaderData() as PreloadedEntryPoint<
              EntryPointComponent<Q, EP>
            >

            const disposeTimeout = useRef<number>()
            useEffect(() => {
              if (disposeTimeout.current != null) {
                window.clearTimeout(disposeTimeout.current)
              }

              return () => {
                disposeTimeout.current = window.setTimeout(() => entryPointReference.dispose(), 0)
              }
            }, [entryPointReference])
            return <EntryPointContainer entryPointReference={entryPointReference} props={{}} />
          },
        }
      : route
  if (route.children != null) {
    result.children = route.children.map(child => entryPointRoute(child, environment))
  }
  return result
}
