/**
 * @generated SignedSource<<328ad968cbd36c4ec755fa71d34ee470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentTypePipelinePageQuery$variables = {
  locator: string;
};
export type AgentTypePipelinePageQuery$data = {
  readonly agentType: {
    readonly " $fragmentSpreads": FragmentRefs<"AgentTypePipelinePage_agentType">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AgentsPagesCanViewAgentDetailsFragment">;
};
export type AgentTypePipelinePageQuery = {
  response: AgentTypePipelinePageQuery$data;
  variables: AgentTypePipelinePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentTypeLocator",
    "variableName": "locator"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentTypePipelinePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AgentsPagesCanViewAgentDetailsFragment"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentTypeRest",
        "kind": "LinkedField",
        "name": "agentType",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AgentTypePipelinePage_agentType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentTypePipelinePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentTypeRest",
        "kind": "LinkedField",
        "name": "agentType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Environment",
            "kind": "LinkedField",
            "name": "environment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "osType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "osName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e05ff10dcd33ca208393112cd64f2ed9",
    "id": null,
    "metadata": {},
    "name": "AgentTypePipelinePageQuery",
    "operationKind": "query",
    "text": "query AgentTypePipelinePageQuery(\n  $locator: String!\n) {\n  ...AgentsPagesCanViewAgentDetailsFragment\n  agentType(agentTypeLocator: $locator) {\n    ...AgentTypePipelinePage_agentType\n    _id\n  }\n}\n\nfragment AgentTypePipelinePage_agentType on AgentTypeRest {\n  name\n  environment {\n    osType\n    osName\n  }\n}\n\nfragment AgentsPagesCanViewAgentDetailsFragment on Query {\n  agentPoolsRest {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "bcf465649ff60ca0b7bb73458e3255de";

export default node;
