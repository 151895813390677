import {createSelector} from 'reselect'

import type {State} from '../../../../../reducers/types'

export const getProjectsSidebarStateToPersist: (state: State) => {
  searchQuery: string
  searchActive: boolean
  showArchivedProjects: boolean
} = createSelector(
  (state: State) => state.projectsSidebar,
  ({activeItem, ...sidebar}) => ({...sidebar, allProjectsCollapsed: true}),
)
