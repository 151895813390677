import {combineReducers} from 'redux'

import {pipelinesSidebar} from '../../App/SidebarPanel/SidebarPanelContent/PipelinesSidebar/PipelinesSidebar.reducers'

import {feedbackForm} from './components/Feedback/Feedback.slice'
import {
  collapsedBlocks,
  hoveredJob,
  pipelineDraft,
  pipelineDraftForm,
  pipelineErrors,
  pipelineForm,
  pipelineSecrets,
  pipelineYaml,
  pipelineYamlValidity,
  suggestions,
} from './EditPipelinePage/slices/EditPipelinePage.slices'

export const pipelines = combineReducers({
  collapsedBlocks: collapsedBlocks.reducer,
  hoveredJob: hoveredJob.reducer,
  pipelineDraft: pipelineDraft.reducer,
  pipelineDraftForm: pipelineDraftForm.reducer,
  pipelineYamlDraft: pipelineYaml.reducer,
  pipelineYamlValidity: pipelineYamlValidity.reducer,
  pipelineForm: pipelineForm.reducer,
  feedbackForm: feedbackForm.reducer,
  suggestions: suggestions.reducer,
  pipelinesSidebar,
  pipelineErrors: pipelineErrors.reducer,
  pipelineSecrets: pipelineSecrets.reducer,
})
