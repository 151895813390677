/**
 * @generated SignedSource<<2f88fd5f852293051510c87bd209c7dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type currentUserKeyFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AllUserSelectFragment" | "HeaderUserFragment" | "useMyIdFragment" | "useMyProfileFragment" | "useMyUsernameFragment" | "useUserPropertyFragment">;
  readonly " $fragmentType": "currentUserKeyFragment";
};
export type currentUserKeyFragment$key = {
  readonly " $data"?: currentUserKeyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"currentUserKeyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "currentUserKeyFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMyIdFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMyUsernameFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useUserPropertyFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMyProfileFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderUserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AllUserSelectFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f640cbd4b5856ca1b6497433672f4629";

export default node;
