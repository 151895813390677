type GenerateFreeNameParams = {
  maxIterations?: number
  getName: (iteration: number) => string
  usedNames: (string | null)[]
  errorMessage: string
}

const DEFAULT_MAX_ITERATIONS = 1000
export function generateFreeName({
  maxIterations = DEFAULT_MAX_ITERATIONS,
  getName,
  usedNames,
  errorMessage,
}: GenerateFreeNameParams) {
  for (let i = 0; i < maxIterations; i++) {
    const name = getName(i)
    if (!usedNames.includes(name)) {
      return name
    }
  }
  throw new Error(errorMessage)
}
