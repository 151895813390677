import {graphql} from 'react-relay'

export const commiterSelectQuery = graphql`
  query CommiterSelectQuery($changeLocator: String!) {
    changeCommiters(changeLocator: $changeLocator) {
      commiter {
        vcsUsername
        users {
          user {
            id
            name
            avatars {
              urlToSize40
            }
          }
        }
      }
    }
  }
`
