import {objectEntries} from '../../../../utils/object'
import type {PipelineError} from '../EditPipelinePage/slices/EditPipelinePage.slices'

import type {
  GetValidatorErrorMessageProps,
  GetValidationErrorMessageProps,
} from './validators.types'

export const isValueNotEmptyMessage = ({value}: GetValidatorErrorMessageProps) => Boolean(value)
export const isValueNotEmptyString = ({value}: GetValidatorErrorMessageProps) => value !== ''

export const isValueArrayNotDuplicate = ({
  compareArray = [],
  value,
}: GetValidatorErrorMessageProps) =>
  !compareArray?.length ||
  (Boolean(value) && compareArray.filter(item => item === value).length <= 1)

export const isValueRelatedArrayNotDuplicate = ({
  relatedArray = [],
  value,
}: GetValidatorErrorMessageProps) =>
  !relatedArray?.length || (Boolean(value) && !relatedArray.some(item => item === value))

export const isValueNotLong =
  (maxLength: number) =>
  ({value}: GetValidatorErrorMessageProps) =>
    Boolean(value && value.length < maxLength)

export const isValueNotValidSymbol =
  (regExp: RegExp) =>
  ({value = ''}: GetValidatorErrorMessageProps) =>
    regExp.test(value)

export const getValidationErrorMessage = ({
  validators,
  disabled,
  ...props
}: GetValidationErrorMessageProps): string => {
  for (const {validator, message} of validators) {
    if (disabled) {
      continue
    }
    const isValid = validator(props)

    if (!isValid) {
      return message
    }
  }

  return ''
}

export const matchError = (filter: PipelineError) => (error: PipelineError) =>
  objectEntries(filter).every(([key, value]) => value == null || filter[key] === error[key])
