import {forEach} from './array'
import {throttle} from './throttle'
import {getDuration} from './time'

export const removeNode = (node: Node | null) => node?.parentNode?.removeChild(node)

const MAX_ATTEMPTS_COUNT = 10
const RETRY_TIMEOUT = getDuration({seconds: 2})
const ATTEMPTS_INTERVAL = getDuration({seconds: 10})

export function watchForNodePosition<T extends Node>(
  node: T,
  mode: 'head' | 'prev-sibling',
  onRestore = Function.prototype,
) {
  const prevSibling = node.previousSibling
  let parent = node.parentNode
  if (!parent) {
    throw new Error('Unable to watch for node position: parent element not found')
  }
  if (mode === 'prev-sibling' && !prevSibling) {
    throw new Error('Unable to watch for node position: there is no previous sibling')
  }
  let attempts = 0
  let start: number | null = null
  let timeoutId: ReturnType<typeof setTimeout> | null = null
  const restore = throttle(() => {
    if (timeoutId) {
      return
    }
    attempts++
    const now = Date.now()
    if (start == null) {
      start = now
    } else if (attempts >= MAX_ATTEMPTS_COUNT) {
      if (now - start < ATTEMPTS_INTERVAL) {
        timeoutId = setTimeout(() => {
          start = null
          attempts = 0
          timeoutId = null
          restore()
        }, RETRY_TIMEOUT)
        return
      }
      start = now
      attempts = 1
    }

    if (mode === 'head') {
      if (prevSibling && prevSibling.parentNode !== parent) {
        stop()
        return
      }
    }

    if (mode === 'prev-sibling') {
      if (prevSibling!.parentNode == null) {
        stop()
        return
      }
      if (prevSibling!.parentNode !== parent) {
        updateParent(prevSibling!.parentNode)
      }
    }

    if (mode === 'head' && !parent!.isConnected) {
      parent = document.head
    }

    parent!.insertBefore(
      node,
      prevSibling?.isConnected ? prevSibling.nextSibling : parent!.firstChild,
    )
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    observer.takeRecords()
    onRestore?.()
  })

  const observer = new MutationObserver(() => {
    if (
      (mode === 'head' && (node.parentNode !== parent || !node.parentNode!.isConnected)) ||
      (mode === 'prev-sibling' && node.previousSibling !== prevSibling)
    ) {
      restore()
    }
  })

  function run() {
    observer.observe(parent as ParentNode, {childList: true})
  }

  function stop() {
    if (timeoutId != null) {
      clearTimeout(timeoutId)
    }
    observer.disconnect()
    restore.cancel()
  }

  function skip() {
    observer.takeRecords()
  }

  function updateParent(parentNode: (Node & ParentNode) | null) {
    parent = parentNode
    stop()
    run()
  }

  run()
  return {run, stop, skip}
}

export function iterateShadowHosts(root: Node | null, iterator: (host: Element) => void) {
  if (root == null) {
    return
  }
  const walker = document.createTreeWalker(root, NodeFilter.SHOW_ELEMENT, {
    acceptNode(node) {
      return (node as Element).shadowRoot == null
        ? NodeFilter.FILTER_SKIP
        : NodeFilter.FILTER_ACCEPT
    },
  })
  for (
    let node = ((root as Element).shadowRoot ? walker.currentNode : walker.nextNode()) as Element;
    node != null;
    node = walker.nextNode() as Element
  ) {
    iterator(node)
    iterateShadowHosts(node.shadowRoot, iterator)
  }
}

export const isDOMReady = () =>
  document.readyState === 'complete' || document.readyState === 'interactive'

const readyStateListeners = new Set<() => void>()

export function addDOMReadyListener(listener: () => void) {
  if (isDOMReady()) {
    listener()
  } else {
    readyStateListeners.add(listener)
  }
}

export function removeDOMReadyListener(listener: () => void) {
  readyStateListeners.delete(listener)
}

export function isReadyStateComplete() {
  return document.readyState === 'complete'
}

const readyStateCompleteListeners = new Set<() => void>()

export function addReadyStateCompleteListener(listener: () => void) {
  if (isReadyStateComplete()) {
    listener()
  } else {
    readyStateCompleteListeners.add(listener)
  }
}

export function cleanReadyStateCompleteListeners() {
  readyStateCompleteListeners.clear()
}

if (!isDOMReady()) {
  const onReadyStateChange = () => {
    if (isDOMReady()) {
      readyStateListeners.forEach(listener => listener())
      readyStateListeners.clear()
      if (isReadyStateComplete()) {
        document.removeEventListener('readystatechange', onReadyStateChange)
        readyStateCompleteListeners.forEach(listener => listener())
        readyStateCompleteListeners.clear()
      }
    }
  }

  document.addEventListener('readystatechange', onReadyStateChange)
}

const HUGE_MUTATIONS_COUNT = 1000

function isHugeMutation(mutations: MutationRecord[]) {
  if (mutations.length > HUGE_MUTATIONS_COUNT) {
    return true
  }

  let addedNodesCount = 0
  for (let i = 0; i < mutations.length; i++) {
    addedNodesCount += mutations[i].addedNodes.length
    if (addedNodesCount > HUGE_MUTATIONS_COUNT) {
      return true
    }
  }

  return false
}

export interface ElementsTreeOperations {
  additions: Set<Element>
  moves: Set<Element>
  deletions: Set<Element>
}

function getElementsTreeOperations(mutations: MutationRecord[]): ElementsTreeOperations {
  const additions = new Set<Element>()
  const deletions = new Set<Element>()
  const moves = new Set<Element>()
  mutations.forEach(m => {
    forEach(m.addedNodes, n => {
      if (n instanceof Element && n.isConnected) {
        additions.add(n)
      }
    })
    forEach(m.removedNodes, n => {
      if (n instanceof Element) {
        if (n.isConnected) {
          moves.add(n)
          additions.delete(n)
        } else {
          deletions.add(n)
        }
      }
    })
  })

  const duplicateAdditions: Element[] = []
  const duplicateDeletions: Element[] = []
  additions.forEach(node => {
    if (additions.has(node.parentElement as HTMLElement)) {
      duplicateAdditions.push(node)
    }
  })
  deletions.forEach(node => {
    if (deletions.has(node.parentElement as HTMLElement)) {
      duplicateDeletions.push(node)
    }
  })
  duplicateAdditions.forEach(node => additions.delete(node))
  duplicateDeletions.forEach(node => deletions.delete(node))

  return {additions, moves, deletions}
}

interface OptimizedTreeObserverCallbacks {
  onMinorMutations: (operations: ElementsTreeOperations) => void
  onHugeMutations: (root: Document | ShadowRoot) => void
}

const optimizedTreeObservers = new Map<Node, MutationObserver>()
const optimizedTreeCallbacks = new WeakMap<MutationObserver, Set<OptimizedTreeObserverCallbacks>>()

export function createOptimizedTreeObserver(
  root: Document | ShadowRoot,
  callbacks: OptimizedTreeObserverCallbacks,
) {
  let observer: MutationObserver
  let observerCallbacks: Set<OptimizedTreeObserverCallbacks>
  let domReadyListener: () => void

  if (optimizedTreeObservers.has(root)) {
    observer = optimizedTreeObservers.get(root)!
    observerCallbacks = optimizedTreeCallbacks.get(observer)!
  } else {
    let hadHugeMutationsBefore = false
    let subscribedForReadyState = false

    observer = new MutationObserver((mutations: MutationRecord[]) => {
      if (isHugeMutation(mutations)) {
        if (!hadHugeMutationsBefore || isDOMReady()) {
          observerCallbacks.forEach(({onHugeMutations}) => onHugeMutations(root))
        } else if (!subscribedForReadyState) {
          domReadyListener = () =>
            observerCallbacks.forEach(({onHugeMutations}) => onHugeMutations(root))
          addDOMReadyListener(domReadyListener)
          subscribedForReadyState = true
        }
        hadHugeMutationsBefore = true
      } else {
        const elementsOperations = getElementsTreeOperations(mutations)
        observerCallbacks.forEach(({onMinorMutations}) => onMinorMutations(elementsOperations))
      }
    })
    observer.observe(root, {childList: true, subtree: true})
    optimizedTreeObservers.set(root, observer)
    observerCallbacks = new Set()
    optimizedTreeCallbacks.set(observer, observerCallbacks)
  }

  observerCallbacks.add(callbacks)

  return {
    disconnect() {
      observerCallbacks.delete(callbacks)
      if (domReadyListener) {
        removeDOMReadyListener(domReadyListener)
      }
      if (observerCallbacks.size === 0) {
        observer.disconnect()
        optimizedTreeCallbacks.delete(observer)
        optimizedTreeObservers.delete(root)
      }
    },
  }
}

export const checkIfElementIgnoredByDarkThemeAdapter = (element: HTMLElement): boolean =>
  element.dataset.ignoreDarkThemeAdapter != null

// TODO: write an algorithm to exclude ignoreDarkThemeAdapter subtree completely
export function checkIfParentIgnoresAdapter(element: HTMLElement): boolean {
  let current = element
  while (current.parentElement != null) {
    if (checkIfElementIgnoredByDarkThemeAdapter(current)) {
      return true
    }
    current = current.parentElement
  }
  return false
}
