import * as React from 'react'
import type {
  MeasureProps,
  MeasuredComponentProps,
  ContentRect,
  MeasurementType,
} from 'react-measure'
import getContentRect from 'react-measure/src/get-content-rect'
import getTypes from 'react-measure/src/get-types'

import {useSandbox} from '../utils/fastdom'

import useComposeRefs from './useComposeRefs'
import useLazy from './useLazy'

type MeasureSelectorResult<T> = {
  measureRef: React.RefCallback<Element>
  measure: (entries?: ResizeObserverEntry[]) => unknown
  result: T
}

export function useMeasureSelector<T>(
  props: MeasureProps,
  selector: (rect: ContentRect, state: T) => T,
  defaultValue: T,
): MeasureSelectorResult<T> {
  const {innerRef, onResize} = props
  const [result, updateResult] = React.useReducer(
    (state: T, rect: ContentRect) => selector(rect, state),
    defaultValue,
  )
  const ref = React.useRef<HTMLElement>()
  const sandbox = useSandbox()
  const joinedTypes = getTypes(props).join(',')
  const measure = React.useCallback(
    (entries?: ResizeObserverEntry[]) => {
      sandbox.measure(() => {
        const element = ref.current
        if (element == null) {
          return
        }

        const measurements = getContentRect(element, joinedTypes.split(',') as MeasurementType[])

        if (entries) {
          measurements.entry = entries[0].contentRect
        }

        sandbox.mutate(() => {
          updateResult(measurements)

          if (typeof onResize === 'function') {
            onResize(measurements)
          }
        })
      })
    },
    [onResize, joinedTypes, sandbox],
  )
  const getObserver = useLazy(() => new ResizeObserver(measure), [measure])
  const composeRefs = useComposeRefs()
  const measureRef = React.useMemo(
    () =>
      composeRefs(innerRef, node => {
        const prevNode = ref.current
        if (prevNode === node) {
          return
        }

        const observer = getObserver()

        if (prevNode != null) {
          observer.unobserve(prevNode)
        }

        if (node != null && node instanceof HTMLElement) {
          ref.current = node
          observer.observe(node)
        } else {
          observer.disconnect()
        }
      }),
    [composeRefs, getObserver, innerRef],
  )

  return {
    result,
    measure,
    measureRef,
  }
}

export default function useMeasure(props: MeasureProps): MeasuredComponentProps {
  const {measure, measureRef, result} = useMeasureSelector(props, contentRect => contentRect, {
    entry: {},
    client: {},
    offset: {},
    scroll: {},
    bounds: {},
    margin: {},
  } as ContentRect)
  return {
    contentRect: result,
    measure,
    measureRef,
  }
}
