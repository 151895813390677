import {
  isValueNotEmptyMessage,
  isValueArrayNotDuplicate,
} from 'src/components/pages/PipelinesPages/utils/validators'

export const jobNameValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: 'Job name should not be empty',
  },
  {
    validator: isValueArrayNotDuplicate,
    message: 'This job name already exists',
  },
]
