import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

export type NodeInfo = {
  serverNodes?: Array<string>
  currentNode?: string
}

const initialState: NodeInfo = {}

export const nodeInfo = createSlice({
  name: 'nodeInfo',
  initialState,
  reducers: {
    set: (_, action: PayloadAction<NodeInfo>) => action.payload,
  },
})
