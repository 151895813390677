/**
 * @generated SignedSource<<a72f143677aca1c0c4733ac36c826f90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditFederationQuery$variables = Record<PropertyKey, never>;
export type EditFederationQuery$data = {
  readonly federationServers: {
    readonly " $fragmentSpreads": FragmentRefs<"projectTreesFederationServersFragment">;
  } | null | undefined;
};
export type EditFederationQuery = {
  response: EditFederationQuery$data;
  variables: EditFederationQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditFederationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FederationServers",
        "kind": "LinkedField",
        "name": "federationServers",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "projectTreesFederationServersFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EditFederationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FederationServers",
        "kind": "LinkedField",
        "name": "federationServers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FederationServer",
            "kind": "LinkedField",
            "name": "server",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63fb221d25d583550ac49d5e8af58563",
    "id": null,
    "metadata": {},
    "name": "EditFederationQuery",
    "operationKind": "query",
    "text": "query EditFederationQuery {\n  federationServers {\n    ...projectTreesFederationServersFragment\n  }\n}\n\nfragment projectTreesFederationServersFragment on FederationServers {\n  server {\n    url\n  }\n}\n"
  }
};

(node as any).hash = "75b24b53ca86e13f00b68ae5c241cff0";

export default node;
