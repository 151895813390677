/**
 * @generated SignedSource<<f9f2f78af39b059821bfe7a05e0ca6dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentPipelinePageQuery$variables = {
  locator: string;
};
export type AgentPipelinePageQuery$data = {
  readonly agentRest: {
    readonly id: number | null | undefined;
    readonly name: string | null | undefined;
    readonly properties: {
      readonly property: ReadonlyArray<{
        readonly name: string | null | undefined;
        readonly value: string | null | undefined;
      }> | null | undefined;
    } | null | undefined;
    readonly typeId: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"AgentPipelinePage_agentRest">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AgentsPagesCanViewAgentDetailsFragment">;
};
export type AgentPipelinePageQuery = {
  response: AgentPipelinePageQuery$data;
  variables: AgentPipelinePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentLocator",
    "variableName": "locator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "locator",
      "value": "name:(value:(teamcity.agent.jbHosted),matchType:equals)"
    }
  ],
  "concreteType": "Properties",
  "kind": "LinkedField",
  "name": "properties",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "properties(locator:\"name:(value:(teamcity.agent.jbHosted),matchType:equals)\")"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Comment",
    "kind": "LinkedField",
    "name": "comment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timestamp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v8 = [
  (v2/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentPipelinePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AgentsPagesCanViewAgentDetailsFragment"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentRest",
        "kind": "LinkedField",
        "name": "agentRest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AgentPipelinePage_agentRest"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentPipelinePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentRest",
        "kind": "LinkedField",
        "name": "agentRest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EnabledInfo",
            "kind": "LinkedField",
            "name": "enabledInfo",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "connected",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "connectedSince",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disconnectionComment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastActivityTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorizedInfo",
            "kind": "LinkedField",
            "name": "authorizedInfo",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "port",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ip",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Environment",
            "kind": "LinkedField",
            "name": "environment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "osName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "osType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "pool",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CloudInstance",
            "kind": "LinkedField",
            "name": "cloudInstance",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cpuRank",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "host",
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3bfe1041884032d17401b992171a518e",
    "id": null,
    "metadata": {},
    "name": "AgentPipelinePageQuery",
    "operationKind": "query",
    "text": "query AgentPipelinePageQuery(\n  $locator: String!\n) {\n  ...AgentsPagesCanViewAgentDetailsFragment\n  agentRest(agentLocator: $locator) {\n    id\n    name\n    typeId\n    ...AgentPipelinePage_agentRest\n    properties(locator: \"name:(value:(teamcity.agent.jbHosted),matchType:equals)\") {\n      property {\n        name\n        value\n      }\n    }\n    _id\n  }\n}\n\nfragment AgentAuthorizedStatus_agentRest on AgentRest {\n  authorizedInfo {\n    comment {\n      text\n      timestamp\n      user {\n        name\n        username\n        _id\n      }\n    }\n    status\n  }\n}\n\nfragment AgentConnectionStatus_agentRest on AgentRest {\n  connected\n  connectedSince\n  disconnectionComment\n  lastActivityTime\n}\n\nfragment AgentEnableStatus_agentRest on AgentRest {\n  enabledInfo {\n    comment {\n      text\n      timestamp\n      user {\n        name\n        username\n        _id\n      }\n    }\n    status\n  }\n}\n\nfragment AgentHeader_agentRest on AgentRest {\n  ...AgentEnableStatus_agentRest\n  ...AgentConnectionStatus_agentRest\n  ...AgentAuthorizedStatus_agentRest\n  port\n  ip\n  environment {\n    osName\n  }\n}\n\nfragment AgentPipelineActions_agentRest on AgentRest {\n  ...AgentPipelineRemoveButton_agentRest\n  id\n  connected\n  name\n  enabled\n  cloudInstance {\n    id\n    _id\n  }\n  build {\n    id\n    _id\n  }\n  pool {\n    id\n    _id\n  }\n}\n\nfragment AgentPipelinePage_agentRest on AgentRest {\n  ...AgentHeader_agentRest\n  ...AgentPipelineActions_agentRest\n  version\n  cpuRank\n  host\n  name\n  connected\n  enabled\n  build {\n    id\n    _id\n  }\n  environment {\n    osName\n    osType\n  }\n}\n\nfragment AgentPipelineRemoveButton_agentRest on AgentRest {\n  id\n  connected\n  build {\n    id\n    _id\n  }\n  pool {\n    id\n    _id\n  }\n}\n\nfragment AgentsPagesCanViewAgentDetailsFragment on Query {\n  agentPoolsRest {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "1adc326416891e9c755ce9d0c2276a8e";

export default node;
