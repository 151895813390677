/**
 * @generated SignedSource<<4cce29e68759ce3d18d4827d382cedb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RunBuildIsCompositeQuery$variables = {
  buildLocator: string;
};
export type RunBuildIsCompositeQuery$data = {
  readonly build: {
    readonly composite: boolean | null | undefined;
  } | null | undefined;
};
export type RunBuildIsCompositeQuery = {
  response: RunBuildIsCompositeQuery$data;
  variables: RunBuildIsCompositeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "buildLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "buildLocator",
    "variableName": "buildLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "composite",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RunBuildIsCompositeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RunBuildIsCompositeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d1afc97f9459c8860988ce034f7b5e1",
    "id": null,
    "metadata": {},
    "name": "RunBuildIsCompositeQuery",
    "operationKind": "query",
    "text": "query RunBuildIsCompositeQuery(\n  $buildLocator: String!\n) {\n  build(buildLocator: $buildLocator) {\n    composite\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fcb28fb6dab7ad2aac6b550ac8d8234";

export default node;
