/**
 * @generated SignedSource<<4092cada3780b7d5ada2828804da171a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildState = "deleted" | "finished" | "queued" | "running" | "unknown";
export type PipelineRunBreadcrumbsQuery$variables = {
  pipelineRunLocator: string;
};
export type PipelineRunBreadcrumbsQuery$data = {
  readonly build: {
    readonly startDate: string | null | undefined;
    readonly state: BuildState | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"useBuildStatusIconFragment" | "useFormatBuildNumberFragment" | "useRunningBuildUpdaterRelayFragment">;
  } | null | undefined;
};
export type PipelineRunBreadcrumbsQuery = {
  response: PipelineRunBreadcrumbsQuery$data;
  variables: PipelineRunBreadcrumbsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pipelineRunLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "buildLocator",
    "variableName": "pipelineRunLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineRunBreadcrumbsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useBuildStatusIconFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useFormatBuildNumberFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useRunningBuildUpdaterRelayFragment"
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineRunBreadcrumbsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Build",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Comment",
            "kind": "LinkedField",
            "name": "canceledInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "failedToStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "personal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "detachedFromAgent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "queuePosition",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BuildTypeRest",
            "kind": "LinkedField",
            "name": "buildType",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalId",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a233f2f0b3240a00d997a4ad99a4ee20",
    "id": null,
    "metadata": {},
    "name": "PipelineRunBreadcrumbsQuery",
    "operationKind": "query",
    "text": "query PipelineRunBreadcrumbsQuery(\n  $pipelineRunLocator: String!\n) {\n  build(buildLocator: $pipelineRunLocator) {\n    ...useBuildStatusIconFragment\n    ...useFormatBuildNumberFragment\n    ...useRunningBuildUpdaterRelayFragment\n    state\n    startDate\n    _id\n  }\n}\n\nfragment useBuildStatusIconFragment on Build {\n  status\n  state\n  canceledInfo {\n    timestamp\n  }\n  failedToStart\n  personal\n  detachedFromAgent\n  user {\n    id\n    _id\n  }\n}\n\nfragment useFormatBuildNumberFragment on Build {\n  number\n  state\n  queuePosition\n}\n\nfragment useRunningBuildUpdaterRelayFragment on Build {\n  id\n  state\n  buildType {\n    internalId\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b49ae77985b25a0c099596b62021367";

export default node;
