/**
 * @generated SignedSource<<37cd3346a6ac2a729f637389f87e886d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BuildArtifactsTreeQuery$variables = {
  buildLocator: string;
  locator?: string | null | undefined;
  path: string;
};
export type BuildArtifactsTreeQuery$data = {
  readonly filesListForSubpathOfBuild: {
    readonly file: ReadonlyArray<{
      readonly fullName: string | null | undefined;
      readonly name: string | null | undefined;
      readonly size: number | null | undefined;
    }> | null | undefined;
  } | null | undefined;
};
export type BuildArtifactsTreeQuery = {
  response: BuildArtifactsTreeQuery$data;
  variables: BuildArtifactsTreeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "buildLocator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locator"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "path"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "buildLocator",
        "variableName": "buildLocator"
      },
      {
        "kind": "Variable",
        "name": "locator",
        "variableName": "locator"
      },
      {
        "kind": "Variable",
        "name": "path",
        "variableName": "path"
      }
    ],
    "concreteType": "Files",
    "kind": "LinkedField",
    "name": "filesListForSubpathOfBuild",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "file",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildArtifactsTreeQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BuildArtifactsTreeQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "92308d387f01ccd93fe801f6cadfcbda",
    "id": null,
    "metadata": {},
    "name": "BuildArtifactsTreeQuery",
    "operationKind": "query",
    "text": "query BuildArtifactsTreeQuery(\n  $buildLocator: String!\n  $path: String!\n  $locator: String\n) {\n  filesListForSubpathOfBuild(buildLocator: $buildLocator, path: $path, locator: $locator) {\n    file {\n      name\n      fullName\n      size\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6eb5ceac6a1da3acb534aa71c177fc01";

export default node;
