import {graphql} from 'react-relay'

export const query = graphql`
  query AgentTypePipelinePageQuery($locator: String!) {
    ...AgentsPagesCanViewAgentDetailsFragment
    agentType(agentTypeLocator: $locator) {
      ...AgentTypePipelinePage_agentType
    }
  }
`
