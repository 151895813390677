import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../utils/makeResource'
import {getAgentsPagesQueries} from '../AgentsPages.queries'

import type {AgentPoolPageOuterContainerType} from './AgentPoolPage'
import {STRING_DEFAULT_AGENT_POOL_ID} from './AgentPoolPage.consts'
import {agentPoolQuery, query} from './AgentPoolPage.queries'

export const AgentPoolPageEntryPoint: EntryPoint<
  AgentPoolPageOuterContainerType,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'AgentPoolPage',
    () => import(/* webpackChunkName: "AgentPoolPage", webpackPrefetch: true */ './AgentPoolPage'),
  ),
  getPreloadProps: ({params}) => ({
    queries: {
      ...getAgentsPagesQueries(),
      main: {
        parameters: getRequest(query),
        variables: {},
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
      agentPool: {
        parameters: getRequest(agentPoolQuery),
        variables: {
          agentPoolId: params.agentPoolId!,
          isDefault: params.agentPoolId === STRING_DEFAULT_AGENT_POOL_ID,
        },
        options: {networkCacheConfig: {metadata: {essential: true}}},
      },
    },
  }),
}
