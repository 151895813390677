import {castDraft} from 'immer'

import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import type {Job} from '../../types'

import {MIN_PARALLELISM_COUNT} from './EditPipelinePage.slices.consts'
import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {deleteIfEmpty, getJob} from './EditPipelinePage.slices.utils'

export const changeJobId: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    jobId: string
    newId: string
  }>
> = (state, action) => {
  const {pipelineId, jobId, newId} = action.payload
  const {draft} = state[pipelineId] ?? {}

  if (draft != null) {
    draft.settings.jobs ??= {}

    const job = draft.settings.jobs![jobId]
    delete draft.settings.jobs![jobId]
    draft.settings.jobs![newId] = job
  }
}

export const setJobName: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    pipelineId: string
    jobId: string
    value: string
  }>
> = (state, action) => {
  const {pipelineId, value, jobId} = action.payload
  const job = getJob(state, pipelineId, jobId)
  if (job != null) {
    job.name = value
  }
}

export const addJob: CaseReducer<
  PipelineDraftState,
  PayloadAction<{job: Job; id: string; jobId: string}>
> = (state, action) => {
  const {job, id, jobId} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.settings.jobs ??= {}
    draft.settings.jobs![jobId] = castDraft(job)
  }
}

export type DeleteJobPayload = {id: string; jobId: string}
export const deleteJob: CaseReducer<PipelineDraftState, PayloadAction<DeleteJobPayload>> = (
  state,
  action,
) => {
  const {id, jobId} = action.payload
  state[id] ??= {}
  state[id]!.deleted ??= {}
  state[id]!.deleted!.jobs ??= []
  state[id]!.deleted!.jobs!.push(jobId)
}

export const restoreJob: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; jobId: string}>
> = (state, action) => {
  const {id, jobId} = action.payload
  const {deleted} = state[id] ?? {}
  if (deleted?.jobs?.includes(jobId)) {
    deleted.jobs = deleted.jobs.filter(otherId => otherId !== jobId)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    deleteIfEmpty(deleted, 'jobs') && deleteIfEmpty(state[id], 'deleted')
  }
}

export const toggleCheckoutWorkingDirectoriesOnly: CaseReducer<
  PipelineDraftState,
  PayloadAction<{value: boolean; id: string; jobId: string}>
> = (state, action) => {
  const {id, jobId, value} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    if (value) {
      delete job['checkout-working-directories-only']
    } else {
      job['checkout-working-directories-only'] = false
    }
  }
}

export const toggleJobAllowReuse: CaseReducer<
  PipelineDraftState,
  PayloadAction<{value: boolean; id: string; jobId: string}>
> = (state, action) => {
  const {id, jobId, value} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    if (value) {
      delete job['allow-reuse']
    } else {
      job['allow-reuse'] = false
    }
  }
}

export const setParallelismCount: CaseReducer<
  PipelineDraftState,
  PayloadAction<{value: number | null; id: string; jobId: string}>
> = (state, action) => {
  const {id, jobId, value} = action.payload
  const job = getJob(state, id, jobId)

  if (job == null) {
    return
  }

  if (!value || value < MIN_PARALLELISM_COUNT) {
    delete job.parallelism
  } else {
    job.parallelism = value
  }
}

export const toggleEnableDependencyCache: CaseReducer<
  PipelineDraftState,
  PayloadAction<{value: boolean; id: string; jobId: string}>
> = (state, action) => {
  const {id, jobId, value} = action.payload
  const job = getJob(state, id, jobId)

  if (job == null) {
    return
  }
  if (!value) {
    delete job['enable-dependency-cache']
  } else {
    job['enable-dependency-cache'] = value
  }
}
