import {graphql} from 'react-relay'

export const query = graphql`
  query PipelineRunBreadcrumbsQuery($pipelineRunLocator: String!) {
    build(buildLocator: $pipelineRunLocator) {
      ...useBuildStatusIconFragment
      ...useFormatBuildNumberFragment
      ...useRunningBuildUpdaterRelayFragment
      state
      startDate
    }
  }
`
