/**
 * @generated SignedSource<<bb133da634c753ac1198dab7b3551199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvatarEditorQuery$variables = {
  userLocator: string;
};
export type AvatarEditorQuery$data = {
  readonly user: {
    readonly _id: string;
    readonly avatars: {
      readonly urlToSize20: string | null | undefined;
    } | null | undefined;
    readonly name: string | null | undefined;
    readonly username: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"AvatarFragment">;
  } | null | undefined;
};
export type AvatarEditorQuery = {
  response: AvatarEditorQuery$data;
  variables: AvatarEditorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userLocator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userLocator",
    "variableName": "userLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlToSize20",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AvatarEditorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AvatarFragment"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAvatars",
            "kind": "LinkedField",
            "name": "avatars",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AvatarEditorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAvatars",
            "kind": "LinkedField",
            "name": "avatars",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize28",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize32",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize40",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize56",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize64",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlToSize80",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38813aeda8fc686e87e9e3f22e0fb6eb",
    "id": null,
    "metadata": {},
    "name": "AvatarEditorQuery",
    "operationKind": "query",
    "text": "query AvatarEditorQuery(\n  $userLocator: String!\n) {\n  user(userLocator: $userLocator) {\n    ...AvatarFragment\n    _id\n    avatars {\n      urlToSize20\n    }\n    name\n    username\n  }\n}\n\nfragment AvatarFragment on User {\n  id\n  name\n  username\n  avatars {\n    urlToSize20\n    urlToSize28\n    urlToSize32\n    urlToSize40\n    urlToSize56\n    urlToSize64\n    urlToSize80\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca7511d5138aacbfcf759683c488c153";

export default node;
