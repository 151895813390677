/**
 * @generated SignedSource<<b214b87d079cadd4a57822f84888cc63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuildApprovalReviewers_groupedByStatusApprovals$data = {
  readonly userApproval: ReadonlyArray<{
    readonly approved: boolean | null | undefined;
    readonly user: {
      readonly id: number | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_approvalUser">;
    } | null | undefined;
  }> | null | undefined;
  readonly " $fragmentType": "BuildApprovalReviewers_groupedByStatusApprovals";
};
export type BuildApprovalReviewers_groupedByStatusApprovals$key = {
  readonly " $data"?: BuildApprovalReviewers_groupedByStatusApprovals$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuildApprovalReviewers_groupedByStatusApprovals">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuildApprovalReviewers_groupedByStatusApprovals",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserApprovalRule",
      "kind": "LinkedField",
      "name": "userApproval",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "approved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BuildApprovalReviewers_approvalUser"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserApprovals",
  "abstractKey": null
};

(node as any).hash = "5925b28706b374f48135b3cedd34640d";

export default node;
