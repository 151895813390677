import type {ReactElement} from 'react'
import {graphql, useFragment} from 'react-relay'

import type {AvatarFragment$key} from './__generated__/AvatarFragment.graphql'
import type {AvatarBaseProps} from './Avatar.container'
import Avatar from './Avatar.container'

type Props = AvatarBaseProps & {
  userKey: AvatarFragment$key | null
}

const avatarFragment = graphql`
  fragment AvatarFragment on User {
    id
    name
    username
    avatars {
      urlToSize20
      urlToSize28
      urlToSize32
      urlToSize40
      urlToSize56
      urlToSize64
      urlToSize80
    }
  }
`

function AvatarRelay({userKey, ...props}: Props): ReactElement | null {
  const user = useFragment(avatarFragment, userKey)

  return <Avatar {...props} user={user} />
}

export default AvatarRelay
