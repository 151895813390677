import {createSelector} from 'reselect'

import type {State} from '../../../../../../reducers/types'
import {getAgentsOverviewHref} from '../../../../../../routes'
import {getAllAgentPreviews} from '../../../../../../selectors'
import type {OSType} from '../../../../../../types'
import {capitalizeFirstLetter} from '../../../../PipelinesPages/utils/string'
import {
  type AgentPoolArray,
  createFolderNode,
  appendAgentNode,
  createAgentNode,
  createAgentSelectionNode,
  createAgentTypeNode,
  getAgentIdListByAgentType,
} from '../../../AgentsPages.selectors'
import type {
  AgentsTreeFolderTypeNode,
  AgentsTreeAgentTypeNode,
  AgentsTreeRootNode,
} from '../../AgentsSidebar.types'
import {AgentTreeItemType} from '../../AgentsSidebar.types'
import {matchAgentsTree} from '../../utils/matches'
import {getAgentsTreeSearchState} from '../getExpand'
import {sortAgentsTree} from '../sortedTree'

const ALL_AGENTS_SELECTION_NAME = 'ALL AGENTS'
export const ALL_AGENTS_SELECTION_ID = 'all-agents'
const JETBRAINS_HOSTED_NAME = 'JetBrains-hosted'
export const JETBRAINS_HOSTED_ID = 'jetbrains-hosted'
const SELF_HOSTED_NAME = 'Self-hosted'
export const SELF_HOSTED_ID = 'self-hosted'

function findOrCreateFolder(
  parent: AgentsTreeFolderTypeNode,
  title: string,
  id: string,
): AgentsTreeFolderTypeNode {
  let folder = parent.children.find(
    (element): element is AgentsTreeFolderTypeNode =>
      element.type === AgentTreeItemType.FOLDER && element.title === title,
  )

  if (!folder) {
    folder = createFolderNode(id, title)
    parent.children.push(folder)
  }

  return folder
}

function appendAgentFolderNode(branch: AgentsTreeFolderTypeNode, child: AgentsTreeAgentTypeNode) {
  const osType = child.osType && capitalizeFirstLetter(child.osType)
  const folder = findOrCreateFolder(branch, osType!, osType!)

  folder.children.push(child)
  branch.count += child.count
  folder.count += child.count

  branch.busyCount += child.busyCount
  folder.busyCount += child.busyCount

  branch.disconnectedCount += child.disconnectedCount
  folder.disconnectedCount += child.disconnectedCount
}

// TODO maybe we can request agent types from the server not pools
const getPipelinesAgentPoolsTree = createSelector(
  [
    getAllAgentPreviews,
    getAgentIdListByAgentType,
    (_: State, props: {pools: AgentPoolArray}) => props.pools,
  ],
  (agentPreviewsHash, agentIdListByAgentType, pools) => {
    const cloudHosted = createFolderNode(JETBRAINS_HOSTED_ID, JETBRAINS_HOSTED_NAME)
    const selfHosted = createFolderNode(SELF_HOSTED_ID, SELF_HOSTED_NAME)

    pools?.forEach(pool => {
      pool.agentTypes?.agentType?.forEach(agentType => {
        const agentTypeId = agentType.id
        const agentTypeName = agentType.name

        function appendAgentList(props: {
          branch: AgentsTreeRootNode | AgentsTreeAgentTypeNode | AgentsTreeFolderTypeNode
          agentTypeIdToAdd: number
          cloud: boolean
        }) {
          const {branch, agentTypeIdToAdd, cloud} = props
          const agentList = agentIdListByAgentType[agentTypeIdToAdd]

          if (agentList) {
            agentList.forEach(agentId => {
              const agent = agentPreviewsHash[agentId]
              if (agent && agent.authorized) {
                const agentNode = createAgentNode(agent, {
                  cloud,
                })

                appendAgentNode(branch, agentNode)
              }
            })
          }
        }

        if (agentTypeId != null && agentTypeName != null) {
          const jbHostedProperty = agentType.configurationParameters?.property?.find(
            property => property.name === 'teamcity.agent.jbHosted',
          )
          const isJetBrainsHosted = jbHostedProperty?.value === 'true'

          if (isJetBrainsHosted && agentType.isCloud) {
            // TODO move this(&& agentType.isCloud) check to the query locator
            const agentTypeNode = createAgentTypeNode(
              agentTypeId,
              agentTypeName,
              (agentType.environment?.osType ?? 'Unknown') as OSType,
            )
            appendAgentFolderNode(cloudHosted, agentTypeNode)
          } else {
            appendAgentList({
              branch: selfHosted,
              agentTypeIdToAdd: agentTypeId,
              cloud: Boolean(agentType.isCloud),
            })
          }
        }
      })
    })

    const items: AgentsTreeFolderTypeNode[] = []

    if (selfHosted.children.length > 0) {
      items.push(selfHosted)
    }

    items.push(cloudHosted)

    return items
  },
)

const sortedAgentsTree = createSelector([getPipelinesAgentPoolsTree], sortAgentsTree)

const getAgentsPoolSelectionTree = createSelector([sortedAgentsTree], tree => {
  const selection = createAgentSelectionNode({
    id: ALL_AGENTS_SELECTION_ID,
    name: ALL_AGENTS_SELECTION_NAME,
    href: getAgentsOverviewHref(),
  })

  return {
    ...selection,
    disconnectedCount: tree.reduce((acc, item) => acc + item.disconnectedCount, 0),
    children: tree,
  }
})
export const getPipelinesMatchingAgentsTree = createSelector(
  [getAgentsPoolSelectionTree, getAgentsTreeSearchState],
  (selection, searchState) => matchAgentsTree([selection], searchState),
)
