import {Suspense} from 'react'
import {connect} from 'react-redux'
import {graphql} from 'react-relay'

import {useUpdateResults} from '../../../contexts/update'
import {useMutationAsPromise} from '../../../hooks/relay/useMutationAsPromise'
import type {State} from '../../../reducers/types'
import {getBuild, getBuildType} from '../../../selectors'
import type {BuildId, ProjectId} from '../../../types'

import type {MoveToTopMutation} from './__generated__/MoveToTopMutation.graphql'
import MoveToTop from './MoveToTop'

type Props = {
  buildId: BuildId
  className?: string
}

type StateProps = {
  projectId: ProjectId | undefined
  isDraggable: boolean
}

const mapStateToProps = (state: State, props: Props): StateProps => {
  const {buildType, queuePosition = 0} = getBuild(state, props.buildId) || {}
  const {projectId} = getBuildType(state, buildType) || {}
  const isDraggable = queuePosition > 1
  return {
    projectId,
    isDraggable,
  }
}

const mutation = graphql`
  mutation MoveToTopMutation($queuePosition: String!, $build: BuildInput) {
    setQueuedBuildPosition(queuePosition: $queuePosition, input: $build) {
      queuePosition
    }
  }
`

function MoveToTopContainer(props: Props & StateProps) {
  const [moveBuildToTop] = useMutationAsPromise<MoveToTopMutation>(mutation)
  const updateBuilds = useUpdateResults()

  return (
    <Suspense>
      <MoveToTop
        {...props}
        updateBuilds={updateBuilds}
        clickHandler={(id: BuildId) =>
          moveBuildToTop({
            variables: {queuePosition: '1', build: {id}},
          })
        }
      />
    </Suspense>
  )
}

export default connect(mapStateToProps)(MoveToTopContainer)
