/* eslint-disable @typescript-eslint/no-unused-expressions */
import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import {generateFreeName} from '../../utils/generateFreeName'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {deleteIfEmpty} from './EditPipelinePage.slices.utils'

export const setParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    savedName: string
    value: string
  }>
> = (state, action) => {
  const {id, savedName, value} = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.settings.parameters ??= {}
    draft.settings.parameters[savedName] = value
  }
}

export const renameParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    name: string
    savedName: string
  }>
> = (state, action) => {
  const {id, name, savedName} = action.payload

  if (name !== savedName) {
    state[id] ??= {}
    state[id]!.renamed ??= {}
    state[id]!.renamed!.parameters ??= {}
    state[id]!.renamed!.parameters![savedName] = name
  } else if (state[id]?.renamed?.parameters?.[savedName] != null) {
    delete state[id]!.renamed!.parameters![savedName]
    deleteIfEmpty(state[id]!.renamed, 'parameters') && deleteIfEmpty(state[id], 'renamed')
  }
}

export const deleteParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; savedName: string}>
> = (state, action) => {
  const {id, savedName} = action.payload

  if (state[id]?.added?.parameters?.[savedName]) {
    delete state[id]!.draft?.settings.parameters?.[savedName]
    delete state[id]!.added!.parameters![savedName]
    deleteIfEmpty(state[id]!.added, 'parameters') && deleteIfEmpty(state[id], 'added')
    delete state[id]!.renamed!.parameters![savedName]
    deleteIfEmpty(state[id]!.renamed, 'parameters') && deleteIfEmpty(state[id], 'renamed')
  } else {
    state[id] ??= {}
    state[id]!.deleted ??= {}
    state[id]!.deleted!.parameters ??= []
    state[id]!.deleted!.parameters!.push(savedName)
  }
}

export const restoreParameter: CaseReducer<
  PipelineDraftState,
  PayloadAction<{id: string; savedName: string}>
> = (state, action) => {
  const {id, savedName} = action.payload
  const {deleted} = state[id] ?? {}
  if (deleted?.parameters?.includes(savedName)) {
    deleted.parameters = deleted.parameters.filter(item => item !== savedName)
    deleteIfEmpty(deleted, 'parameters') && deleteIfEmpty(state[id], 'deleted')
  }
}

export const addParameter: CaseReducer<PipelineDraftState, PayloadAction<string>> = (
  state,
  action,
) => {
  const id = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.settings.parameters ??= {}
    const key = generateFreeName({
      getName: iteration => `parameter_${iteration}`,
      usedNames: Object.keys(draft.settings.parameters),
      errorMessage: 'Cannot generate unique parameter key',
    })
    draft.settings.parameters[key] = ''
    renameParameter(state, {type: '', payload: {id, name: '', savedName: key}})
    state[id]!.added ??= {}
    state[id]!.added!.parameters ??= {}
    state[id]!.added!.parameters![key] = true
  }
}

export const clearAddedParameters: CaseReducer<PipelineDraftState, PayloadAction<string>> = (
  state,
  action,
) => {
  const id = action.payload
  const {added, draft} = state[id] ?? {}
  if (added?.parameters != null) {
    Object.keys(added.parameters).forEach(key => {
      delete draft?.settings.parameters?.[key]
    })
    delete added.parameters
    deleteIfEmpty(state[id], 'added')
  }
}

export const applyAddedParameters: CaseReducer<PipelineDraftState, PayloadAction<string>> = (
  state,
  action,
) => {
  const id = action.payload
  const {added} = state[id] ?? {}
  if (added?.parameters != null) {
    delete added.parameters
    deleteIfEmpty(state[id], 'added')
  }
}
