import {useAppSelector} from '../../../../hooks/react-redux'
import {getServerInfo} from '../../../../selectors'
import {isPipelinesEnabledInExclusiveMode} from '../../../pages/PipelinesPages/utils/featureToggles'
import {useFormattedVersionAndDetails} from '../../Header/HeaderTrayHelp/Footer/Footer.hooks'

import styles from './Version.css'

export default function Version() {
  const licenseModeName = useAppSelector(state =>
    isPipelinesEnabledInExclusiveMode ? 'Pipelines' : getServerInfo(state)?.licenseModeName,
  )
  const buildNumber = useAppSelector(state => getServerInfo(state)?.buildNumber)
  const formattedVersionAndDetails = useFormattedVersionAndDetails()

  return (
    licenseModeName != null && (
      <div className={styles.version}>
        {`TeamCity ${licenseModeName} ${formattedVersionAndDetails}${buildNumber ? ` (build ${buildNumber})` : ''}`}
      </div>
    )
  )
}
