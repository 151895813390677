import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip'
import classNames from 'classnames'
import plur from 'plur'
import * as React from 'react'
import {useContext} from 'react'
import {graphql, useFragment} from 'react-relay'

import {notNull} from '../../../utils/guards'
import {Size} from '../Avatar/Avatar.container'
import AvatarRelay from '../Avatar/Avatar.relay'

import type {BuildApprovalReviewers_approvalGroups$key} from './__generated__/BuildApprovalReviewers_approvalGroups.graphql'
import type {BuildApprovalReviewers_approvalInfo$key} from './__generated__/BuildApprovalReviewers_approvalInfo.graphql'
import type {BuildApprovalReviewers_approvalUser$key} from './__generated__/BuildApprovalReviewers_approvalUser.graphql'
import type {BuildApprovalReviewers_groupApproval$key} from './__generated__/BuildApprovalReviewers_groupApproval.graphql'
import type {BuildApprovalReviewers_groupedByStatusApprovals$key} from './__generated__/BuildApprovalReviewers_groupedByStatusApprovals.graphql'
import {BuildApprovalViewContext} from './BuildApproval.context'
import {useGroupedByStatusApprovals} from './BuildApproval.hooks'
import {BuildApprovalViewMode} from './BuildApproval.types'

import styles from './BuildApproval.css'

const approvalUserFragment = graphql`
  fragment BuildApprovalReviewers_approvalUser on User {
    name
    username
    id
    ...AvatarFragment
  }
`

type ApprovalUserProps = {
  userKey: BuildApprovalReviewers_approvalUser$key | null
  approved: boolean
  withAvatar: boolean
  foldNames: boolean
  isLast: boolean
}

function ApprovalUser({userKey, approved, withAvatar, foldNames, isLast}: ApprovalUserProps) {
  const user = useFragment(approvalUserFragment, userKey)
  const username = user?.name ?? user?.username ?? user?.id
  const WrapperTag = foldNames ? Tooltip : React.Fragment
  const props = foldNames
    ? {
        popupProps: {top: -1, offset: 4},
        title: username,
      }
    : {}
  const view = useContext(BuildApprovalViewContext)

  return (
    <React.Fragment>
      <span
        className={classNames(
          styles.user,
          withAvatar && styles.withAvatar,
          foldNames && !isLast ? styles.folded : null,
          approved ? styles.approved : styles.awaits,
        )}
      >
        {withAvatar ? (
          <WrapperTag {...props}>
            <AvatarRelay userKey={user} size={Size.Size20} withOutline={false} />
          </WrapperTag>
        ) : null}
        {foldNames ? null : <span className={styles.username}>{username}</span>}
        {(foldNames && isLast) || !foldNames ? (
          <span className={styles.status}>{approved ? 'approved' : 'awaiting'}</span>
        ) : null}
      </span>
      {!isLast && !withAvatar && view !== BuildApprovalViewMode.POPUP ? <span>{','}</span> : null}
    </React.Fragment>
  )
}

type ApprovalUserLineProps = {
  users: ReadonlyArray<BuildApprovalReviewers_approvalUser$key & {id?: number | null}>
  approved?: boolean
  withAvatar: boolean
}

function ApprovalUserLine({users, approved = false, withAvatar}: ApprovalUserLineProps) {
  const view = useContext(BuildApprovalViewContext)
  const foldNames =
    users.length > 1 && withAvatar && approved && view !== BuildApprovalViewMode.POPUP

  if (users.length === 0) {
    return null
  }

  return (
    <span>
      {users.map(
        (user, index) => (
          <ApprovalUser
            key={user.id}
            userKey={user}
            approved={approved}
            withAvatar={withAvatar}
            isLast={index === users.length - 1}
            foldNames={foldNames}
          />
        ),
        [],
      )}
    </span>
  )
}

type ApprovalUsersProps = {
  approvalsKey: BuildApprovalReviewers_groupedByStatusApprovals$key | null
  withAvatar: boolean
}

function ApprovalUsers({approvalsKey, withAvatar}: ApprovalUsersProps) {
  const groupedApprovals = useGroupedByStatusApprovals(approvalsKey)

  return (
    <>
      <ApprovalUserLine
        approved
        users={groupedApprovals.approved.map(({user}) => user).filter(notNull)}
        withAvatar={withAvatar}
      />
      <ApprovalUserLine
        users={groupedApprovals.nonApproved.map(({user}) => user).filter(notNull)}
        withAvatar={withAvatar}
      />
    </>
  )
}

const groupApprovalFragment = graphql`
  fragment BuildApprovalReviewers_groupApproval on GroupApprovalRule {
    group {
      name
    }
    requiredApprovalsCount
    currentlyApprovedBy {
      user {
        id
        ...BuildApprovalReviewers_approvalUser
      }
    }
  }
`

function GroupApproval(props: {groupApprovalKey: BuildApprovalReviewers_groupApproval$key | null}) {
  const {group, requiredApprovalsCount, currentlyApprovedBy} =
    useFragment(groupApprovalFragment, props.groupApprovalKey) ?? {}
  const viewMode = useContext(BuildApprovalViewContext)
  const users = currentlyApprovedBy?.user ?? []

  const approvalsLeft = (requiredApprovalsCount ?? 0) - users.length
  const approvalsLeftMessage = approvalsLeft ? (
    <span className={styles.approvalsLeft}>
      {`${approvalsLeft} ${plur('user', approvalsLeft)} awaiting`}
      {users.length && viewMode === BuildApprovalViewMode.INLINE ? <span>{','}</span> : null}
    </span>
  ) : null

  const groupName = `Group "${group?.name}"`

  return (
    <span className={styles.group}>
      <span>
        {groupName}
        {approvalsLeftMessage || users.length ? <span>{': '}</span> : null}
        {approvalsLeftMessage}
      </span>
      <span>
        {users.length > 0 && <ApprovalUserLine approved users={users} withAvatar={false} />}
      </span>
    </span>
  )
}

const approvalGroupsFragment = graphql`
  fragment BuildApprovalReviewers_approvalGroups on GroupApprovals {
    groupApproval {
      group {
        key
      }
      ...BuildApprovalReviewers_groupApproval
    }
  }
`

function ApprovalGroups({
  approvalsKey,
}: {
  approvalsKey: BuildApprovalReviewers_approvalGroups$key | null
}) {
  const approvals = useFragment(approvalGroupsFragment, approvalsKey)?.groupApproval ?? []
  return (
    <>
      {approvals.map(approval => (
        <GroupApproval key={approval.group?.key} groupApprovalKey={approval} />
      ))}
    </>
  )
}

const fragment = graphql`
  fragment BuildApprovalReviewers_approvalInfo on ApprovalInfo {
    userApprovals {
      ...BuildApprovalReviewers_groupedByStatusApprovals
    }
    groupApprovals {
      ...BuildApprovalReviewers_approvalGroups
    }
  }
`

type ApprovalProps = {
  approvalInfoKey: BuildApprovalReviewers_approvalInfo$key | null
}

export default function BuildApprovalReviewers({approvalInfoKey}: ApprovalProps) {
  const {userApprovals = null, groupApprovals = null} = useFragment(fragment, approvalInfoKey) ?? {}
  const view = useContext(BuildApprovalViewContext)

  return (
    <div
      className={classNames({
        [styles.inline]: view === BuildApprovalViewMode.INLINE,
        [styles.popup]: view === BuildApprovalViewMode.POPUP,
      })}
    >
      <ApprovalUsers approvalsKey={userApprovals} withAvatar />
      <ApprovalGroups approvalsKey={groupApprovals} />
    </div>
  )
}
