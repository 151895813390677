import type {GraphQLTaggedNode} from 'react-relay'
import {graphql} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {isPipelinesAgentPageEnabled} from '../PipelinesPages/utils/featureToggles'

import type {AgentsPagesAgentTypesQuery} from './__generated__/AgentsPagesAgentTypesQuery.graphql'

export const poolsAuthorizePermissionsQuery: GraphQLTaggedNode = graphql`
  query AgentsPagesPoolsAuthorizePermissionsQuery {
    agentPools {
      edges {
        node {
          id
          rawId
          name
          permissions {
            authorizeAgents
            manageAgents
          }
        }
      }
    }
  }
`

export const poolAuthorizePermissionsQuery: GraphQLTaggedNode = graphql`
  query AgentsPagesPoolAuthorizePermissionsQuery($agentPoolId: ID!) {
    agentPool(id: $agentPoolId) {
      id
      permissions {
        authorizeAgents
        manageAgents
      }
    }
  }
`

export const agentTypesQuery = graphql`
  query AgentsPagesAgentTypesQuery($skipParameters: Boolean = true) {
    agentPoolsRest {
      agentPool {
        id
        name
        agentTypes {
          agentType {
            id
            name
            isCloud
            environment {
              osType
              osName
            }
            configurationParameters(
              locator: "name:(value:(teamcity.agent.jbHosted),matchType:equals)"
            ) @skip(if: $skipParameters) {
              property {
                name
                value
              }
            }
          }
        }
      }
    }
  }
`

export const NativeAgentTypesQuery = graphql`
  query AgentsPagesNativeAgentTypesQuery($skip: Boolean = true) {
    agentTypes @skip(if: $skip) {
      edges {
        node {
          id
          rawId
          name
          isCloud
          agentPool {
            id
            rawId
            name
          }
          cloudImage {
            id
            name
          }
          environment {
            os {
              type
            }
          }
        }
      }
    }
  }
`

export const getAgentsPagesQueries = () => ({
  agentTypes: {
    parameters: getRequest(agentTypesQuery),
    variables: {
      skipParameters: !isPipelinesAgentPageEnabled(),
    },
    options: {networkCacheConfig: {metadata: {essential: true}}},
  },
})

export type AgentsPagesQueries = {
  agentTypes: AgentsPagesAgentTypesQuery
}
