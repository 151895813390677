/**
 * @generated SignedSource<<a0a028b59fc26790f5ef3622073ccea5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueuePageQuery$variables = {
  locator: string;
  skip: boolean;
};
export type QueuePageQuery$data = {
  readonly agentPoolRest?: {
    readonly " $fragmentSpreads": FragmentRefs<"QueuePageHeader_agentPool">;
  } | null | undefined;
};
export type QueuePageQuery = {
  response: QueuePageQuery$data;
  variables: QueuePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentPoolLocator",
    "variableName": "locator"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueuePageQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPoolRest",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "QueuePageHeader_agentPool"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueuePageQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AgentPoolRest",
            "kind": "LinkedField",
            "name": "agentPoolRest",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "688d5517773cec99dedd0662b9e380c5",
    "id": null,
    "metadata": {},
    "name": "QueuePageQuery",
    "operationKind": "query",
    "text": "query QueuePageQuery(\n  $locator: String!\n  $skip: Boolean!\n) {\n  agentPoolRest(agentPoolLocator: $locator) @skip(if: $skip) {\n    ...QueuePageHeader_agentPool\n    _id\n  }\n}\n\nfragment QueuePageHeader_agentPool on AgentPoolRest {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "e43863e917494f1f0c516ed5c2d61762";

export default node;
