import {resolveHelpURL} from 'src/utils/url'

export enum BranchSpecificationPolicy {
  ALL_BRANCHES = 'ALL_BRANCHES',
  DEFAULT_ONLY = 'DEFAULT_ONLY',
  SPECIFIC = 'SPECIFIC',
}

export const allBranchesSpecification = 'refs/heads/*'

export const branchSpecDocLink = resolveHelpURL(
  'working-with-feature-branches.html?Working+with+Feature+Branches#WorkingwithFeatureBranches-branchSpec',
)
