/**
 * @generated SignedSource<<b7b7f6c895d59ef9a5702386dc910185>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BranchSelectBranchesQuery$variables = {
  btLocator: string;
  isBuildType: boolean;
  isProject: boolean;
  locator?: string | null | undefined;
  projectLocator: string;
};
export type BranchSelectBranchesQuery$data = {
  readonly branches?: {
    readonly " $fragmentSpreads": FragmentRefs<"BranchSelect_branches">;
  } | null | undefined;
  readonly branchesOfBuildType?: {
    readonly " $fragmentSpreads": FragmentRefs<"BranchSelect_branches">;
  } | null | undefined;
};
export type BranchSelectBranchesQuery = {
  response: BranchSelectBranchesQuery$data;
  variables: BranchSelectBranchesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "btLocator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isBuildType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isProject"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locator"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectLocator"
},
v5 = {
  "kind": "Variable",
  "name": "locator",
  "variableName": "locator"
},
v6 = [
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "projectLocator",
    "variableName": "projectLocator"
  }
],
v7 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "BranchSelect_branches"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "btLocator",
    "variableName": "btLocator"
  },
  (v5/*: any*/)
],
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Branch",
    "kind": "LinkedField",
    "name": "branch",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "default",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "groupFlag",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "internalName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unspecified",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BranchSelectBranchesQuery",
    "selections": [
      {
        "condition": "isProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Branches",
            "kind": "LinkedField",
            "name": "branches",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isBuildType",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Branches",
            "kind": "LinkedField",
            "name": "branchesOfBuildType",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BranchSelectBranchesQuery",
    "selections": [
      {
        "condition": "isProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Branches",
            "kind": "LinkedField",
            "name": "branches",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isBuildType",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Branches",
            "kind": "LinkedField",
            "name": "branchesOfBuildType",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "717ac0ae419e8f3d027361586ddb369b",
    "id": null,
    "metadata": {},
    "name": "BranchSelectBranchesQuery",
    "operationKind": "query",
    "text": "query BranchSelectBranchesQuery(\n  $locator: String\n  $projectLocator: String!\n  $btLocator: String!\n  $isProject: Boolean!\n  $isBuildType: Boolean!\n) {\n  branches(projectLocator: $projectLocator, locator: $locator) @include(if: $isProject) {\n    ...BranchSelect_branches\n  }\n  branchesOfBuildType(btLocator: $btLocator, locator: $locator) @include(if: $isBuildType) {\n    ...BranchSelect_branches\n  }\n}\n\nfragment BranchSelect_branches on Branches {\n  ...BranchSelect_hasNonDefaultBranches\n  ...BranchSelect_defaultBranchVcsName\n  branch {\n    internalName\n    name\n    default\n    active\n    groupFlag\n    unspecified\n  }\n}\n\nfragment BranchSelect_defaultBranchVcsName on Branches {\n  branch {\n    name\n    default\n  }\n}\n\nfragment BranchSelect_hasNonDefaultBranches on Branches {\n  branch {\n    default\n    groupFlag\n  }\n}\n"
  }
};
})();

(node as any).hash = "043fc0597eaa6e071fd0eff2bb800e6b";

export default node;
