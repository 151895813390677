import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../../../utils/makeResource'
import {cloudImagesQuery} from '../SettingsSidebarContent/JobSettingsSidebar/JobAgentRequirements/JobAgentRequirements.queries'

import type {EditPipelineYAMLComponent} from './EditPipelineYAML'

export const EditPipelineYAMLEntryPoint: EntryPoint<EditPipelineYAMLComponent> = {
  root: makeResource(
    'EditPipelineYAML',
    () =>
      import(
        /* webpackChunkName: "EditPipelineYAML", webpackPrefetch: true */
        './EditPipelineYAML'
      ),
  ),
  getPreloadProps: () => ({
    queries: {cloudImages: {parameters: getRequest(cloudImagesQuery), variables: {}}},
  }),
}
