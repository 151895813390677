import {createSelector} from 'reselect'

import type {State} from '../../../../../reducers/types'
import type {AgentsTreeExpandState, AgentsTreeSearchState} from '../../AgentsPages.types'
import {mergeExpandState} from '../../AgentsPages.utils'

const getDefaultAgentsTreeExpandState = () =>
  createSelector(
    (state1: AgentsTreeExpandState) => state1,
    (_: AgentsTreeExpandState, state2: AgentsTreeExpandState) => state2,
    mergeExpandState,
  )
export const getDefaultFavoriteTreeExpand = getDefaultAgentsTreeExpandState()
export const getDefaultTreeExpand = getDefaultAgentsTreeExpandState()

export const getAgentsTreeSearchState = ({agentsPage}: State): AgentsTreeSearchState =>
  agentsPage.agentsTreeSearchState
export const getIsSearching = (state: State): boolean =>
  getAgentsTreeSearchState(state).searchQuery !== ''
export const getExpandState = createSelector(
  [
    (_: State, props: {defaultExpandState: AgentsTreeExpandState}) => props.defaultExpandState,
    (state: State) => state.agentsPage.agentsTreeExpandState,
    (state: State) => state.agentsPage.agentsTreeSearchExpandState,
    getIsSearching,
  ],
  (defaultExpandState, reduxExpandState, reduxSearchExpandState, isSearching) =>
    // I use separate expand states - one for searching mode and one for regular
    isSearching ? reduxSearchExpandState : mergeExpandState(defaultExpandState, reduxExpandState),
)
export const getFavoriteExpandState = createSelector(
  [
    (_: State, props: {defaultFavoriteExpandState: AgentsTreeExpandState}) =>
      props.defaultFavoriteExpandState,
    (state: State) => state.agentsPage.favoriteAgentsTreeExpandState,
    (state: State) => state.agentsPage.favoriteAgentsTreeSearchExpandState,
    getIsSearching,
  ],
  (defaultExpandState, reduxExpandState, reduxSearchExpandState, isSearching) =>
    // I use separate expand states - one for searching mode and one for regular
    isSearching ? reduxSearchExpandState : mergeExpandState(defaultExpandState, reduxExpandState),
)
