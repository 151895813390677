import type {PlaceId, PluginContext, PluginInterface} from '@jetbrains/teamcity-api'
import classNames from 'classnames'
import * as React from 'react'
import type {$PropertyType} from 'utility-types'

import pluginRegistry from '../../../../plugins/plugins.registry'
import styles from '../Plugin.css'
import {isValidPluginReactElementType} from '../Plugin.utils'

import PluginErrorBoundary from './SakuraUIPlugin.errorBoundary'

type PluginLocationContext = $PropertyType<PluginContext, 'location'>
type OwnProps = {
  name: string
  placeId: PlaceId
  activeEntities: PluginLocationContext
}

declare global {
  interface HTMLDivElement {
    _teamCityPlugin?: PluginInterface
  }
}

const SakuraUIPlugin = ({name, activeEntities, placeId}: OwnProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const plugin: PluginInterface | null | undefined = pluginRegistry.findUniquePlugin(placeId, name)
  React.useEffect(() => {
    if (plugin != null) {
      plugin.updateContext({
        location: activeEntities,
      })
    }
  }, [activeEntities, plugin])
  React.useEffect(() => {
    if (containerRef.current != null && plugin != null) {
      containerRef.current.dataset.teamCityPluginName = plugin.name
      containerRef.current._teamCityPlugin = plugin
    }
  }, [plugin])
  React.useEffect(() => {
    if (plugin == null) {
      return undefined
    }

    const isValidReactElementType = isValidPluginReactElementType(plugin.content)

    if (containerRef.current != null || isValidReactElementType) {
      if (!isValidReactElementType) {
        if (plugin.container) {
          containerRef.current?.appendChild(plugin.container)
        }
      }

      plugin.mount()
    }

    return () => plugin.unmount()
  }, [plugin])
  const content = plugin?.content ?? ''
  const containerClassNames = plugin?.options.containerClassNames
  return React.useMemo(() => {
    const className = classNames(styles.container, containerClassNames)

    if (content instanceof HTMLElement || typeof content === 'string') {
      return <div className={className} ref={containerRef} />
    }

    if (isValidPluginReactElementType(content)) {
      const component = React.createElement(content, {
        location: activeEntities,
      })
      return (
        <div className={className} ref={containerRef}>
          {component}
        </div>
      )
    }

    return null
  }, [containerClassNames, content, activeEntities])
}

function Plugin(props: OwnProps) {
  return (
    <PluginErrorBoundary name={props.name} placeId={props.placeId}>
      <SakuraUIPlugin {...props} />
    </PluginErrorBoundary>
  )
}

export default Plugin
