import {allBranchesSpecification} from '../../../../../components/BranchSpecificationSelect/BranchSpecificationSelect.consts'
import {getDefaultBranchSpecification} from '../../../../../components/BranchSpecificationSelect/BranchSpecificationSelect.utils'

export const ROOT_REPOSITORY_KEY = 'Root'
export const getRepositoryKey = (isRoot: boolean, index?: number) =>
  isRoot ? ROOT_REPOSITORY_KEY : index
export const getBranchesToMonitor = (branchSpec: string, defaultBranch: string) =>
  branchSpec === allBranchesSpecification
    ? 'All branches'
    : branchSpec === getDefaultBranchSpecification(defaultBranch)
      ? 'Only default branch'
      : 'Specific branches'
