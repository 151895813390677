/**
 * @generated SignedSource<<46793c79aa15092fd0eaf77a4b4d1882>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentTypePageQuery$variables = {
  locator: string;
};
export type AgentTypePageQuery$data = {
  readonly agentType: {
    readonly " $fragmentSpreads": FragmentRefs<"AgentTypePage_agentType">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AgentsPagesCanViewAgentDetailsFragment">;
};
export type AgentTypePageQuery = {
  response: AgentTypePageQuery$data;
  variables: AgentTypePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentTypeLocator",
    "variableName": "locator"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentTypePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AgentsPagesCanViewAgentDetailsFragment"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentTypeRest",
        "kind": "LinkedField",
        "name": "agentType",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AgentTypePage_agentType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentTypePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPools",
        "kind": "LinkedField",
        "name": "agentPoolsRest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentTypeRest",
        "kind": "LinkedField",
        "name": "agentType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e18368bed4ecbf13c5a6d56112c2cfad",
    "id": null,
    "metadata": {},
    "name": "AgentTypePageQuery",
    "operationKind": "query",
    "text": "query AgentTypePageQuery(\n  $locator: String!\n) {\n  ...AgentsPagesCanViewAgentDetailsFragment\n  agentType(agentTypeLocator: $locator) {\n    ...AgentTypePage_agentType\n    _id\n  }\n}\n\nfragment AgentTypePage_agentType on AgentTypeRest {\n  name\n}\n\nfragment AgentsPagesCanViewAgentDetailsFragment on Query {\n  agentPoolsRest {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "76bbed4a6580b231f99f7069bdd54aad";

export default node;
