/* eslint-disable @typescript-eslint/no-unused-expressions */
import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'

import {generateFreeName} from '../../utils/generateFreeName'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {deleteIfEmpty} from './EditPipelinePage.slices.utils'

export const updateSecret: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    savedName: string
    value: string
  }>
> = (state, action) => {
  const {id, savedName, value} = action.payload

  const {secrets} = state[id]?.draft?.settings ?? {}
  if (secrets?.[savedName] != null) {
    secrets[savedName] = value
  }
}

export const renameSecret: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    name: string
    savedName: string
  }>
> = (state, action) => {
  const {id, name, savedName} = action.payload

  if (name !== savedName) {
    state[id] ??= {}
    state[id]!.renamed ??= {}
    state[id]!.renamed!.secrets ??= {}
    state[id]!.renamed!.secrets![savedName] = name
  } else if (state[id]?.renamed?.secrets?.[savedName] != null) {
    delete state[id]!.renamed!.secrets![savedName]
    deleteIfEmpty(state[id]!.renamed, 'secrets') && deleteIfEmpty(state[id], 'renamed')
  }
}

export const deleteSecret: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    savedName: string
  }>
> = (state, action) => {
  const {id, savedName} = action.payload

  if (state[id]?.added?.secrets?.[savedName]) {
    delete state[id]!.draft?.settings.secrets?.[savedName]
    delete state[id]!.added!.secrets![savedName]
    deleteIfEmpty(state[id]!.added, 'secrets') && deleteIfEmpty(state[id], 'added')
    delete state[id]!.renamed!.secrets![savedName]
    deleteIfEmpty(state[id]!.renamed, 'secrets') && deleteIfEmpty(state[id], 'renamed')
  } else {
    state[id] ??= {}
    state[id]!.deleted ??= {}
    state[id]!.deleted!.secrets ??= []
    state[id]!.deleted!.secrets!.push(savedName)
  }
}

export const restoreSecret: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    savedName: string
  }>
> = (state, action) => {
  const {id, savedName} = action.payload
  const {deleted} = state[id] ?? {}

  if (deleted?.secrets?.includes(savedName)) {
    deleted.secrets = deleted.secrets.filter(item => item !== savedName)
    deleteIfEmpty(deleted, 'secrets') && deleteIfEmpty(state[id], 'deleted')
  }
}

export const addSecret: CaseReducer<PipelineDraftState, PayloadAction<string>> = (
  state,
  action,
) => {
  const id = action.payload
  const {draft} = state[id] ?? {}
  if (draft != null) {
    draft.settings.secrets ??= {}
    const key = generateFreeName({
      getName: iteration => `secret_${iteration}`,
      usedNames: Object.keys(draft.settings.secrets),
      errorMessage: 'Cannot generate unique secret key',
    })
    draft.settings.secrets[key] = ''
    renameSecret(state, {type: '', payload: {id, name: '', savedName: key}})
    state[id]!.added ??= {}
    state[id]!.added!.secrets ??= {}
    state[id]!.added!.secrets![key] = true
  }
}

export const clearAddedSecrets: CaseReducer<PipelineDraftState, PayloadAction<string>> = (
  state,
  action,
) => {
  const id = action.payload
  const {added, draft} = state[id] ?? {}
  if (added?.secrets != null) {
    Object.keys(added.secrets).forEach(key => {
      delete draft?.settings.secrets?.[key]
    })
    delete added.secrets
    deleteIfEmpty(state[id], 'added')
  }
}

export const applyAddedSecrets: CaseReducer<PipelineDraftState, PayloadAction<string>> = (
  state,
  action,
) => {
  const id = action.payload
  const {added} = state[id] ?? {}
  if (added?.secrets != null) {
    delete added.secrets
    deleteIfEmpty(state[id], 'added')
  }
}
