import Theme, {ThemeProvider} from '@jetbrains/ring-ui/components/global/theme'
import * as React from 'react'

import type {HeaderProps} from './Header'

import styles from './Header.css'

const Header = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Header", webpackPrefetch: true */
      './Header'
    ),
)

const HeaderLazy = (props: HeaderProps) =>
  React.useMemo(
    () => (
      <ThemeProvider theme={Theme.DARK} className={styles.container}>
        <React.Suspense fallback={<div data-suspense-fallback />}>
          <Header {...props} />
        </React.Suspense>
      </ThemeProvider>
    ),
    [props],
  )

export default React.memo(HeaderLazy)
