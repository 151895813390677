import {
  isValueArrayNotDuplicate,
  isValueNotEmptyMessage,
  isValueNotLong,
  isValueNotValidSymbol,
  isValueRelatedArrayNotDuplicate,
} from '../../../../../utils/validators'

const maxParameterNameLength = 300
export const parameterNameValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: 'Parameter name should not be empty',
  },
  {
    validator: isValueRelatedArrayNotDuplicate,
    message: 'This parameter name is already used in secrets',
  },
  {
    validator: isValueArrayNotDuplicate,
    message: 'This parameter name already exists',
  },
  {
    validator: isValueNotLong(maxParameterNameLength),
    message: 'The parameter name is too long',
  },
  {
    validator: isValueNotValidSymbol(/^[a-zA-Z0-9._\-*]+$/),
    message:
      'A name must contain only the [a-zA-Z0-9._-*] characters and start with an ASCII letter',
  },
]

export const parameterValueValidators = [
  {
    validator: isValueNotEmptyMessage,
    message: 'Parameter value should not be empty',
  },
]
