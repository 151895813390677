import {safeLocalStorage} from '../../../utils/safeStorages'

import type {AgentsTreeExpandState} from './AgentsPages.types'
import {mergeExpandState} from './AgentsPages.utils'
import {
  AgentTreeItemType,
  DETACHED_AGENTS_SELECTION_ID,
  POOLS_SELECTION_ID,
  UNAUTHORIZED_AGENTS_SELECTION_ID,
} from './AgentsSidebar/AgentsSidebar.types'
import {
  ALL_AGENTS_SELECTION_ID,
  JETBRAINS_HOSTED_ID,
  SELF_HOSTED_ID,
} from './AgentsSidebar/selectors/pipelines/pipelinesMatchingTree'

export type AgentsTreeExpandStateKey =
  | 'agentsSidebarExpandState'
  | 'agentsOverviewExpandState'
  | 'favoriteAgentsSidebarExpandState'
export const EXPAND_SIDEBAR_STATE_KEY: AgentsTreeExpandStateKey = 'agentsSidebarExpandState'
export const EXPAND_FAVORITE_SIDEBAR_STATE_KEY: AgentsTreeExpandStateKey =
  'favoriteAgentsSidebarExpandState'
export const EXPAND_AGENTS_OVERVIEW_STATE_KEY: AgentsTreeExpandStateKey =
  'agentsOverviewExpandState'
const EMPTY_EXPAND_STATE: AgentsTreeExpandState = {
  [AgentTreeItemType.SELECTION]: {
    [UNAUTHORIZED_AGENTS_SELECTION_ID]: false,
    [DETACHED_AGENTS_SELECTION_ID]: false,
    [POOLS_SELECTION_ID]: true,
    [ALL_AGENTS_SELECTION_ID]: true,
  },
  [AgentTreeItemType.POOL]: {},
  [AgentTreeItemType.AGENT_TYPE]: {},
  [AgentTreeItemType.FOLDER]: {
    [JETBRAINS_HOSTED_ID]: true,
    [SELF_HOSTED_ID]: true,
  },
}

export const getStoredAgentsTreeExpandState: (
  arg0: AgentsTreeExpandStateKey,
) => AgentsTreeExpandState = key => safeLocalStorage.getItemInJSON(key) || EMPTY_EXPAND_STATE
export const setStoredExpandState: (
  arg0: AgentsTreeExpandStateKey,
  arg1: AgentsTreeExpandState,
) => void = (key, expandState) =>
  safeLocalStorage.setItem(
    key,
    JSON.stringify(mergeExpandState(getStoredAgentsTreeExpandState(key), expandState)),
  )
