const isProjectsAndBuildTypesEnabled =
  window.internalProps['teamcity.pipelines.projects.enabled'] !== false
export const isPipelinesEnabled = window.internalProps['teamcity.pipelines.enabled']
export const isPipelinesEnabledInExclusiveMode =
  isPipelinesEnabled && !isProjectsAndBuildTypesEnabled
export const areFeaturesWithoutBackendEnabled =
  window.internalProps['teamcity.pipelines.featuresWaitingForBackend']
export const isDockerAutocompleteEnabled =
  window.internalProps['teamcity.docker.dockerhub.autocomplete']
export const isPipelinesDockerfileEnabled =
  window.internalProps['teamcity.pipelines.dockerfile.support']
export const isUnityRunnedEnabled = window.internalProps['teamcity.pipelines.unity.enabled']
export const parallelTestsEnabled = window.internalProps['teamcity.pipelines.parallelTests.enabled']
export const customizeJobReuseEnabled =
  window.internalProps['teamcity.pipelines.feature.jobReuseCustomization.enabled']
export const isNewTimeBadgeEnabled = window.internalProps['teamcity.pipelines.newTimeBadge.enabled']
export const isFilePickersEnabled =
  window.internalProps['teamcity.pipelines.filePickers.enabled'] === true
export const isNewArtifactField =
  window.internalProps['teamcity.pipelines.newArtifactField.enabled'] === true
export const isLineEditorEnabled =
  window.internalProps['teamcity.pipelines.lineEditor.enabled'] === true
export const isSpaceConnectionEnabled =
  window.internalProps['teamcity.pipelines.spaceConnection.enabled']
export const isSelfHostedAgentsEnabled = () =>
  window.internalProps['teamcity.pipelines.selfHostedAgents.enabled'] === true
export const isSelfHostedAgentsNewFeaturePopupEnabled =
  window.internalProps['teamcity.pipelines.selfHostedAgents.newFeaturePopup.enabled'] === true
export const isPipelinesAgentPageEnabled = () =>
  isPipelinesEnabled && window.internalProps['teamcity.pipelines.agentPage.enabled'] === true
export const isPipelinesAgentPageNewFeaturePopupEnabled =
  window.internalProps['teamcity.pipelines.agentPage.newFeaturePopup.enabled'] === true
export const dependencyCacheEnabled =
  window.internalProps['teamcity.pipelines.dependencyCache.enabled']
export const isStoreYamlInVcsEnabled =
  window.internalProps['teamcity.pipelines.feature.storeYAMLinVCS']
export const isCloneJobEnabled =
  window.internalProps['teamcity.pipelines.feature.cloneButton.enabled']
export const isSmoothParameterReplacementEnabled =
  window.internalProps['teamcity.pipelines.feature.smoothParameterReplacement.enabled']
export const isMuteInvestigateButtonEnabled =
  window.internalProps['teamcity.pipelines.muteInvestigateButton.enabled']
export const isNewCreatePipelinePageEnabled = () =>
  window.internalProps['teamcity.pipelines.newCreatePipelinePage.enabled']
export const isEarlyAccessBannerEnabled =
  window.internalProps['teamcity.pipelines.earlyAccessBanner.enabled']
export const isPipelinesPromoBannerEnabled =
  !isPipelinesEnabled && window.internalProps['teamcity.pipelines.promoBanner.enabled']
export const isJobIncompatibilityAgent =
  window.internalProps['teamcity.pipelines.feature.jobIncompatibilityAgent.enabled']
export const isShowLoginAlertOnErrors =
  window.internalProps['teamcity.pipelines.showLoginAlertOnErrors.enabled']
