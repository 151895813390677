/**
 * @generated SignedSource<<eddd1f36bcadb35b6b8075a27c6fc328>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgentTypeParametersQuery$variables = {
  locator: string;
};
export type AgentTypeParametersQuery$data = {
  readonly agentType: {
    readonly " $fragmentSpreads": FragmentRefs<"AgentTypeParameters_agentType">;
  } | null | undefined;
};
export type AgentTypeParametersQuery = {
  response: AgentTypeParametersQuery$data;
  variables: AgentTypeParametersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentTypeLocator",
    "variableName": "locator"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Property",
    "kind": "LinkedField",
    "name": "property",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentTypeParametersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentTypeRest",
        "kind": "LinkedField",
        "name": "agentType",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AgentTypeParameters_agentType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentTypeParametersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AgentTypeRest",
        "kind": "LinkedField",
        "name": "agentType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Properties",
            "kind": "LinkedField",
            "name": "environmentParameters",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Properties",
            "kind": "LinkedField",
            "name": "systemParameters",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Properties",
            "kind": "LinkedField",
            "name": "configurationParameters",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7354b427d3db2b325fcb4ba171ea5673",
    "id": null,
    "metadata": {},
    "name": "AgentTypeParametersQuery",
    "operationKind": "query",
    "text": "query AgentTypeParametersQuery(\n  $locator: String!\n) {\n  agentType(agentTypeLocator: $locator) {\n    ...AgentTypeParameters_agentType\n    _id\n  }\n}\n\nfragment AgentTypeParameters_agentType on AgentTypeRest {\n  environmentParameters {\n    count\n    ...filterSearch_parameters\n  }\n  systemParameters {\n    count\n    ...filterSearch_parameters\n  }\n  configurationParameters {\n    count\n    ...filterSearch_parameters\n  }\n}\n\nfragment filterSearch_parameters on Properties {\n  property {\n    name\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "b139c2510f1c3f44f92a2a93b3163b01";

export default node;
