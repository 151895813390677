/**
 * @generated SignedSource<<075d584cb5e0b5b00682645363905178>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewBreadcrumbsQuery$variables = {
  buildLocator: string;
  buildTypeLocator: string;
  fetchBuild: boolean;
  fetchBuildType: boolean;
  fetchProject: boolean;
  projectLocator: string;
};
export type OverviewBreadcrumbsQuery$data = {
  readonly build?: {
    readonly " $fragmentSpreads": FragmentRefs<"OverviewBreadcrumbsBuildFragment">;
  } | null | undefined;
  readonly buildType?: {
    readonly " $fragmentSpreads": FragmentRefs<"OverviewBreadcrumbsBuildTypeFragment">;
  } | null | undefined;
  readonly project?: {
    readonly " $fragmentSpreads": FragmentRefs<"OverviewBreadcrumbsProjectFragment">;
  } | null | undefined;
};
export type OverviewBreadcrumbsQuery = {
  response: OverviewBreadcrumbsQuery$data;
  variables: OverviewBreadcrumbsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "buildLocator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "buildTypeLocator"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchBuild"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchBuildType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchProject"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectLocator"
},
v6 = [
  {
    "kind": "Variable",
    "name": "projectLocator",
    "variableName": "projectLocator"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "btLocator",
    "variableName": "buildTypeLocator"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "buildLocator",
    "variableName": "buildLocator"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v12 = [
  (v9/*: any*/),
  (v10/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Projects",
    "kind": "LinkedField",
    "name": "ancestorProjects",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectRest",
        "kind": "LinkedField",
        "name": "project",
        "plural": true,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectRest",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OverviewBreadcrumbsQuery",
    "selections": [
      {
        "condition": "fetchProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ProjectRest",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OverviewBreadcrumbsProjectFragment"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "fetchBuildType",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "BuildTypeRest",
            "kind": "LinkedField",
            "name": "buildType",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OverviewBreadcrumbsBuildTypeFragment"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "fetchBuild",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OverviewBreadcrumbsBuildFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "OverviewBreadcrumbsQuery",
    "selections": [
      {
        "condition": "fetchProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ProjectRest",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "fetchBuildType",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "BuildTypeRest",
            "kind": "LinkedField",
            "name": "buildType",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "fetchBuild",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Build",
            "kind": "LinkedField",
            "name": "build",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "queuedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "queuePosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "canceledInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timestamp",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "failedToStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "personal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detachedFromAgent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BuildTypeRest",
                "kind": "LinkedField",
                "name": "buildType",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "internalId",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c48f7f2828fee70835330f06219276b9",
    "id": null,
    "metadata": {},
    "name": "OverviewBreadcrumbsQuery",
    "operationKind": "query",
    "text": "query OverviewBreadcrumbsQuery(\n  $projectLocator: String!\n  $buildTypeLocator: String!\n  $buildLocator: String!\n  $fetchProject: Boolean!\n  $fetchBuildType: Boolean!\n  $fetchBuild: Boolean!\n) {\n  project(projectLocator: $projectLocator) @include(if: $fetchProject) {\n    ...OverviewBreadcrumbsProjectFragment\n    _id\n  }\n  buildType(btLocator: $buildTypeLocator) @include(if: $fetchBuildType) {\n    ...OverviewBreadcrumbsBuildTypeFragment\n    _id\n  }\n  build(buildLocator: $buildLocator) @include(if: $fetchBuild) {\n    ...OverviewBreadcrumbsBuildFragment\n    _id\n  }\n}\n\nfragment OverviewBreadcrumbsBuildFragment on Build {\n  id\n  state\n  startDate\n  queuedDate\n  ...useFormatBuildNumberFragment\n  ...useBuildStatusIconFragment\n  ...useRunningBuildUpdaterRelayFragment\n  buildType {\n    ...OverviewBreadcrumbsBuildTypeFragment\n    _id\n  }\n}\n\nfragment OverviewBreadcrumbsBuildTypeFragment on BuildTypeRest {\n  id\n  name\n  type\n  project {\n    ...OverviewBreadcrumbsProjectFragment\n    _id\n  }\n}\n\nfragment OverviewBreadcrumbsProjectFragment on ProjectRest {\n  id\n  name\n  ancestorProjects {\n    project {\n      id\n      name\n      _id\n    }\n  }\n}\n\nfragment useBuildStatusIconFragment on Build {\n  status\n  state\n  canceledInfo {\n    timestamp\n  }\n  failedToStart\n  personal\n  detachedFromAgent\n  user {\n    id\n    _id\n  }\n}\n\nfragment useFormatBuildNumberFragment on Build {\n  number\n  state\n  queuePosition\n}\n\nfragment useRunningBuildUpdaterRelayFragment on Build {\n  id\n  state\n  buildType {\n    internalId\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "44b56e95ba8627969d7329d5311f552a";

export default node;
