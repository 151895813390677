import {createSelector} from 'reselect'

import type {State} from '../../../reducers/types'
import {emptyArray} from '../../../utils/empty'

import type {
  BuildLogMessage,
  BuildLogKey,
  FullLogTarget,
  FullLogState,
  BuildLogTestTargetAnchor,
  MessagesLoadState,
  LogFilter,
  BuildLogState,
  MessagesFetchable,
} from './BuildLog.types'
import {generateShowLogParam, generateLinesStateParam} from './BuildLog.utils'

export const getBuildLogMessages = (
  state: State,
  key: BuildLogKey,
): MessagesFetchable | null | undefined => state.buildLog.messages[key]

const getBuildLogTestTargetAnchors = (
  state: State,
  key: BuildLogKey,
): ReadonlyArray<BuildLogTestTargetAnchor> | null | undefined =>
  state.buildLog.testAnchors[key]?.anchors

export const getBuildLogTestAnchorPosition = (
  state: State,
  key: BuildLogKey,
  anchorType: string,
): number | null | undefined => {
  const anchors = getBuildLogTestTargetAnchors(state, key)
  return anchors != null ? anchors.find(item => item.type === anchorType)?.position : null
}

export const getBuildLogLastMessageIncluded = (
  state: State,
  key: BuildLogKey,
): boolean | undefined | null => state.buildLog.lastMessageIncluded[key]

export const getBuildLogMessagesReady = (state: State, key: BuildLogKey): boolean =>
  getBuildLogMessages(state, key)?.ready ?? false
export const getBuildLogMessagesLoading = (state: State, key: BuildLogKey): boolean =>
  getBuildLogMessages(state, key)?.loading ?? false

const getBuildLogMessagesData = (state: State, key: BuildLogKey): ReadonlyArray<BuildLogMessage> =>
  getBuildLogMessages(state, key)?.data ?? emptyArray

export const getFullLogState = (state: State, target: FullLogTarget): FullLogState =>
  state.buildLog.fullLogStates[target]
export const getFullLogShowedFirstDate = (
  state: State,
  target?: FullLogTarget,
): string | null | undefined =>
  target != null ? state.buildLog.fullLogStates[target].showedFirstDate : null

const getFullLogExpandAll = (state: State, target: FullLogTarget): boolean =>
  state.buildLog.fullLogStates[target].expandAll

const getFullLogExpandState = (
  state: State,
  target: FullLogTarget,
): ReadonlyArray<number> | null | undefined => state.buildLog.fullLogStates[target].expandState

export const getShowLog = (state: State): string | null | undefined => {
  const {buildId, focusLine, expandState, expandAll} = state.buildLog.fullLogStates.popup
  return buildId == null ? null : generateShowLogParam(buildId, focusLine, expandState, expandAll)
}
export const getFocusLine = (state: State): number | null | undefined =>
  state.buildLog.fullLogStates.page.focusLine
export const getLinesState = (state: State): string | null | undefined =>
  generateLinesStateParam(state.buildLog.fullLogStates.page.expandState)
export const getExpandAll = (state: State): boolean => state.buildLog.fullLogStates.page.expandAll
export const getLogFilter = (state: State, target?: FullLogTarget): LogFilter | null | undefined =>
  target != null
    ? state.buildLog.fullLogStates[target].filter
    : (state.buildLog.fullLogStates.popup.filter ?? state.buildLog.fullLogStates.page.filter)
export const getMessagesLoadStates = (
  state: State,
  key: BuildLogKey,
): MessagesLoadState | null | undefined => state.buildLog.messagesLoadStates[key]
export const getFullBuildLog: (
  arg0: State,
  arg1: BuildLogKey,
  arg2: FullLogTarget,
  arg3: boolean,
) => {
  messages: ReadonlyArray<BuildLogMessage | null | undefined>
  holeIndexes: ReadonlyArray<number>
  expandStateSet: Set<number>
  expandableChildrenMap: Map<number, {parentId: number; children: ReadonlyArray<number>}>
  maxMessageLength: number
} = createSelector(
  getBuildLogMessagesData,
  (state: State, key: BuildLogKey, target: FullLogTarget) => getFullLogExpandState(state, target),
  (state: State, key: BuildLogKey, target: FullLogTarget) => getFullLogExpandAll(state, target),
  (state: State, key: BuildLogKey, target: FullLogTarget, running: boolean) => running,
  (
    data: ReadonlyArray<BuildLogMessage>,
    expandState: ReadonlyArray<number> | null | undefined,
    expandAll: boolean,
    running: boolean,
  ) => {
    const holeIndexes: Array<number> = []
    const messages: Array<BuildLogMessage | null | undefined> = []
    let maxMessageLength = 0
    let nextMessage
    let childrenEmpty = false
    let parentExpanded
    const expandStateSet = new Set<number>(expandState)
    const childrenEmptyMap = new Map<number, number>()
    const messageParentMap = new Map<number, number>()
    const expandableChildrenMap = new Map<number, {parentId: number; children: number[]}>()
    data.forEach((message: BuildLogMessage, index: number) => {
      const {level, parentId, containsMessages, flowId, nextIsHole, maxLineLength, id, isLast} =
        message

      if (index === 0 && level !== 0) {
        holeIndexes.push(messages.push(null) - 1)
      }

      parentExpanded =
        parentId != null &&
        (expandAll ? !expandStateSet.has(parentId) : expandStateSet.has(parentId))

      if (level === 1 || (parentExpanded && level > 0)) {
        nextMessage = data[index + 1]
        childrenEmpty =
          containsMessages === true &&
          (nextMessage == null || nextMessage.level <= level || nextMessage.flowId !== flowId)

        if (childrenEmpty) {
          const messagesLength = messages.push({...message, childrenEmpty: true})
          childrenEmptyMap.set(id, messagesLength - 1)
        } else {
          messages.push(message)
        }

        if (nextIsHole === true) {
          holeIndexes.push(messages.push(null) - 1)
        }

        if (maxLineLength != null && maxLineLength > maxMessageLength) {
          maxMessageLength = maxLineLength
        }
      } else if (expandAll) {
        // add id to collapsed set for collapse all children messages
        expandStateSet.add(id)
      } else {
        // remove id from expanded set for collapse all children messages
        expandStateSet.delete(id)
      }

      if (parentId != null && childrenEmptyMap.size > 0) {
        const parentIndex = childrenEmptyMap.get(parentId)

        if (parentIndex != null) {
          childrenEmptyMap.delete(parentId)
          const parentMessage = messages[parentIndex]

          if (parentMessage != null) {
            messages[parentIndex] = {...parentMessage, childrenEmpty: false}
          }
        }
      }

      if (parentId != null && parentId !== 0 && containsMessages) {
        messageParentMap.set(id, parentId)

        const mapObj = expandableChildrenMap.get(parentId)
        if (mapObj != null) {
          mapObj.children.push(id)
        } else {
          expandableChildrenMap.set(parentId, {
            parentId: messageParentMap.get(parentId) ?? 0,
            children: [id],
          })
        }
      }

      if (index === data.length - 1 && (isLast !== true || running)) {
        holeIndexes.push(messages.push(null) - 1)
      }
    })
    return {
      messages,
      holeIndexes,
      expandStateSet,
      expandableChildrenMap,
      maxMessageLength,
    }
  },
)
export const getSoftWrapLinesInBuildlog = (state: State): boolean =>
  state.buildLog.settings.softWrapLines
export const getRelativeTimeInBuildlog = (state: State): boolean =>
  state.buildLog.settings.relativeTime
export const getBuildlogStateToPersist: (state: State) => Partial<BuildLogState> = createSelector(
  (state: State) => state.buildLog.settings,
  settings => ({
    settings,
  }),
)
