import Button from '@jetbrains/ring-ui/components/button/button'
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import classNames from 'classnames'
import * as React from 'react'
import {graphql, useFragment} from 'react-relay'

import type {BuildId} from '../../../../../types'
import Popup from '../../../Popup/Popup.lazy'
import {useBuildApprove} from '../../BuildApproval.hooks'
import BuildApprovalDialog from '../../BuildApprovalDialog/BuildApprovalDialog'
import BuildApprovalReviewers from '../../BuildApprovalReviewers'
import styles from '../BuildApprovalDetailsPopup.css'

import type {BuildApprovalDetailsPopupContent_approvalInfo$key} from './__generated__/BuildApprovalDetailsPopupContent_approvalInfo.graphql'

const fragment = graphql`
  fragment BuildApprovalDetailsPopupContent_approvalInfo on ApprovalInfo {
    canBeApprovedByCurrentUser
    ...BuildApprovalReviewers_approvalInfo
  }
`

export type OwnProps = {
  buildId: BuildId
  approvalInfoKey: BuildApprovalDetailsPopupContent_approvalInfo$key | null
}

export default function BuildApprovalDetailsPopupContent({
  buildId,
  approvalInfoKey,
  ...props
}: OwnProps) {
  const approvalInfo = useFragment(fragment, approvalInfoKey)
  const {approve} = useBuildApprove(buildId, false)
  const approveAll = useBuildApprove(buildId, true)
  const [showDialog, setShowDialog] = React.useState(false)
  const [popupHidden, setPopupHidden] = React.useState(false)

  return (
    <>
      {!showDialog && (
        <Popup
          hidden={popupHidden}
          className={classNames(
            styles.popup,
            approvalInfo?.canBeApprovedByCurrentUser && styles.withControls,
          )}
          {...props}
        >
          <div className={styles.popupContent}>
            <div>
              <BuildApprovalReviewers approvalInfoKey={approvalInfo} />
            </div>
            {approvalInfo?.canBeApprovedByCurrentUser ? (
              <Button
                className={styles.approve}
                onClick={() => {
                  setPopupHidden(true)
                  setShowDialog(true)
                }}
              >
                {'Approve'}
              </Button>
            ) : null}
          </div>
        </Popup>
      )}
      <React.Suspense
        fallback={
          <span>
            {'Loading '}
            <LoaderInline />
          </span>
        }
      >
        <BuildApprovalDialog
          buildId={buildId}
          show={showDialog}
          onClose={() => {
            setShowDialog(false)
            setPopupHidden(false)
          }}
          onApprove={approve}
          onApproveAll={approveAll.approve}
        />
      </React.Suspense>
    </>
  )
}
