import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import type {ThinNestedEntryPointParamsObject} from '../../../../types/entryPoints'
import {makeResource} from '../../../../utils/makeResource'
import {queryToObject} from '../../../../utils/queryParams'
import {EditPipelinePageEntryPoint} from '../EditPipelinePage/EditPipelinePage.entryPoint'
import {pipelineHeadQuery} from '../PipelinesPages.queries'

import {PipelineContentEntryPoint} from './PipelineContent/PipelineContent.entryPoint'
import type {NestedEntryPoints, PipelinePageComponent} from './PipelinePage'

export const PipelinePageEntryPoint: EntryPoint<PipelinePageComponent, LoaderFunctionArgs> = {
  root: makeResource(
    'PipelinePage',
    () => import(/* webpackChunkName: "PipelinePage", webpackPrefetch: true */ './PipelinePage'),
  ),
  getPreloadProps: ({params, request}) => {
    const url = new URL(request.url)
    const {edit} = queryToObject(url.search)

    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {
      pipelineContent: {
        entryPoint: PipelineContentEntryPoint,
        entryPointParams: {},
      },
    }

    if (edit === 'true') {
      entryPoints.editPipelineContent = {
        entryPoint: EditPipelinePageEntryPoint,
        entryPointParams: {request, params},
      }
    }
    return {
      queries: {
        pipelineHead: {
          parameters: getRequest(pipelineHeadQuery),
          variables: {locator: `internalId:${params.pipelineId}`},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints,
    }
  },
}
