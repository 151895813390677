import {graphql} from 'react-relay'

export const query = graphql`
  query OverviewBreadcrumbsQuery(
    $projectLocator: String!
    $buildTypeLocator: String!
    $buildLocator: String!
    $fetchProject: Boolean!
    $fetchBuildType: Boolean!
    $fetchBuild: Boolean!
  ) {
    project(projectLocator: $projectLocator) @include(if: $fetchProject) {
      ...OverviewBreadcrumbsProjectFragment
    }
    buildType(btLocator: $buildTypeLocator) @include(if: $fetchBuildType) {
      ...OverviewBreadcrumbsBuildTypeFragment
    }
    build(buildLocator: $buildLocator) @include(if: $fetchBuild) {
      ...OverviewBreadcrumbsBuildFragment
    }
  }
`
