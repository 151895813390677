import {stringifyId} from '../../../../../types'
import type {BuildTypeId, ProjectId} from '../../../../../types'

import type {CleanupHolderNode, Rule} from './Rules.types'

export const getKey = (holderNode: CleanupHolderNode): string =>
  `${holderNode.nodeType}:${stringifyId(holderNode.id)}`
type HolderParams = {
  holder: string
  holderId: string
}
export const getHolderParams = (holderNode: CleanupHolderNode): HolderParams => {
  const holder = holderNode.nodeType === 'bt' ? 'buildType' : 'project'
  const holderId = stringifyId(holderNode.internalId)
  return {
    holder,
    holderId,
  }
}
export const getRuleOwnership = (
  rule: Rule | null | undefined,
): {
  own: boolean
  inherited: boolean
  enforced: boolean
} => ({
  own: rule ? rule.source === 'own' : true,
  inherited: rule?.source === 'inherited',
  enforced: rule?.source === 'enforced',
})
export const isRuleOverridesTemplate = (rule: Rule): boolean =>
  rule.holderExternalId != null && rule.source === 'own'

type RulesByOwnership = {
  own: Rule[]
  inherited: Rule[]
  enforced: Rule[]
}
export const getRulesByOwnership = (rules: ReadonlyArray<Rule>): RulesByOwnership => {
  const result: RulesByOwnership = {
    own: [],
    inherited: [],
    enforced: [],
  }
  rules.forEach(rule => {
    const ownership = getRuleOwnership(rule)
    if (ownership.own) {
      result.own.push(rule)
    }
    if (ownership.inherited) {
      result.inherited.push(rule)
    }
    if (ownership.enforced) {
      result.enforced.push(rule)
    }
  })
  return result
}
type RuleHolderIds =
  | {
      projectId: ProjectId
      buildTypeId?: never
    }
  | {
      projectId?: never
      buildTypeId: BuildTypeId
    }
export const getRuleHolderIds = ({holder, holderExternalId}: Rule): RuleHolderIds =>
  (holder === 'project'
    ? {
        projectId: holderExternalId,
      }
    : {
        buildTypeId: holderExternalId,
      }) as any
