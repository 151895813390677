/**
 * @generated SignedSource<<f69e74c0fb098e490844a6d71170af5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelinesPagesHeadQuery$variables = {
  locator: string;
};
export type PipelinesPagesHeadQuery$data = {
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"pipelineHeadFragment">;
  } | null | undefined;
};
export type PipelinesPagesHeadQuery = {
  response: PipelinesPagesHeadQuery$data;
  variables: PipelinesPagesHeadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locator"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "projectLocator",
    "variableName": "locator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelinesPagesHeadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectRest",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "pipelineHeadFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelinesPagesHeadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectRest",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BuildTypes",
            "kind": "LinkedField",
            "name": "buildTypes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BuildTypeRest",
                "kind": "LinkedField",
                "name": "buildType",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "internalId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Properties",
                    "kind": "LinkedField",
                    "name": "parameters",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Property",
                        "kind": "LinkedField",
                        "name": "property",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63f1fd5774a4c4417db76a7f6dcd15b9",
    "id": null,
    "metadata": {},
    "name": "PipelinesPagesHeadQuery",
    "operationKind": "query",
    "text": "query PipelinesPagesHeadQuery(\n  $locator: String!\n) {\n  project(projectLocator: $locator) {\n    ...pipelineHeadFragment\n    _id\n  }\n}\n\nfragment pipelineHeadFragment on ProjectRest {\n  buildTypes {\n    buildType {\n      id\n      internalId\n      parameters {\n        property {\n          name\n        }\n      }\n      _id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5be30c75ee236aeba6b38cebed53150d";

export default node;
