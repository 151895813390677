import {useContext} from 'react'
import {graphql, useFragment} from 'react-relay'

import {CurrentUserKeyContext} from '../contexts/currentUserKey'
import type {UserId} from '../types'

import type {useMyIdFragment$key} from './__generated__/useMyIdFragment.graphql'

const useMyIdFragment = graphql`
  fragment useMyIdFragment on User {
    id
  }
`

export default function useMyId(): UserId | null | undefined {
  const currentUserKey: useMyIdFragment$key | null = useContext(CurrentUserKeyContext)
  const {id} = useFragment(useMyIdFragment, currentUserKey) ?? {}
  return id
}
