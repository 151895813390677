/**
 * @generated SignedSource<<a9f33e215d1e80bb781cdd1828c1012a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AgentDomainBreadcrumbsAgentQuery$variables = {
  agentLocator: string;
  fetchAgent: boolean;
};
export type AgentDomainBreadcrumbsAgentQuery$data = {
  readonly agent?: {
    readonly id: number | null | undefined;
    readonly name: string | null | undefined;
    readonly pool: {
      readonly id: number | null | undefined;
    } | null | undefined;
    readonly typeId: number | null | undefined;
  } | null | undefined;
};
export type AgentDomainBreadcrumbsAgentQuery = {
  response: AgentDomainBreadcrumbsAgentQuery$data;
  variables: AgentDomainBreadcrumbsAgentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentLocator"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fetchAgent"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentLocator",
    "variableName": "agentLocator"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentDomainBreadcrumbsAgentQuery",
    "selections": [
      {
        "condition": "fetchAgent",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "agent",
            "args": (v1/*: any*/),
            "concreteType": "AgentRest",
            "kind": "LinkedField",
            "name": "agentRest",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentPoolRest",
                "kind": "LinkedField",
                "name": "pool",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentDomainBreadcrumbsAgentQuery",
    "selections": [
      {
        "condition": "fetchAgent",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "agent",
            "args": (v1/*: any*/),
            "concreteType": "AgentRest",
            "kind": "LinkedField",
            "name": "agentRest",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentPoolRest",
                "kind": "LinkedField",
                "name": "pool",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "96bfacd9547db994bfad7509b22fc193",
    "id": null,
    "metadata": {},
    "name": "AgentDomainBreadcrumbsAgentQuery",
    "operationKind": "query",
    "text": "query AgentDomainBreadcrumbsAgentQuery(\n  $agentLocator: String!\n  $fetchAgent: Boolean!\n) {\n  agent: agentRest(agentLocator: $agentLocator) @include(if: $fetchAgent) {\n    id\n    name\n    pool {\n      id\n      _id\n    }\n    typeId\n    _id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4facc0bd56f94914a12f2515393905f0";

export default node;
