import {BitbucketIcon as PlainBitBucketIcon} from '@atlaskit/logo'
import * as React from 'react'

export const mainColor = (disabled: boolean): string => (disabled ? '#e5e5e5' : '#000')

type Props = {
  disabled: boolean
}

function BitbucketIcon({disabled}: Props) {
  return <PlainBitBucketIcon iconColor={`"${mainColor(disabled)}"`} />
}

export default React.memo(BitbucketIcon)
