import type {$Values} from 'utility-types'

import type {BuildSnippetName} from '../components/pages/BuildPage/BuildOverviewTab/BuildSnippets/BuildSnippets.types'

import version from './version'

import type {
  ParameterCategory,
  ParameterGroup,
} from 'src/components/packages/Parameters/Parameters.types'

export const UserProperties = {
  HIGHLIGHT_MY_CHANGES: 'hightlightMyChanges',
  AUTODETECT_TIMEZONE: 'autodetectTimeZone',
  SHOW_ALL_PERSONAL_BUILDS: 'showAllPersonalBuilds',
  SHOW_ALL_CHANGES_FILES: 'showAllChangesFiles',
  SHOW_CHANGES_GRAPH: 'showChangesGraph',
  SHOW_CHANGES_BUILDS: 'showChangesBuilds',
  SHOW_SAKURA_RELEASE_BANNER: 'showSakuraReleaseBanner',
  USE_EXPERIMENTAL_OVERVIEW: 'useExperimentalOverview',
  SHOW_CLASSIC_UI_SWITCHER: 'showClassicUISwitcher',
  HAS_SEEN_EXPERIMENTAL_OVERVIEW: 'hasSeenExperimentalOverview',
  EXPAND_MATRIX_BUILD_SECTION: 'expandMatrixBuildSection',
  EXPAND_BUILD_PROBLEMS_SECTION: 'expandBuildProblemsSection',
  EXPAND_CHANGE_PROBLEMS_SECTION: 'expandChangeProblemsSection',
  EXPAND_BUILD_TESTS_SECTION: 'expandBuildTestsSection',
  EXPAND_CHANGE_TESTS_SECTION: 'expandChangeTestsSection',
  EXPAND_PROJECT_INVESTIGATIONS_TESTS_SECTION: 'expandProjectInvestigationsTestsSection',
  EXPAND_BUILD_CHANGES_SECTION: 'expandBuildChangesSection',
  EXPAND_BUILD_DEPLOYMENTS_SECTION: 'expandBuildDeploymentsSection',
  EXPAND_REVISIONS_SECTION: 'expandRevisionsSection',
  EXPAND_TEST_HISTORY_CHART_SECTION: 'expandTestHistoryChartSection',
  EXPAND_TEST_HISTORY_INVESTIGATIONS_SECTION: 'expandTestHistoryInvestigationsSection',
  EXPAND_TEST_HISTORY_MUTES_SECTION: 'expandTestHistoryMutesSection',
  HIDE_PROBLEMS_FROM_DEPENDENCIES: 'hideProblemsFromDependencies',
  HIDE_TESTS_FROM_DEPENDENCIES: 'hideTestsFromDependencies',
  BUILD_TESTS_TREE_VIEW: 'buildTestsTreeView',
  PROJECT_INVESTIGATIONS_TESTS_TREE_VIEW: 'projectInvestigationsTestsTreeView',
  HAS_USED_EXPERIMENTAL_UI_OPTOUT_FORM: `hasUsedExperimentalUIOptoutForm_${version.full}`,
  HAS_USED_TRY_CLOUD: 'hasUsedTryCloud',
  HAS_SEEN_EXPERIMENTAL_UI_FEEDBACK_FORM: `hasSeenExperimentalUIFeedbackForm_${version.full}`,
  HAS_SEEN_TIPS_REMINDER: 'hasSeenTipsReminder',
  TIPS_MODE: 'tipsMode',
  DEPENDENCIES_TYPE: 'dependenciesType',
  DEPENDENCIES_MODE: 'dependenciesMode',
  BUILD_PARAMETERS_TAB: 'buildParametersTab',
  BUILD_PARAMETERS_SHOW_DEPENDENCIES: 'buildParametersShowDependencies',
  PROJECT_OVERVIEW_MODE: 'projectOverviewMode',
  PROJECT_PROBLEMS_MODE: 'projectProblemsMode',
  BUILD_TYPE_OVERVIEW_MODE: 'buildTypeOverviewMode',
  BUILD_TYPE_PROBLEMS_MODE: 'buildTypeProblemsMode',
  BUILD_RUNNERS_OVERVIEW_MODE: 'buildRunnerOverviewMode',
  CLEANUP_SHOW_SUBPROJECTS: 'cleanupShowSubprojects',
  ADD_TRIGGERED_BUILD_TO_FAVORITES: 'addTriggeredBuildToFavorites',
  USE_SERVICE_WORKER: 'useServiceWorker',
  SHOW_CHAIN_DETAILS: 'showChainDetails',
  UNGROUP_COMPOSITE_BUILDS_IN_CHAIN: 'ungroupCompositeBuildsInChain',
  GROUP_PROJECTS_IN_CHAIN: 'groupProjectsInChain',
  CHANGE_BUILDS_SHOW_ONLY_FAILED: 'changeBuildsShowOnlyFailed',
  CHANGE_BUILDS_SHOW_HIERARCHY: 'changeBuildsShowHierarchy',
  HAS_SEEN_SAKURA_RELEASE_POPUP: 'hasSeenSakuraReleasePopup',
  LAST_SEEN_SAKURA_UI_VERSION: `lastSeenSakuraUIVersion`,
  AGENT_POOL_FAVORITE_LIST: `agentPoolFavoriteList`,
  THEME: 'theme',
  SHOW_ONLY_UNREAD_NOTIFICATIONS: 'showOnlyUnreadNotifications',
  READ_NOTIFICATIONS: 'readNotifications',
  PROBLEMS_INVESTIGATION_MODE: 'problemsInvestigationMode',
  PROBLEMS_MUTE_MODE: 'problemsMuteMode',

  HAS_STARTED_USING_PIPELINES_DATETIME: `hasStartedUsingPipelinesDateTime`,
  PIPELINES_FOLLOW_UP_FEEDBACK_SUBMITTED_DATETIME: `pipelinesFollowUpFeedbackSubmittedDateTime`,
  PIPELINES_FEEDBACK_SUBMITTED_PROJECT_LIST: `pipelinesFeedbackSubmittedProjectList`,

  SHOW_PIPELINES_PROMO_BANNER: `showPipelinesPromoBanner`,

  //New features seen
  HAS_PIPELINE_AGENT_PAGES_BEEN_SEEN: 'hasPipelineAgentPagesBeenSeen',
  HAS_PIPELINE_SELF_HOSTED_AGENT_BEEN_SEEN: 'hasPipelineSelfHostedAgentBeenSeen',
} as const
export type UserProperty =
  | $Values<typeof UserProperties>
  | `expand${BuildSnippetName}`
  | `parametersSection${ParameterGroup}${ParameterCategory}`
