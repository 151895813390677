import isEmpty from 'lodash/isEmpty'

import type {PipelineDraftState, PipelineFormParams} from './EditPipelinePage.slices.types'

export function getJob(state: PipelineDraftState, id: string, jobId: string) {
  return state[id]?.draft?.settings.jobs?.[jobId]
}

export function deleteIfEmpty<T>(object: T | undefined, key: keyof T) {
  if (object != null && isEmpty(object[key])) {
    delete object[key]
    return true
  }
  return false
}

export function getPipelineFormKey({jobId, id, type}: Omit<PipelineFormParams, 'pipelineId'>) {
  return `${jobId ?? ''}_${type}_${id ?? ''}`
}
