import type {ReactNode} from 'react'

import type {AlertMessageType} from '../../types/AlertMessages'

export type BannerAction = {
  label: string
  onClick: () => void
}

export type Banner = {
  key: string
  title?: string
  message: string
  type: AlertMessageType
  closeable?: boolean
  actions?: BannerAction[]
}

export type BannerStoreValue = Record<string, Banner[]>

export type BannersContextProps = {
  items: BannerStoreValue
  addBanner: (pipelineId: string, value: Banner) => void
  removeBanner: (pipelineId: string, key: string) => void
  removeAllBanners: () => void
}

export type BannersProviderProps = {
  children: ReactNode
}

export const RELOAD_MESSAGE = 'reload-message'
export const VALIDATION_ERRROR_MESSAGE = 'validation-error'
