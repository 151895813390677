import type {PayloadAction} from '@reduxjs/toolkit'
import {SHOULD_AUTOBATCH} from '@reduxjs/toolkit'

export const autobatchMiddleware =
  () =>
  () =>
  (next: (action: PayloadAction<unknown>) => PayloadAction<unknown>) =>
  (action: PayloadAction<unknown, string, {[SHOULD_AUTOBATCH]?: boolean}>) => {
    if (action.type.includes('internalSubscriptions/subscriptionsUpdated')) {
      action.meta ??= {}
      action.meta[SHOULD_AUTOBATCH] = true
    }

    return next(action)
  }
