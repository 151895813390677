import batchVisible from '../../../actions/batchVisible'
import {buildDetailsQueue} from '../../../actions/buildDetailsQueue'
import type {AppThunk} from '../../../actions/types'
import {fetchQueryOnce} from '../../../relay/fetchQueryOnce'
import getEnvironment from '../../../relay/getEnvironment'
import {normalizeBuildArtifacts} from '../../../rest/schemata'
import type {Build} from '../../../services/rest'
import type {BuildId} from '../../../types'
import {Namespace} from '../../../utils/namespace'

import type {BuildArtifactsQuery} from './__generated__/BuildArtifactsQuery.graphql'
import {buildArtifactsQuery} from './BuildArtifacts.queries'
import {buildArtifacts} from './BuildArtifacts.slice'

export const receiveBuildArtifacts = (data: ReadonlyArray<Build>) =>
  buildArtifacts.actions.receive(normalizeBuildArtifacts(data))

export const fetchBuildArtifactsCountQueue: (id: BuildId) => AppThunk<void> = batchVisible({
  queue: buildDetailsQueue,
  namespace: Namespace.ARTIFACTS,
  request: id =>
    fetchQueryOnce<BuildArtifactsQuery>(getEnvironment(), buildArtifactsQuery, {
      buildLocator: `id:${id}`,
    }).then(data => {
      if (data?.build == null) {
        return []
      }
      return [
        {
          ...data.build,
          artifacts: {
            count: data.build.artifacts?.count ?? 0,
          },
        },
      ]
    }),
  action: receiveBuildArtifacts,
})
