import type {PlaceIdList as PlaceIdListType} from '@jetbrains/teamcity-api'

// Do not forget to add PlaceID to public typings
export const PlaceIdList: PlaceIdListType = {
  SAKURA_AGENTS_TOOLBAR: 'SAKURA_AGENTS_TOOLBAR',
  SAKURA_AFTER_AGENT_INFO: 'SAKURA_AFTER_AGENT_INFO',
  SAKURA_AGENT_ACTIONS: 'SAKURA_AGENT_ACTIONS',
  SAKURA_HEADER_NAVIGATION_AFTER: 'SAKURA_HEADER_NAVIGATION_AFTER',
  SAKURA_HEADER_USERNAME_BEFORE: 'SAKURA_HEADER_USERNAME_BEFORE',
  SAKURA_HEADER_RIGHT: 'SAKURA_HEADER_RIGHT', // TODO remove
  SAKURA_FOOTER_RIGHT: 'SAKURA_FOOTER_RIGHT',
  SAKURA_BEFORE_CONTENT: 'SAKURA_BEFORE_CONTENT',
  SAKURA_SIDEBAR_TOP: 'SAKURA_SIDEBAR_TOP',
  SAKURA_PROJECT_LINKS: 'SAKURA_PROJECT_LINKS',
  SAKURA_PROJECT_TRENDS: 'SAKURA_PROJECT_TRENDS',
  SAKURA_PROJECT_BUILDS: 'SAKURA_PROJECT_BUILDS',
  SAKURA_BUILD_CONFIGURATION_TREND_CARD: 'SAKURA_BUILD_CONFIGURATION_TREND_CARD',
  SAKURA_BUILD_CONFIGURATION_BUILDS: 'SAKURA_BUILD_CONFIGURATION_BUILDS',
  SAKURA_BUILD_CONFIGURATION_BRANCHES: 'SAKURA_BUILD_CONFIGURATION_BRANCHES',
  SAKURA_BUILD_CONFIGURATION_CHANGE_LOG: 'SAKURA_BUILD_CONFIGURATION_CHANGE_LOG',
  SAKURA_BUILD_CHANGES: 'SAKURA_BUILD_CHANGES',
  SAKURA_BUILD_LINE_EXPANDED: 'SAKURA_BUILD_LINE_EXPANDED',
  SAKURA_BUILD_OVERVIEW: 'SAKURA_BUILD_OVERVIEW',
  SAKURA_CUSTOM_AGENTS_COUNTER: 'SAKURA_CUSTOM_AGENTS_COUNTER',
  SAKURA_QUEUE_ACTIONS: 'SAKURA_QUEUE_ACTIONS',
  SAKURA_TEST_DETAILS_ACTIONS: 'SAKURA_TEST_DETAILS_ACTIONS',
  SAKURA_GUIDES_OVERVIEW: 'SAKURA_GUIDES_OVERVIEW',
  SAKURA_TOOL_PANEL_FOOTER: 'SAKURA_TOOL_PANEL_FOOTER',

  HEADER_RIGHT: 'HEADER_RIGHT',
  BEFORE_CONTENT: 'BEFORE_CONTENT',
  PROJECT_FRAGMENT: 'PROJECT_FRAGMENT',
  PROJECT_STATS_FRAGMENT: 'PROJECT_STATS_FRAGMENT',
  BUILD_CONF_STATISTICS_FRAGMENT: 'BUILD_CONF_STATISTICS_FRAGMENT',
  BUILD_RESULTS_FRAGMENT: 'BUILD_RESULTS_FRAGMENT',
  BUILD_RESULTS_BUILD_PROBLEM: 'BUILD_RESULTS_BUILD_PROBLEM',
  TAB_PLUGIN_CONTAINER: 'TAB_PLUGIN_CONTAINER',
}
export enum PluginLifecycle {
  ON_CREATE = 'ON_CREATE',
  ON_MOUNT = 'ON_MOUNT',
  ON_CONTENT_UPDATE = 'ON_CONTENT_UPDATE',
  ON_CONTEXT_UPDATE = 'ON_CONTEXT_UPDATE',
  ON_UNMOUNT = 'ON_UNMOUNT',
  ON_DELETE = 'ON_DELETE',
}
