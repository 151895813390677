import type {CaseReducer, PayloadAction} from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import type {RunsOn} from '../../types'
import type {SelfHostedRequirement} from '../SettingsSidebarContent/JobSettingsSidebar/JobAgentRequirements/SelfHosted/Requirements/SelfHostedRequirements.types'
import {
  isArchRequirement,
  isCpuRequirement,
  isCustomRequirement,
  isOsFamilyRequirement,
  isOsVersionRequirement,
  isRamRequirement,
} from '../SettingsSidebarContent/JobSettingsSidebar/JobAgentRequirements/SelfHosted/Requirements/SelfHostedRequirements.types'

import type {PipelineDraftState} from './EditPipelinePage.slices.types'
import {getJob} from './EditPipelinePage.slices.utils'

export const setAgent: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    agent?: string
  }>
> = (state, action) => {
  const {id, jobId, agent} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    job['runs-on'] = agent
  }
}

export const setSelfHostedAgent: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    requirements?: RunsOn
  }>
> = (state, action) => {
  const {id, jobId, requirements} = action.payload
  const job = getJob(state, id, jobId)

  if (job != null) {
    job['runs-on'] = requirements ?? 'self-hosted'
  }
}

export const addSelfHostedRequirements: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    requirement: SelfHostedRequirement
  }>
> = (state, action) => {
  const {id, jobId, requirement} = action.payload
  const job = getJob(state, id, jobId)

  if (job == null) {
    return
  }

  if (typeof job['runs-on'] === 'object') {
    job['runs-on']['self-hosted'].push(requirement)
  } else {
    job['runs-on'] = {
      'self-hosted': [requirement],
    }
  }
}

export const removeSelfHostedRequirement: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    requirement: SelfHostedRequirement
  }>
> = (state, action) => {
  const {id, jobId, requirement} = action.payload
  const job = getJob(state, id, jobId)

  if (job == null) {
    return
  }

  if (typeof job['runs-on'] !== 'object') {
    return
  }

  const restRequirements = job['runs-on']['self-hosted'].filter(
    current => !isEqual(current, requirement),
  )

  if (restRequirements.length > 0) {
    job['runs-on']['self-hosted'] = restRequirements
  } else {
    job['runs-on'] = 'self-hosted'
  }
}

export const changeSelfHostedRequirement: CaseReducer<
  PipelineDraftState,
  PayloadAction<{
    id: string
    jobId: string
    prev: SelfHostedRequirement
    next: SelfHostedRequirement
  }>
> = (state, action) => {
  const {id, jobId, prev, next} = action.payload
  const job = getJob(state, id, jobId)

  if (job == null) {
    return
  }

  if (typeof job['runs-on'] !== 'object') {
    return
  }

  job['runs-on']['self-hosted'] = job['runs-on']['self-hosted'].map(current => {
    if (isCpuRequirement(current) && isCpuRequirement(prev)) {
      return next
    }

    if (isArchRequirement(current) && isArchRequirement(prev)) {
      return next
    }

    if (isRamRequirement(current) && isRamRequirement(prev)) {
      return next
    }

    if (isOsFamilyRequirement(current) && isOsFamilyRequirement(prev)) {
      return next
    }

    if (isOsVersionRequirement(current) && isOsVersionRequirement(prev)) {
      return next
    }

    if (isCustomRequirement(current) && isCustomRequirement(prev)) {
      return current.name === prev.name ? next : current
    }

    return current
  })
}
