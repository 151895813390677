/**
 * @generated SignedSource<<4b2ddb6de6463a3dc5d46bc551c2590d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AgentsPagesPoolsAuthorizePermissionsQuery$variables = Record<PropertyKey, never>;
export type AgentsPagesPoolsAuthorizePermissionsQuery$data = {
  readonly agentPools: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null | undefined;
        readonly permissions: {
          readonly authorizeAgents: boolean | null | undefined;
          readonly manageAgents: boolean | null | undefined;
        } | null | undefined;
        readonly rawId: string;
      };
    }>;
  } | null | undefined;
};
export type AgentsPagesPoolsAuthorizePermissionsQuery = {
  response: AgentsPagesPoolsAuthorizePermissionsQuery$data;
  variables: AgentsPagesPoolsAuthorizePermissionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AgentPoolPermissions",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorizeAgents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manageAgents",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentsPagesPoolsAuthorizePermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPoolsConnection",
        "kind": "LinkedField",
        "name": "agentPools",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AgentsPagesPoolsAuthorizePermissionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentPoolsConnection",
        "kind": "LinkedField",
        "name": "agentPools",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentPoolEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b4fac1a215eb6c36a3730468852bf99",
    "id": null,
    "metadata": {},
    "name": "AgentsPagesPoolsAuthorizePermissionsQuery",
    "operationKind": "query",
    "text": "query AgentsPagesPoolsAuthorizePermissionsQuery {\n  agentPools {\n    edges {\n      node {\n        __typename\n        id\n        rawId\n        name\n        permissions {\n          authorizeAgents\n          manageAgents\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ffef69a027119426b6aa44b360907ec0";

export default node;
