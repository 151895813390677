import type {IconType as Icon} from '@jetbrains/ring-ui/components/icon/icon'
import * as React from 'react'

type Props = {
  url: string
  children?: React.ReactNode
  Component?: React.ComponentType<any> | string
  width?: number
  height?: number
  icon?: Icon | string
  className?: string
  preservePlus?: boolean
}

function HelpLink({
  Component = 'a',
  children = null,
  url,
  width,
  height,
  preservePlus,
  ...restProps
}: Props) {
  const handleClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      window.BS?.Util?.showHelp(e.nativeEvent, url, {
        width,
        height,
        preservePlus,
      })
      e.preventDefault()
    },
    [url, width, height, preservePlus],
  )

  return (
    <Component {...restProps} href={url} onClick={handleClick} title="View help">
      {children}
    </Component>
  )
}

export default React.memo(HelpLink)
