import type {Location as ReduxLocation} from 'react-router-dom'

import {
  getAgentIdFromUrl,
  getAgentPoolIdFromUrl,
  getAgentTypeIdFromUrl,
  getBuildTypeIdFromUrl,
  getChangeId,
  getIsAgentsOverviewSelected,
  getIsAgentsRootSelected,
  getIsChangesSelected,
  getIsFavoriteAgentPoolsSelected,
  getIsFavoriteLinkSelected,
  getIsInvestigationsSelected,
  getIsQueueSelected,
  getIsUnauthorizedAgentsSelected,
  getProjectIdFromUrl,
  getTestId,
} from '../../../hooks/routes'
import type {State} from '../../../reducers/types'
import Routes, {
  getAgentHref,
  getAgentsOverviewHref,
  getAgentTypeHref,
  getChangeHref,
  getChangesHref,
  getFavoriteBuildsHref,
  getInvestigationsHref,
  getOverviewHref,
  getQueueHref,
  getTestHistoryHref,
  getUnauthorizedAgentsHref,
  matchRoute,
} from '../../../routes'
import {
  checkIfTabIsOnlyClassicUI,
  getClassicUIAgentLink,
  getClassicUIAgentPoolLink,
  getClassicUIAgentsLink,
  getClassicUIChangeLink,
  getClassicUIChangesLink,
  getClassicUICloudImageLink,
  getClassicUIFavoriteBuildsLink,
  getClassicUIInvestigationsLink,
  getClassicUIQueueLink,
  getClassicUITestHistoryLink,
  getCurrentBuildTab,
  getCurrentBuildTypeTab,
  getCurrentProjectPageTab,
  makeGetClassicUILinkWithBranch,
} from '../../../selectors'
import type {ActiveEntityURLProps, NodeType, TabId} from '../../../types'
import {
  BuildPageTabNamesEnum,
  BuildTypePageTabNamesEnum,
  ClassicUIAgentTabNamesEnum,
  OverviewAgentsTabNamesEnum,
  OverviewCloudImageTabNamesEnum,
  ProjectPageTabNamesEnum,
  stringifyId,
  toBuildTypeId,
  toProjectId,
  toTabId,
} from '../../../types'
import {internalProps} from '../../../types/BS_types'
import {parseBranch} from '../../../utils/branchNames'
import invertDictionary from '../../../utils/invertDictionary'
import type {KeyValue} from '../../../utils/object'
import {objectToQuery, queryToObject} from '../../../utils/queryParams'
import {
  matchClassicToSakuraTabName,
  matchSakuraToClassicTabName,
} from '../../packages/Changes/ChangeDetailsTabs/ChangeDetailsTabs.utils'
import {DependenciesTypes} from '../../pages/BuildPage/DependenciesTab/DependenciesTab.modes'
import {Modes} from '../../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'

import type {OpenInSakuraUIProps} from './ToggleSakuraUI.types'

const USE_NEW_INVESTIGATIONS_PAGE: boolean = internalProps['teamcity.ui.newInvestigationsPage']
const USE_SAKURA_PROBLEMS_PAGE: boolean = internalProps['teamcity.ui.sakuraProblemsTab']

export const ProjectTabsWithoutAliases: KeyValue<TabId, string> = {
  investigations: USE_SAKURA_PROBLEMS_PAGE
    ? ProjectPageTabNamesEnum.PROBLEMS
    : ProjectPageTabNamesEnum.INVESTIGATIONS,
  mutedProblems: USE_SAKURA_PROBLEMS_PAGE
    ? ProjectPageTabNamesEnum.PROBLEMS
    : ProjectPageTabNamesEnum.mutedProblems,
}

export const ProjectTabs: KeyValue<string, TabId> = {
  ...ProjectTabsWithoutAliases,
  projectOverview: ProjectPageTabNamesEnum.OVERVIEW,
  stats: ProjectPageTabNamesEnum.STATISTICS,
  testDetails: ProjectPageTabNamesEnum.TEST_DETAILS,
  projectChangeLog: ProjectPageTabNamesEnum.CHANGE_LOG,
  problems: ProjectPageTabNamesEnum.PROBLEMS,
}
const InvertedProjectTabs: KeyValue<TabId, string> = invertDictionary(ProjectTabs)

export const BuildTypeTabsWithoutAliases: KeyValue<TabId, string> = {
  buildTypeBranches: BuildTypePageTabNamesEnum.OVERVIEW,
  buildTypeHistoryList: BuildTypePageTabNamesEnum.OVERVIEW,
}
const BuildTypeModes: KeyValue<string, string> = {
  buildTypeBranches: Modes.BRANCHES,
  buildTypeStatusDiv: Modes.BUILDS,
}
export const BuildTypeTabs: KeyValue<string, TabId> = {
  ...BuildTypeTabsWithoutAliases,
  buildTypeStatusDiv: BuildTypePageTabNamesEnum.OVERVIEW,
  buildTypeStatistics: BuildTypePageTabNamesEnum.STATISTICS,
  pendingChangesDiv: BuildTypePageTabNamesEnum.PENDING_CHANGES,
  buildTypeChains: BuildTypePageTabNamesEnum.CHAINS,
  buildTypeChangeLog: BuildTypePageTabNamesEnum.CHANGE_LOG,
}
const InvertedBuildTypeTabs: KeyValue<TabId, string> = invertDictionary<string, TabId>(
  BuildTypeTabs,
)

export const BuildTabs: KeyValue<string, TabId> = {
  buildResultsDiv: BuildPageTabNamesEnum.OVERVIEW,
  queuedBuildOverviewTab: BuildPageTabNamesEnum.OVERVIEW,
  buildChangesDiv: BuildPageTabNamesEnum.CHANGES,
  dependencies: BuildPageTabNamesEnum.DEPENDENCIES,
  buildLog: BuildPageTabNamesEnum.LOG,
  testsInfo: BuildPageTabNamesEnum.TESTS,
  artifacts: BuildPageTabNamesEnum.ARTIFACTS,
  buildParameters: BuildPageTabNamesEnum.PARAMETERS,
}
const InvertedBuildTabs: KeyValue<TabId, string> = invertDictionary<string, TabId>(BuildTabs)

const DepsTabs: Record<string, string> = {
  snapshot: DependenciesTypes.SNAPSHOT,
  downloadedArtifacts: DependenciesTypes.DOWNLOADED_ARTIFACTS,
  deliveredArtifacts: DependenciesTypes.DELIVERED_ARTIFACTS,
}
const InvertedDepsTabs = invertDictionary(DepsTabs)

const AgentsTabs: KeyValue<string, TabId> = {
  unauthorizedAgents: OverviewAgentsTabNamesEnum.ALL_AGENTS,
  registeredAgents: OverviewAgentsTabNamesEnum.ALL_AGENTS,
  clouds: OverviewAgentsTabNamesEnum.ALL_AGENTS,
  agentsParametersReport: OverviewAgentsTabNamesEnum.PARAMETERS_REPORT,
  agentsStatisticsTab: OverviewAgentsTabNamesEnum.AGENTS_STATISTICS,
  'agent.push': OverviewAgentsTabNamesEnum.AGENT_PUSH,
}
const InvertedAgentTabs: KeyValue<TabId, TabId> = invertDictionary<TabId, TabId>(
  ClassicUIAgentTabNamesEnum,
)

const CloudImagesTabs: KeyValue<string, TabId> = {
  agentTypeSummary: OverviewCloudImageTabNamesEnum.SUMMARY,
  agentHistory: OverviewCloudImageTabNamesEnum.HISTORY,
  agentCompatibleConfigurations: OverviewCloudImageTabNamesEnum.COMPATIBLE_CONFIGUTATIONS,
  agentBuildRunners: OverviewCloudImageTabNamesEnum.BUILD_RUNNERS,
  agentParameters: OverviewCloudImageTabNamesEnum.AGENT_PARAMETERS,
}
const InvertedCloudImagesTabs: KeyValue<TabId, string> = invertDictionary<string, TabId>(
  CloudImagesTabs,
)

export const SWITCH_TO_SAKURA_PARAM = 'enableSakuraByDefault'

export const getSakuraUIHref = ({
  projectId,
  buildTypeId,
  buildId,
  agentId,
  agentTypeId,
  testId,
  changeId,
  favoriteBuilds,
  agents,
  tab = '',
  depsTab,
  kind = '',
  status,
  branch,
  focusLine,
  expandAll,
  logFilter,
  tag,
  queue,
  changes,
  investigations,
  personal,
  enableSakuraByDefault,
  onlyModified,
}: OpenInSakuraUIProps): string | null | undefined => {
  const tabId = toTabId(tab)

  if (tab && !changeId) {
    let nodeType: NodeType | null | undefined = null

    if (projectId) {
      nodeType = 'project'
    } else if (buildTypeId && buildId == null) {
      nodeType = 'bt'
    } else if (buildId != null) {
      nodeType = 'build'
    }

    const tabInBlackList = checkIfTabIsOnlyClassicUI(tabId, nodeType)

    if (
      tabInBlackList ||
      (projectId && !ProjectTabs[tab]) ||
      (buildTypeId && buildId == null && !BuildTypeTabs[tab]) ||
      (buildId != null && !BuildTabs[tab]) ||
      (agents === true && !AgentsTabs[tab])
    ) {
      return null
    }
  }

  if (favoriteBuilds === true) {
    return getFavoriteBuildsHref()
  }

  if (agents === true) {
    if (tab === 'unauthorizedAgents') {
      return getUnauthorizedAgentsHref()
    }

    return `${getAgentsOverviewHref()}${
      tab
        ? `?${objectToQuery({
            tab: AgentsTabs[tab],
          })}`
        : ''
    }`
  }

  if (agentId) {
    return `${getAgentHref(agentId)}${
      tab
        ? `?${objectToQuery({
            tab: stringifyId(ClassicUIAgentTabNamesEnum[tabId] || tabId),
            kind,
          })}`
        : ''
    }`
  }

  if (agentTypeId) {
    return `${getAgentTypeHref(agentTypeId)}${
      tab
        ? `?${objectToQuery({
            tab: CloudImagesTabs[tab],
            kind,
          })}`
        : ''
    }`
  }

  if (changeId != null) {
    return getChangeHref({
      changeId,
      buildTypeId,
      personal,
      tab: matchClassicToSakuraTabName(tab),
    })
  }

  if (testId != null) {
    return getTestHistoryHref(testId, {
      currentProjectId: stringifyId(projectId),
      currentBuildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
      branch,
      status,
    })
  }

  if (queue === true) {
    return `${getQueueHref()}?${objectToQuery({enableSakuraByDefault})}`
  }

  if (changes === true) {
    return `${getChangesHref()}?${objectToQuery({enableSakuraByDefault})}`
  }

  if (investigations === true) {
    return USE_NEW_INVESTIGATIONS_PAGE
      ? `${getInvestigationsHref()}?${objectToQuery({enableSakuraByDefault})}`
      : null
  }

  if (buildId != null && branch != null && /^[+-]:/.test(branch)) {
    return null
  }

  return `${getOverviewHref({
    projectId,
    buildTypeId,
    buildId,
  })}?${objectToQuery({
    branch,
    projectTab: projectId != null ? ProjectTabs[tab] : null,
    buildTypeTab: buildTypeId != null && buildId == null ? BuildTypeTabs[tab] : null,
    buildTab: buildId != null ? BuildTabs[tab] : null,
    mode: buildTypeId != null && buildId == null ? BuildTypeModes[tab] : null,
    type: depsTab != null ? DepsTabs[depsTab] : null,
    focusLine,
    expandAll,
    logFilter,
    tag,
    enableSakuraByDefault,
    onlyModified: onlyModified != null ? onlyModified : null,
  })}`
}
export function redirectToSakuraUI(props: OpenInSakuraUIProps): boolean {
  const href = getSakuraUIHref(props)

  if (href == null) {
    return false
  }

  window.location.replace(href)
  return true
}

const getTab = (
  state: State,
  {buildId, buildTypeId, projectId}: ActiveEntityURLProps,
  viewMode: string | null | undefined,
) => {
  if (buildId != null) {
    const tab = getCurrentBuildTab(state)
    return tab ? (InvertedBuildTabs[tab] ?? stringifyId(tab)) : null
  } else if (buildTypeId) {
    const tab = getCurrentBuildTypeTab(state)

    if (tab == null || tab === BuildTypePageTabNamesEnum.OVERVIEW) {
      return viewMode === Modes.BRANCHES ? 'buildTypeBranches' : 'buildTypeStatusDiv'
    }

    return InvertedBuildTypeTabs[tab] ?? stringifyId(tab)
  } else if (projectId) {
    const tab = getCurrentProjectPageTab(state)
    return tab ? (InvertedProjectTabs[tab] ?? stringifyId(tab)) : null
  }

  return null
}

export function getClassicUIHref(state: State, location: ReduxLocation | Location) {
  const isFavoriteLinkSelected = getIsFavoriteLinkSelected(location.pathname)
  const isAgentsRootSelected = getIsAgentsRootSelected(location.pathname)
  const isUnauthorizedAgentsSelected = getIsUnauthorizedAgentsSelected(location.pathname)
  const isAgentsOverviewSelected = getIsAgentsOverviewSelected(location.pathname)
  const isFavoriteAgentPoolsSelected = getIsFavoriteAgentPoolsSelected(location.pathname)
  const isQueueSelected = getIsQueueSelected(location.pathname)
  const isChangesSelected = getIsChangesSelected(location.pathname)
  const isInvestigationsSelected = getIsInvestigationsSelected(location.pathname)
  const projectId = getProjectIdFromUrl(location.pathname)
  const buildTypeId = getBuildTypeIdFromUrl(location.pathname)
  const buildPageMatch =
    matchRoute(Routes.BUILD, location.pathname) ??
    matchRoute(Routes.BUILD_UNKNOWN_BUILDTYPE, location.pathname)
  const buildId = buildPageMatch?.params.buildId
  const agentPoolId = getAgentPoolIdFromUrl(location.pathname)
  const agentTypeId = getAgentTypeIdFromUrl(location.pathname)
  const agentId = getAgentIdFromUrl(location.pathname)
  const testId = getTestId(location.pathname)
  const changeId = getChangeId(location.pathname)
  const {
    branch: branchFromQuery,
    tag,
    tab,
    kind,
    type,
    personal,
    currentProjectId: projectIdFromQuery,
    currentBuildTypeId: buildTypeIdFromQuery,
    onlyModified,
    mode: viewMode,
  } = queryToObject(location.search)
  const branch = parseBranch(branchFromQuery)
  const classicUiTab = getTab(
    state,
    {
      projectId,
      buildTypeId,
      buildId,
    },
    viewMode,
  )
  const getClassicUILinkWithBranch = makeGetClassicUILinkWithBranch(
    {
      projectId,
      buildTypeId,
      buildId,
    },
    branch,
    {
      tag,
      tab: classicUiTab,
      depsTab: classicUiTab === 'dependencies' && type != null ? InvertedDepsTabs[type] : null,
      onlyModified,
    },
  )

  if (isFavoriteLinkSelected) {
    return getClassicUIFavoriteBuildsLink()
  } else if (isQueueSelected) {
    return getClassicUIQueueLink()
  } else if (isAgentsRootSelected || isAgentsOverviewSelected || isFavoriteAgentPoolsSelected) {
    return getClassicUIAgentsLink(tab === OverviewAgentsTabNamesEnum.ALL_AGENTS ? null : tab)
  } else if (isUnauthorizedAgentsSelected) {
    return getClassicUIAgentsLink('unauthorizedAgents')
  } else if (agentPoolId != null) {
    return getClassicUIAgentPoolLink(agentPoolId)
  } else if (agentId != null) {
    return getClassicUIAgentLink(
      agentId,
      tab != null ? (InvertedAgentTabs[toTabId(tab)] ?? toTabId(tab)) : null,
      kind,
    )
  } else if (agentTypeId != null) {
    return getClassicUICloudImageLink(
      agentTypeId,
      tab != null ? (InvertedCloudImagesTabs[toTabId(tab)] ?? tab) : null,
      kind,
    )
  } else if (testId != null && projectIdFromQuery != null) {
    return getClassicUITestHistoryLink({
      testId,
      branch,
      projectId: toProjectId(projectIdFromQuery),
      buildTypeId: buildTypeIdFromQuery != null ? toBuildTypeId(buildTypeIdFromQuery) : null,
    })
  } else if (changeId != null) {
    return getClassicUIChangeLink({
      changeId,
      buildTypeId,
      personal: personal === 'true',
      tab: matchSakuraToClassicTabName(tab),
    })
  } else if (isChangesSelected) {
    return getClassicUIChangesLink()
  } else if (isInvestigationsSelected) {
    return getClassicUIInvestigationsLink()
  } else {
    return getClassicUILinkWithBranch(state)
  }
}

export function redirectToClassicUI(state: State) {
  const href = getClassicUIHref(state, window.location)
  window.location.replace(href)
}
