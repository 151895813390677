import classNames from 'classnames'
import * as React from 'react'
import type {$Keys} from 'utility-types'

import {resolveRelative} from '../../../utils/url'

import type {Icons} from './BuildProblemIcon/BuildProblemIcon'
import BuildProblemIcon from './BuildProblemIcon/BuildProblemIcon'

type Props = {
  btId: string
  projectExternalId: string
  failed: number
  invest: number
  fixed: number
  muted: number
  className?: string
}

const getStatItem = (type: $Keys<typeof Icons>, num: number, details: string) =>
  num > 0 ? (
    <React.Fragment key={type}>
      <BuildProblemIcon type={type} />
      <span className="bp-number">{num}</span> <span className="problemDetails">{details}</span>
    </React.Fragment>
  ) : null

function ProblemsSummary({
  btId,
  projectExternalId,
  failed,
  invest,
  fixed,
  muted,
  className,
}: Props): React.ReactNode {
  const classes = classNames('problemsSummary', className)

  const params = new URLSearchParams()
  params.set('projectId', projectExternalId)
  params.set('tab', 'problems')
  params.set('buildTypeId', btId)

  const href = resolveRelative(`/project.html?${params.toString()}`)
  const statItems = [
    getStatItem('error', failed, 'not investigated'),
    getStatItem('taken', invest, 'under investigation'),
    getStatItem('fixed', fixed, 'marked as fixed'),
    getStatItem('muted-red', muted, 'muted'),
  ]
    .filter(Boolean)
    .reduce<React.ReactNode[] | null>(
      (items, item) => (items ? [...items, ', ', item] : [item]),
      null,
    )

  return (
    <span className={classes} title="View problems summary for this build configuration">
      <a className="summaryLink" href={href}>
        {'Test'}
        <span className="problemDetails">{' failure'}</span>
        {'s: '}
        {statItems}
      </a>
    </span>
  )
}

export default React.memo(ProblemsSummary)
